import React from "react";
import styles from "./edit.module.css";
import PrimaryButton from "../primary-button";
import DisplayDropdown from "../display-dropdown";
import _ from "lodash";
import CuttingList from "../cutting-list";
import Flashing from "../flashing";
import { ReactFlowProvider } from "reactflow";

export default function FlashingEdit({
  editedRow,
  flashings,
  data,
  onDelete,
  onDiscard,
  onAdd,
  onEdit,
  costPrice,
}) {
  const [editing, setEditing] = React.useState(false);
  const [variant, setVariant] = React.useState(
    editedRow
      ? () => {
          const record = flashings[parseInt(editedRow)];
          return {
            label: record.colour,
            value: record.variantID,
            extraData: {
              prices: record.prices,
              colourID: record.colourID,
            },
          };
        }
      : undefined,
  );
  const [showDraw, setShowDraw] = React.useState(false);
  const [showCuttingList, setShowCuttingList] = React.useState(false);
  const [shape, setShape] = React.useState(
    editedRow
      ? () => {
          const record = flashings[parseInt(editedRow)];
          const parsed = JSON.parse(record.diagram);
          return {
            diagram: parsed,
            bends: record.bends,
            girth: record.girth,
            edgeCount: parsed.data.edges.length,
          };
        }
      : undefined,
  );
  const [image, setImage] = React.useState(
    editedRow
      ? () => {
          const record = flashings[parseInt(editedRow)];
          return record.shape;
        }
      : undefined,
  );
  const [cutting, setCutting] = React.useState(
    editedRow
      ? () => {
          const record = flashings[parseInt(editedRow)];
          return record.cutting;
        }
      : [],
  );

  const colourOptions = data
    ? data[0].Flashing_Variants.reduce((acc, curr) => {
        const cat = curr.Colour.Colour_Category.Colour_Category_Name;
        const colObj = {
          value: curr._id,
          label: curr.Colour.Colour_Name,
          extraData: {
            prices: costPrice
              ? curr.Cost_Price_Array.map((priceArray) =>
                  priceArray.map((record) => ({
                    ...record,
                    Price: record.Cost_Price,
                    Tapered_Price: record.Tapered_Cost_Price,
                  })),
                )
              : curr.Selling_Price_Array.map((priceArray) =>
                  priceArray.map((record) => ({
                    ...record,
                    Price: record.Selling_Price,
                    Tapered_Price: record.Tapered_Selling_Price,
                  })),
                ),
            colourID: curr.Colour._id,
          },
        };
        if (!acc[cat]) {
          acc[cat] = [];
        }
        acc[cat].push(colObj);
        return acc;
      }, {})
    : [];

  let unitPrice = 0;
  let baseCost = 0;
  const selectedPricing = variant?.extraData?.prices;
  if (selectedPricing) {
    const index =
      shape.bends <= selectedPricing.length
        ? (shape.bends || 1) - 1
        : selectedPricing.length - 1;
    const priceArray = selectedPricing[index];
    for (let i = 0; i < priceArray.length; i++) {
      if (priceArray[i].Max_Girth >= shape.girth) {
        unitPrice =
          shape.diagram.tapered && priceArray[i].Tapered_Price
            ? priceArray[i].Tapered_Price
            : priceArray[i].Price;
        baseCost =
          shape.diagram.tapered && priceArray[i].Tapered_Cost
            ? priceArray[i].Tapered_Cost
            : 0;
        break;
      }
    }
    if (shape.bends > selectedPricing.length) {
      unitPrice += 1.65 * (shape.bends - selectedPricing.length);
    }
  }

  function discard() {
    setEditing(false);
    setVariant(undefined);
    setShape(undefined);
    setImage(undefined);
    setCutting([]);
    onDiscard();
  }

  function submit() {
    if (cutting.length === 0) {
      alert("Cutting list cannot be empty");
      return;
    }
    const shapeData = {
      shape: image,
      girth: shape.girth,
      bends: shape.bends,
      cutting,
      colour: variant.label,
      price: `${unitPrice.toFixed(2)}${baseCost ? `(${baseCost})` : ""}`,
      total: cutting.reduce(
        (acc, curr) => acc + (curr.Size * curr.Quantity * unitPrice) / 1000,
        0,
      ),
      diagram: JSON.stringify(shape.diagram),
      variantID: variant.value,
      prices: variant.extraData.prices,
      colourID: variant.extraData.colourID,
      flashingBranchItem: data[0]._id,
    };
    if (editedRow) {
      onEdit(shapeData, parseInt(editedRow));
    } else {
      onAdd(shapeData);
    }
    discard();
  }

  return (
    <div>
      <div className={styles.buttonContainer}>
        <PrimaryButton
          variant="outlined"
          label="Add New Line"
          onClick={() => {
            setEditing(true);
          }}
          loading={editedRow}
        />
      </div>
      {(editing || editedRow) && (
        <>
          <div className={styles.editForm}>
            <div className={styles.cuttingListContainer}>
              <div className={styles.cuttingListHeader}>Flashing Shape</div>
              {image && (
                <div className={styles.cList}>
                  <img
                    src={image}
                    alt="Flashing Preview"
                    width={150}
                    height={196}
                  />
                </div>
              )}
              <button
                className={`${styles.poButton} ${styles.action}`}
                type="button"
                onClick={() => setShowDraw(true)}
              >
                {shape ? "Edit Shape" : "Draw Shape"}
              </button>
            </div>
            {shape && (
              <DisplayDropdown
                name="Colour"
                id="colour"
                label="Colour"
                required
                options={colourOptions}
                placeholder="Select Colour"
                value={variant?.value || ""}
                fullWidth
                onChange={(newValue) =>
                  setVariant(
                    _.flatten(Object.values(colourOptions)).find(
                      (item) => item.value === newValue,
                    ),
                  )
                }
              />
            )}
            {shape && variant && (
              <div className={styles.cuttingListContainer}>
                <div className={styles.cuttingListHeader}>Cutting List</div>
                <div className={styles.cList}>
                  {cutting.length > 0 &&
                    cutting.map((record, index) => (
                      <div key={index.toString()}>
                        {record.Quantity} X {record.Size}
                        MM
                      </div>
                    ))}
                </div>
                <button
                  className={`${styles.poButton} ${styles.action}`}
                  type="button"
                  onClick={() => setShowCuttingList(true)}
                >
                  {cutting.length > 0 ? "Edit" : "Create List"}
                </button>
              </div>
            )}
          </div>
          {shape && variant && (
            <div className={styles.editButtonsContainer}>
              <PrimaryButton label="Save" type="button" onClick={submit} />
              <PrimaryButton
                label="Discard"
                type="button"
                variant="outlined"
                onClick={discard}
              />
              {editedRow && (
                <span
                  className={styles.delete}
                  onClick={() => {
                    onDelete(parseInt(editedRow));
                    onDiscard();
                  }}
                >
                  Delete Item
                </span>
              )}
            </div>
          )}
        </>
      )}
      <CuttingList
        showModal={showCuttingList}
        initialList={cutting}
        itemName="Flashing #"
        variantSize={editedRow || (flashings.length + 1).toString()}
        toggleModal={() => setShowCuttingList((curr) => !curr)}
        unit="MM"
        price={unitPrice}
        baseCost={baseCost}
        factor={1000}
        maxSize={shape && shape.edgeCount === 1 ? 2400 : 8000}
        minSize={50}
        minCost={1000}
        updateCuttingList={setCutting}
      />
      <ReactFlowProvider>
        <Flashing
          open={showDraw}
          onClose={() => setShowDraw(false)}
          onCompleted={(image, diagram) => {
            setImage(image);
            setShape(diagram);
          }}
          shape={shape?.diagram}
        />
      </ReactFlowProvider>
    </div>
  );
}
