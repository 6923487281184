import React from "react";

export function getAngle(edges) {
  let x1, y1, x2, y2, x3, y3;

  if (edges[0].x1 === edges[1].x1 && edges[0].y1 === edges[1].y1) {
    x1 = edges[0].x1;
    y1 = edges[0].y1;
    x2 = edges[0].x2;
    y2 = edges[0].y2;
    x3 = edges[1].x2;
    y3 = edges[1].y2;
  } else if (edges[0].x1 === edges[1].x2 && edges[0].y1 === edges[1].y2) {
    x1 = edges[0].x1;
    y1 = edges[0].y1;
    x2 = edges[0].x2;
    y2 = edges[0].y2;
    x3 = edges[1].x1;
    y3 = edges[1].y1;
  } else if (edges[0].x2 === edges[1].x1 && edges[0].y2 === edges[1].y1) {
    x1 = edges[0].x2;
    y1 = edges[0].y2;
    x2 = edges[0].x1;
    y2 = edges[0].y1;
    x3 = edges[1].x2;
    y3 = edges[1].y2;
  } else {
    x1 = edges[0].x2;
    y1 = edges[0].y2;
    x2 = edges[0].x1;
    y2 = edges[0].y1;
    x3 = edges[1].x1;
    y3 = edges[1].y1;
  }

  const dx21 = x2 - x1;
  const dx31 = x3 - x1;
  const dy21 = y2 - y1;
  const dy31 = y3 - y1;
  const m12 = Math.sqrt(dx21 * dx21 + dy21 * dy21);
  const m13 = Math.sqrt(dx31 * dx31 + dy31 * dy31);
  const theta = Math.acos((dx21 * dx31 + dy21 * dy31) / (m12 * m13));
  return Math.round((180 * theta) / Math.PI);
}

export function getNewCoords(a, b, c, prevAngle, newAngle) {
  const posDelta = (Math.PI * Math.abs(prevAngle - newAngle)) / 180;
  const negDelta = (Math.PI * -Math.abs(prevAngle - newAngle)) / 180;
  const dx = c.x - a.x;
  const dy = c.y - a.y;
  const d_acx_p = dx * Math.cos(posDelta) - dy * Math.sin(posDelta);
  const d_acy_p = dx * Math.sin(posDelta) + dy * Math.cos(posDelta);

  const d_acx_n = dx * Math.cos(negDelta) - dy * Math.sin(negDelta);
  const d_acy_n = dx * Math.sin(negDelta) + dy * Math.cos(negDelta);

  const c1x = a.x + d_acx_p;
  const c1y = a.y + d_acy_p;

  const c2x = a.x + d_acx_n;
  const c2y = a.y + d_acy_n;

  const a1 = getAngle([
    { x1: a.x, y1: a.y, x2: b.x, y2: b.y },
    { x1: a.x, y1: a.y, x2: c1x, y2: c1y },
  ]);

  if (a1 === newAngle) {
    return { x: c1x, y: c1y };
  } else {
    return { x: c2x, y: c2y };
  }
}

export const FoldDir = {
  UP: 1,
  DOWN: 2,
};

export const ColourDir = {
  INSIDE: 0,
  OUTSIDE: 1,
};

export const ColourContext = React.createContext(ColourDir.OUTSIDE);
