import styles from "./supplier-details.module.css";
import Control from "../../../components/control";
import ImagePicker from "../../../components/image-picker";
import GoogleAutocomplete from "../../../components/google-autocomplete";

export default function SupplierDetails({ supplier, autoComplete }) {
  return (
    <section>
      <div className={styles.flex}>
        <div className={styles.inputContainer}>
          <Control
            label="Supplier Name"
            id="supplier-name"
            name="Supplier_Name"
            required
            initialValue={supplier?.Supplier_Name}
          />
          <div className={styles.spacing} />
          <Control
            label="Supplier E-mail"
            id="supplier-email"
            name="Supplier_Email"
            required
            initialValue={supplier?.Supplier_Email}
            type="email"
          />
        </div>
        <div className={styles.inputContainer}>
          <Control
            label="Supplier Phone"
            id="supplier-phone"
            name="Supplier_Phone"
            required
            initialValue={supplier?.Supplier_Phone}
          />
          <div className={styles.spacing} />
          <GoogleAutocomplete
            label="Supplier Address"
            id="supplier-address"
            name="Supplier_Address"
            required
            initialValue={supplier?.Supplier_Address}
            autocompleteService={autoComplete}
          />
        </div>
        <div className={styles.inputContainer}>
          <Control
            label="Supplier ABN"
            id="supplier-abn"
            name="Supplier_ABN"
            initialValue={supplier?.Supplier_ABN}
          />
        </div>
      </div>
      <div className={`${styles.inputContainer} ${styles.rowContainer}`}>
        <Control
          label="Notes"
          id="supplier-notes"
          name="Supplier_Notes"
          initialValue={supplier?.Supplier_Notes}
          multiline
          rows={4}
          fullWidth
        />
      </div>
      <div className={`${styles.rowContainer} ${styles.flex}`}>
        <div className={styles.inputContainer}>
          <ImagePicker
            name="Supplier_Image"
            id="supplier-image"
            image={
              supplier?.Supplier_Image
                ? `${process.env.REACT_APP_IMAGE_PREFIX}/${supplier.Supplier_Image}`
                : undefined
            }
            label="Supplier Image"
          />
        </div>
        <div className={styles.inputContainer}>
          <ImagePicker
            name="Supplier_Image_Square"
            id="supplier-image-square"
            image={
              supplier?.Supplier_Image_Square
                ? `${process.env.REACT_APP_IMAGE_PREFIX}/${supplier.Supplier_Image_Square}`
                : undefined
            }
            label="Supplier Square Image"
          />
        </div>
      </div>
    </section>
  );
}
