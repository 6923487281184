import PageHeader from "../components/page-header";
import { useLoaderData, useNavigate } from "react-router-dom";
import { generateGetLoader, getInvoiceStatus } from "../lib/helpers";
import Table from "../components/table";
import dayjs from "dayjs";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/order/admin/getMany?offset=${page}&limit=25`,
    "orders",
  )();
  return response;
}

export default function Orders() {
  const navigate = useNavigate();
  const { orders: data } = useLoaderData();
  const currentPage = data.currentPage;
  const totalPages = data.totalPages;

  const rows = data.orders.map((order) => {
    const { text, colour } = getInvoiceStatus(order.Order_Invoice);
    return [
      { value: `${order._id}` },
      { value: `# ${order.Order_Number}` },
      {
        value:
          order.Order_Customer.Customer.Customer_Business_Name ||
          order.Order_Customer.Customer.Customer_Name,
      },
      {
        value: dayjs(order.Order_Date).format("DD/MM/YYYY"),
      },
      {
        value: order.Order_Delivery_Type === "Delivery" ? "To Site" : "Pick Up",
      },
      {
        value: <div style={{ color: colour }}>{text}</div>,
      },
      {
        value: `$ ${order.Order_Total_Price_GST.toFixed(2)}`,
      },
    ];
  });

  function handleRowClick(path) {
    navigate(path);
  }

  return (
    <>
      <PageHeader text="Orders" noAdd />
      <Table
        headers={[
          { name: "Order ID", width: "15.5%" },
          { name: "Customer", width: "22.15%" },
          { name: "Date", width: "13.5%" },
          { name: "Delivery", width: "15.94%" },
          { name: "Status", width: "20.91%" },
          { name: "Amount", width: "12%" },
        ]}
        rows={rows}
        nextLink={
          currentPage < totalPages ? `?page=${currentPage + 1}` : undefined
        }
        prevLink={currentPage > 1 ? `?page=${currentPage - 1}` : undefined}
        onRowClick={handleRowClick}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </>
  );
}
