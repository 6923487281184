import { useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import dayjs from "dayjs";
import { generateGetLoader, getPOStatus } from "../lib/helpers";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/getMany?offset=${page}&limit=25`,
    "purchaseOrders",
  )();
  return response;
}

export default function PurchaseOrders() {
  const { purchaseOrders: data } = useLoaderData();
  const totalPages = data.totalPages;
  const currentPage = data.currentPage;
  const orders = data.PurchaseOrders;
  const navigate = useNavigate();

  const rows = orders.map((po) => {
    const { text, colour } = getPOStatus(po);
    return [
      {
        value: po._id,
      },
      {
        value: po.PurchaseOrder_Number,
      },
      {
        value: po.PurchaseOrder_Supplier.Supplier_Name,
      },
      {
        value: po.PurchaseOrder_Supplier_Branch.Branch_Name,
      },
      {
        value:
          po.PurchaseOrder_Delivery_Type === "Delivery"
            ? "To Site"
            : po.PurchaseOrder_Delivery_Address ===
              "6/56 Montague St, North Wollongong, NSW"
            ? "To Pova"
            : "Pick Up",
      },
      {
        value: dayjs(po.PurchaseOrder_Date).format("DD.MM.YYYY"),
      },
      {
        value: <div style={{ color: colour }}>{text}</div>,
      },
      {
        value: `$${po.PurchaseOrder_Total_Price_GST.toFixed(2)}`,
      },
    ];
  });

  function handleClick(link) {
    navigate(link);
  }

  return (
    <>
      <PageHeader text="Purchase Orders" />
      <Table
        headers={[
          { name: "PO #", width: "12.1%" },
          { name: "Supplier", width: "18.3%" },
          { name: "Branch", width: "20%" },
          { name: "Delivery", width: "13.4%" },
          { name: "Date", width: "12.6%" },
          { name: "Status", width: "12.8%" },
          { name: "Amount", width: "10.8%" },
        ]}
        rows={rows}
        onRowClick={handleClick}
        nextLink={
          currentPage < totalPages ? `?page=${currentPage + 1}` : undefined
        }
        prevLink={currentPage > 1 ? `?page=${currentPage - 1}` : undefined}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </>
  );
}
