import React from "react";
import styles from "./primary-button.module.css";

export default React.forwardRef(function PrimaryButton(
  {
    label,
    type = "button",
    loading,
    variant = "filled",
    onClick,
    fullWidth,
    rounded,
    ...rest
  },
  ref,
) {
  return (
    <button
      className={`${styles.button} ${
        variant === "outlined" ? styles.buttonOutlined : styles.buttonFilled
      } ${fullWidth ? styles.fullWidth : ""} ${rounded ? styles.rounded : ""}`}
      type={type}
      disabled={loading}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {label}
    </button>
  );
});
