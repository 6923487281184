import Layout from "./layout";
import styles from "./errors.module.css";

export default function NotFoundPage() {
  return (
    <Layout>
      <div className={styles.comingSoonContainer}>Coming Soon</div>
    </Layout>
  );
}
