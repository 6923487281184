import { useFetcher, useLoaderData } from "react-router-dom";
import { generateGetLoader, getPOStatus } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";
import Control from "../../../components/control";
import dayjs from "dayjs";
import styles from "./details.module.css";
import PrimaryButton from "../../../components/primary-button";
import Table from "../../../components/table";
import React from "react";
import { OrderEdit, FlashingEdit } from "../../../components/edit";
import Loader from "../../../components/loader";
import { Form } from "react-router-dom";
import { createGetRequest, createPostRequest } from "../../../lib/network";
import DropdownButton from "./dropdown-button";

export async function loader({ params }) {
  const response = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/getOne/${params.id}`,
      `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany?limit=50&page=1`,
    ],
    ["po", "cc"],
  )();
  return response;
}

export async function action() {
  return null;
}

export default function PurchaseOrderDetails() {
  const { po, cc } = useLoaderData();
  const fetcher = useFetcher();
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [details, setDetails] = React.useState(
    po.PurchaseOrder_Items.length > 0
      ? {
          type: "items",
          items: po.PurchaseOrder_Items.map((item) => ({
            supplier:
              item.Branch_Item_Object.Branch_Item.Item_Supplier.Supplier_Name,
            branch: item.Branch_Item_Object.Branch_ID.Branch_Name,
            name: `${item.Branch_Item_Object.Branch_Item.Item_Name} - ${item.Variant_Object.Variant_Size}`,
            cutting: item.Cutting_List,
            price: `$${item.Variant_Object.Variant_Cost_Price.toFixed(2)}`,
            colour: item.Variant_Object.Variant_Colour?.Colour_Name || "",
            totalPrice: `$${item.Total_Item_Price.toFixed(2)}`,
            itemID: item.Branch_Item_Object.Branch_Item?.Item_ID,
            variantID: item.Variant_Object.Variant_ID,
            unit: item.Variant_Object.Variant_Size_Type_Units,
            quantity: item.Quantity,
            raw: item,
            variantSize: item.Variant_Object.Variant_Size,
          })),
        }
      : {
          type: "flashings",
          flashings: po.PurchaseOrder_Flashing_Items.map((flashing) => {
            const firstEntry = flashing.Cutting_List[0];
            const unitPrice =
              ((firstEntry.Total_Size_Price / firstEntry.Quantity) * 1000) /
              firstEntry.Size;
            return {
              shape: `${process.env.REACT_APP_IMAGE_PREFIX}/${flashing.Image}`,
              girth: flashing.Total_Girth,
              bends: flashing.Total_Bends,
              cutting: flashing.Cutting_List,
              colour: flashing.Flashing_Variant.Colour.Colour_Name,
              price: unitPrice.toFixed(2),
              total: flashing.Cutting_List.reduce(
                (acc, curr) => acc + curr.Total_Size_Price,
                0,
              ),
              diagram: flashing.Diagram,
              variantID: flashing.Flashing_Variant.Colour._id,
              colourID: flashing.Flashing_Variant.Colour._id,
              prices: [],
              flashingBranchItem: "",
            };
          }),
        },
  );
  const [editedRow, setEditedRow] = React.useState("");
  const [updated, setUpdated] = React.useState(false);
  const formRef = React.useRef(null);
  const submitRef = React.useRef(null);

  async function handleSubmit() {
    submitRef.current.setAttribute("disabled", "true");
    let body = {
      PurchaseOrder_Delivery_Address: po.PurchaseOrder_Delivery_Address,
      PurchaseOrder_Delivery_Type: po.PurchaseOrder_Delivery_Type,
      PurchaseOrder_Supplier: po.PurchaseOrder_Supplier._id,
      PurchaseOrder_Branch: po.PurchaseOrder_Supplier_Branch._id,
      POType: details.type,
      UpdatePurchaseOrderID: po._id,
      PurchaseOrder_Date: po.PurchaseOrder_Date,
    };
    if (details.type === "items") {
      body = {
        ...body,
        PurchaseOrder_Items: details.items.map((item) => ({
          Variant: item.variantID,
          Branch_Item: item.raw.Branch_Item_Object._id,
          Quantity:
            item.quantity && !isNaN(item.quantity) ? item.quantity : undefined,
          Cutting_List: item.cutting?.map((record) => ({
            Quantity: record.Quantity,
            Size: record.Size,
          })),
        })),
      };
    } else {
      body = {
        ...body,
        PurchaseOrder_Flashing_Items: details.flashings.map((flashing) => {
          let image = "";
          const parsed = JSON.parse(flashing.diagram);
          if (flashing.shape.charAt(0).toLowerCase() === "h") {
            const segment = flashing.shape.split("/");
            image = segment[segment.length - 1];
          } else {
            image = flashing.shape;
          }
          return {
            Flashing_Branch_Item: flashing.flashingBranchItem,
            Flashing_Branch_Item_Variant: flashing.variantID,
            Cutting_List: flashing.cutting.map((record) => ({
              Quantity: record.Quantity,
              Size: record.Size,
            })),
            Diagram: flashing.diagram,
            Image: image,
            Tapered: parsed.tapered || false,
            Total_Girth: flashing.girth,
            Total_Bends: flashing.bends,
          };
        }),
      };
    }
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/update`,
      body,
    );
    submitRef.current.removeAttribute("disabled");
    setUpdated(false);
    formRef.current.requestSubmit();
  }

  React.useEffect(() => {
    async function getFlashingData() {
      const branch = po.PurchaseOrder_Supplier_Branch._id;
      const response = await createGetRequest(
        `${process.env.REACT_APP_BASE_URL}/api/flashingBranchItems/admin/getAll?branch=${branch}`,
      );
      if (!response.data) {
        setError(true);
      } else {
        setData(response.data);
        const data = response.data;
        const branchItemId = data[0]._id;
        const colourIdToVariant = data[0].Flashing_Variants.reduce(
          (acc, curr) => {
            acc[curr.Colour._id] = {
              id: curr._id,
              prices: curr.Cost_Price_Array.map((priceArray) =>
                priceArray.map((record) => ({
                  ...record,
                  Price: record.Cost_Price,
                  Tapered_Price: record.Tapered_Cost_Price,
                })),
              ),
            };
            return acc;
          },
          {},
        );
        setDetails((curr) => ({
          ...curr,
          flashings: curr.flashings.map((flashing) => {
            const entry = colourIdToVariant[flashing.variantID];
            return entry
              ? {
                  ...flashing,
                  variantID: colourIdToVariant[flashing.variantID].id,
                  prices: colourIdToVariant[flashing.variantID].prices,
                  flashingBranchItem: branchItemId,
                }
              : flashing;
          }),
        }));
      }
      setLoading(false);
    }
    if (po.PurchaseOrder_Items.length === 0) {
      getFlashingData();
    } else {
      setLoading(false);
    }
  }, [po.PurchaseOrder_Supplier_Branch, po.PurchaseOrder_Items]);

  const { text, colour } = getPOStatus(po);
  let inputClassName;
  // eslint-disable-next-line default-case
  switch (colour) {
    case "var(--success)":
      inputClassName = styles.success;
      break;
    case "var(--error)":
      inputClassName = styles.error;
      break;
    case "var(--info)":
      inputClassName = styles.info;
      break;
    case "var(--accent)":
      inputClassName = styles.accent;
  }
  let subtotal = 0;
  const orderRows =
    po.PurchaseOrder_Items.length > 0
      ? details.items.map((item, index) => {
          subtotal += parseFloat(item.totalPrice.slice(1));
          return [
            {
              value: index.toString(),
            },
            {
              value: item.supplier,
              vAlign: "top",
            },
            {
              value: item.branch,
              vAlign: "top",
            },
            {
              value: item.name,
              vAlign: "top",
            },
            {
              value:
                !item.cutting || item.cutting.length === 0 ? (
                  ""
                ) : (
                  <div className={styles.list}>
                    {item.cutting.map((record, index) => (
                      <div key={index.toString()}>
                        {record.Size} {item.unit}
                      </div>
                    ))}
                  </div>
                ),
            },
            {
              value: item.colour,
              vAlign: "top",
            },
            {
              value:
                !item.cutting || item.cutting.length === 0 ? (
                  item.quantity
                ) : (
                  <div className={styles.list}>
                    {item.cutting.map((record, index) => (
                      <div key={index.toString()}>{record.Quantity}</div>
                    ))}
                  </div>
                ),
              align: "center",
            },
            {
              value: item.price,
              vAlign: "top",
              align: "center",
            },
            {
              value: item.totalPrice,
              vAlign: "top",
              align: "center",
            },
          ];
        })
      : details.flashings.map((flashing, index) => {
          subtotal += flashing.total;
          return [
            {
              value: index.toString(),
            },
            {
              value: (
                <img
                  src={flashing.shape}
                  alt="Shape"
                  width={150}
                  height={196}
                />
              ),
              vAlign: "top",
            },
            {
              vAlign: "top",
              value: `${flashing.girth}mm`,
            },
            {
              vAlign: "top",
              value: `${flashing.bends}`,
            },
            {
              value: (
                <div className={styles.list}>
                  {flashing.cutting.map((record, index) => (
                    <div key={index.toString()}>{record.Size}MM</div>
                  ))}
                </div>
              ),
            },
            {
              vAlign: "top",
              value: flashing.colour,
            },
            {
              value: (
                <div className={styles.list}>
                  {flashing.cutting.map((record) => (
                    <div>{record.Quantity}</div>
                  ))}
                </div>
              ),
            },
            {
              vAlign: "top",
              value: `$${flashing.price}`,
            },
            {
              vAlign: "top",
              value: `$${flashing.total.toFixed(2)}`,
              align: "center",
            },
          ];
        });

  return (
    <div>
      <BreadCrums
        segments={[
          { label: "Purchase Orders", link: "../purchase-orders" },
          { label: `Puchase Order #${po.PurchaseOrder_Number}`, link: "." },
        ]}
      />
      {!po.PurchaseOrder_Sent_Status && (
        <div className={styles.banner}>
          <div className={styles.bannerText}>
            The purchase order has not been sent yet.
          </div>
          <PrimaryButton
            label="Send"
            loading={fetcher.state === "submitting"}
            rounded
            onClick={() =>
              fetcher.submit(
                { id: po._id },
                { method: "POST", action: "../purchase-orders/send" },
              )
            }
          />
        </div>
      )}
      <div className={styles.topSection}>
        <Control
          label="Supplier"
          id="supplier"
          name="Supplier"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Supplier.Supplier_Name}
        />
        <Control
          label="Issue Date"
          id="issue-date"
          name="Issue_Date"
          editable={false}
          fullWidth
          initialValue={dayjs(po.PurchaseOrder_Date).format("DD/MM/YYYY")}
        />
        <Control
          label="Delivery Type"
          id="delivery-type"
          name="Delivery_Type"
          editable={false}
          fullWidth
          initialValue={
            po.PurchaseOrder_Delivery_Type === "Delivery"
              ? "To Site"
              : po.PurchaseOrder_Delivery_Address ===
                "6/56 Montague St, North Wollongong, NSW"
              ? "To Pova"
              : "Pick Up"
          }
        />
        <Control
          label="Supplier Branch"
          id="supplier-branch"
          name="Supplier_Branch"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Supplier_Branch.Branch_Name}
          smallFont
        />
        <Control
          label="Shipping Address"
          id="shipping-address"
          name="Shipping_Address"
          editable={false}
          fullWidth
          initialValue={po.PurchaseOrder_Delivery_Address}
          smallFont
        />
        <Control
          label="Status"
          id="status"
          name="Status"
          editable={false}
          fullWidth
          initialValue={text}
          inputClassName={inputClassName}
          key={text}
        />
      </div>
      {error ? (
        <div className={styles.loadingContainer}>Something went wrong</div>
      ) : loading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <Form ref={formRef} method="POST">
          {po.PurchaseOrder_Items.length > 0 ? (
            <Table
              headers={[
                { name: "Supplier", width: "12.3%" },
                { name: "Branch", width: "12%" },
                { name: "Item", width: "19%" },
                { name: "Size", width: "12.4%" },
                { name: "Colour", width: "14.7%" },
                { name: "QTY", width: "8.1%" },
                { name: "PPU", width: "6.5%" },
                { name: "Item Total", width: "15%" },
              ]}
              rows={orderRows}
              onRowClick={
                po.PurchaseOrder_Sent_Status ? undefined : setEditedRow
              }
              bodyStyles={{
                height: "auto",
              }}
              content={
                po.PurchaseOrder_Sent_Status ? undefined : (
                  <OrderEdit
                    items={details.items}
                    editedRow={editedRow}
                    suppliers={[po.PurchaseOrder_Supplier]}
                    key={editedRow}
                    selectedDetails={{
                      supplier: po.PurchaseOrder_Supplier_Branch.Supplier,
                      branch: po.PurchaseOrder_Supplier_Branch._id,
                    }}
                    onDiscard={() => {
                      setEditedRow("");
                    }}
                    onAdd={(data) => {
                      setDetails((curr) => ({
                        ...curr,
                        items: [...curr.items, data],
                      }));
                      setUpdated(true);
                    }}
                    onEdit={(data, index) => {
                      setDetails((curr) => ({
                        ...curr,
                        items: [
                          ...curr.items.slice(0, index),
                          data,
                          ...curr.items.slice(index + 1),
                        ],
                      }));
                      setUpdated(true);
                    }}
                    onDelete={(index) => {
                      setDetails((curr) => ({
                        ...curr,
                        items: [
                          ...curr.items.slice(0, index),
                          ...curr.items.slice(index + 1),
                        ],
                      }));
                      setUpdated(true);
                    }}
                    colourCategories={cc.colourCategories}
                    cp
                  />
                )
              }
            />
          ) : (
            <Table
              bodyStyles={{
                height: "auto",
              }}
              headers={[
                { name: "Shape", width: "19.3%" },
                { name: "Girth", width: "12%" },
                { name: "Bends", width: "12" },
                { name: "Size", width: "12.4%" },
                { name: "Colour", width: "14.7%" },
                { name: "QTY", width: "8.1%" },
                { name: "PPM", width: "6.5%" },
                { name: "Item Total", width: "15%" },
              ]}
              rows={orderRows}
              onRowClick={
                po.PurchaseOrder_Sent_Status ? undefined : setEditedRow
              }
              content={
                <FlashingEdit
                  editedRow={editedRow}
                  key={editedRow}
                  flashings={details.flashings}
                  data={data}
                  onDiscard={() => {
                    setEditedRow("");
                  }}
                  onAdd={(data) => {
                    setDetails((curr) => ({
                      ...curr,
                      flashings: [...curr.flashings, data],
                    }));
                    setUpdated(true);
                  }}
                  onEdit={(data, index) => {
                    setDetails((curr) => ({
                      ...curr,
                      flashings: [
                        ...curr.flashings.slice(0, index),
                        data,
                        ...curr.flashings.slice(index + 1),
                      ],
                    }));
                    setUpdated(true);
                  }}
                  onDelete={(index) => {
                    setDetails((curr) => ({
                      ...curr,
                      flashings: [
                        ...curr.flashings.slice(0, index),
                        ...curr.flashings.slice(index + 1),
                      ],
                    }));
                    setUpdated(true);
                  }}
                  costPrice
                />
              }
            />
          )}
          {updated && (
            <section className={styles.buttonsContainer}>
              <div className={styles.priceText}>
                Purchase Order total might be incorrect, once you are done with
                changes click "Save Changes" to see the final amount.
              </div>
              <PrimaryButton
                label="Save Changes"
                type="button"
                onClick={handleSubmit}
                ref={submitRef}
              />
            </section>
          )}
          <div className={styles.bottomSection}>
            <div className={styles.pricingContainer}>
              <div>
                <div>SUBTOTAL</div>
                <div className={styles.price}>${subtotal.toFixed(2)}</div>
              </div>
              <div>
                <div>SHIPPING</div>
                <div className={styles.price}>
                  ${po.PurchaseOrder_Delivery_Price}
                </div>
              </div>
              <div>
                <div>TOTAL</div>
                <div className={styles.price}>
                  ${po.PurchaseOrder_Total_Price_GST}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
      <div className={styles.actionContainer}>
        <DropdownButton
          disabled={fetcher.state === "submitting"}
          update={(status) =>
            fetcher.submit(
              { id: po._id, status },
              { method: "POST", action: "../purchase-orders/status" },
            )
          }
        />
      </div>
    </div>
  );
}
