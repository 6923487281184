import BreadCrums from "../../../components/breadcrums";
import styles from "./pricing-details.module.css";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import React from "react";
import {
  Form,
  useBeforeUnload,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import Dropdown from "../../../components/dropdown";
import Branch from "./branch";
import Radio from "../../../components/radio";
import PrimaryButton from "../../../components/primary-button";
import Colours from "./colours";
import { FLASHINGS_KEY } from "./constants";
import Modal from "../../../components/modal";
import _ from "lodash";
import { createPostRequest } from "../../../lib/network";

export default function PricingDetails({
  heading = "Create Pricing",
  buttonLabel,
}) {
  const { suppliers, categories, colours, prevData } = useLoaderData();
  const stdId = categories.colourCategories.find(
    (item) => item.Colour_Category_Name === "Standard",
  )?._id;
  const [step, setStep] = React.useState(prevData?.step || 1);
  const [supplier, setSupplier] = React.useState(prevData?.supplier || "");
  const [taperedMode, setTaperedMode] = React.useState(
    prevData?.taperedMode || "fixed",
  );
  const [branches, setBranches] = React.useState(prevData?.branches || []);
  const [selectedTypes, setSelectedTypes] = React.useState(
    prevData?.selectedTypes || {
      [stdId]: true,
    },
  );
  const [selectedColours, setSelectedColours] = React.useState(
    prevData?.selectecColours || {
      [stdId]: { costPrice: "0", price: "0", colours: [] },
    },
  );
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(!!prevData);
  const formRef = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    function listener(e) {
      const code = e.keyCode;
      // listen for arrow key
      if (code >= 37 && code <= 40 && e.shiftKey) {
        const activeElement = document.activeElement;
        if (activeElement && activeElement.tagName === "INPUT") {
          const id = activeElement.getAttribute("id");
          if (id && /branch-.{24}-(cp|sp|girth)/.test(id)) {
            const segments = id.split("-");
            if (segments[2] === "girth") {
              const colIndex = parseInt(segments[3]);
              if (code === 37) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-girth-${colIndex - 1}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else if (code === 39) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-girth-${colIndex + 1}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else if (code === 40) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-cp-0-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              }
            } else if (segments[2] === "cp") {
              const row = parseInt(segments[3]);
              const colIndex = parseInt(segments[4]);
              if (code === 37) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-sp-${row}-${colIndex - 1}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else if (code === 39) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-sp-${row}-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else if (code === 40) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-cp-${row + 1}-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-cp-${row - 1}-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              }
            } else {
              const row = parseInt(segments[3]);
              const colIndex = parseInt(segments[4]);
              if (code === 37) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-cp-${row}-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else if (code === 39) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-cp-${row}-${colIndex + 1}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else if (code === 40) {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-sp-${row + 1}-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              } else {
                const newElement = document.getElementById(
                  `branch-${segments[1]}-sp-${row - 1}-${colIndex}`,
                );
                if (newElement) {
                  newElement.focus();
                }
              }
            }
          }
        }
      }
    }

    document.addEventListener("keydown", listener);

    return () => document.removeEventListener("keydown", listener);
  }, []);

  const beforeUnload = React.useCallback(
    function () {
      localStorage.setItem(
        FLASHINGS_KEY,
        JSON.stringify({
          step,
          supplier,
          taperedMode,
          branches,
          selectedTypes,
          selectedColours,
        }),
      );
    },
    [step, supplier, taperedMode, branches, selectedTypes, selectedColours],
  );

  useBeforeUnload(beforeUnload);

  const supplierOptions = suppliers.map((sup) => ({
    label: sup.Supplier_Name,
    value: sup._id,
  }));

  function onSupplierChange(newSup) {
    setBranches(
      suppliers
        .find((sup) => sup._id === newSup)
        .Branches.map((branch) => ({
          name: branch.Branch_Name,
          branch_sp: "",
          branch_delivery_cost: "",
          branch_large_delivery_cost: "",
          branch_edt: "",
          prices: [[{ size: "100mm - 150mm", cp: "", sp: "" }]],
          id: branch._id,
          tapered_cp: "",
          tapered_sp: "",
          tapered_cost: "",
        })),
    );
    setSupplier(newSup);
  }

  function moveToColours() {
    let isValid = true;
    branches.forEach((branch) => {
      if (!branch.branch_sp && branch.branch_sp !== 0) {
        isValid = false;
        document
          .getElementById(`branch-${branch.id}-selling-price`)
          .setCustomValidity("Selling price percentage is required");
      } else {
        document
          .getElementById(`branch-${branch.id}-selling-price`)
          .setCustomValidity("");
      }
      if (!branch.branch_delivery_cost && branch.branch_delivery_cost !== 0) {
        isValid = false;
        document
          .getElementById(`branch-${branch.id}-delivery-cost`)
          .setCustomValidity("Delivery cost is required");
      } else {
        document
          .getElementById(`branch-${branch.id}-delivery-cost`)
          .setCustomValidity("");
      }
      if (
        !branch.branch_large_delivery_cost &&
        branch.branch_large_delivery_cost !== 0
      ) {
        isValid = false;
        document
          .getElementById(`branch-${branch.id}-delivery-large`)
          .setCustomValidity("Delivery cost for length 8.5m+ is required");
      } else {
        document
          .getElementById(`branch-${branch.id}-delivery-large`)
          .setCustomValidity("");
      }
      if (!branch.branch_edt && branch.branch_edt !== 0) {
        isValid = false;
        document
          .getElementById(`branch-${branch.id}-edt`)
          .setCustomValidity("Estimated delivery time(EDT) is required");
      } else {
        document
          .getElementById(`branch-${branch.id}-edt`)
          .setCustomValidity("");
      }
      if (taperedMode === "fixed") {
        if (!branch.tapered_cost && branch.tapered_cost !== 0) {
          isValid = false;
          document
            .getElementById(`branch-${branch.id}-tapered-cost`)
            .setCustomValidity("Fixed cost for tapered flashings is required");
        } else {
          document
            .getElementById(`branch-${branch.id}-tapered-cost`)
            .setCustomValidity("");
        }
      } else {
        if (!branch.tapered_cp && branch.tapered_cp !== 0) {
          document
            .getElementById(`branch-${branch.id}-tapered-cost-price`)
            .setCustomValidity(
              "Cost price percentage for tapered flashings is required",
            );
        } else {
          document
            .getElementById(`branch-${branch.id}-tapered-cost-price`)
            .setCustomValidity("");
        }
        if (!branch.tapered_sp && branch.tapered_sp !== 0) {
          document
            .getElementById(`branch-${branch.id}-tapered-selling-price`)
            .setCustomValidity(
              "Selling price percentage for tapered flashings is required",
            );
        } else {
          document
            .getElementById(`branch-${branch.id}-tapered-selling-price`)
            .setCustomValidity("");
        }
      }

      branch.prices.forEach((bendRecord, index) => {
        bendRecord.forEach((record, i) => {
          if (!record.size) {
            isValid = false;
            document
              .getElementById(`branch-${branch.id}-girth-${i}`)
              .setCustomValidity("Girth is required");
          } else {
            document
              .getElementById(`branch-${branch.id}-girth-${i}`)
              .setCustomValidity("");
          }

          if (!record.cp || isNaN(parseFloat(record.cp))) {
            isValid = false;
            document
              .getElementById(`branch-${branch.id}-cp-${index}-${i}`)
              .setCustomValidity("Cost Price is required");
          } else {
            document
              .getElementById(`branch-${branch.id}-cp-${index}-${i}`)
              .setCustomValidity("");
          }

          if (!record.sp || isNaN(parseFloat(record.sp))) {
            isValid = false;
            document
              .getElementById(`branch-${branch.id}-sp-${index}-${i}`)
              .setCustomValidity("Selling Price is required");
          } else {
            document
              .getElementById(`branch-${branch.id}-sp-${index}-${i}`)
              .setCustomValidity("");
          }
        });
      });
    });

    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }
    setStep(2);
  }

  function handleModalAccept() {
    setModalOpen(false);
  }

  function handleModalCancel() {
    setStep(1);
    setSupplier("");
    setTaperedMode("fixed");
    setBranches([]);
    setSelectedTypes({
      [stdId]: true,
    });
    setSelectedColours({
      [stdId]: { costPrice: "0", price: "0", colours: [] },
    });
    setModalOpen(false);
  }

  async function handleSubmit() {
    setLoading(true);
    const cats = Object.entries(selectedTypes).filter(([_, val]) => val);
    if (cats.length === 0) {
      document
        .querySelector("[name=Colour_Type]")
        .setCustomValidity("At least one colour category is required");
      formRef.current.reportValidity();
      setLoading(false);
      return;
    } else {
      document.querySelector("[name=Colour_Type]").setCustomValidity("");
    }
    let isValid = true;
    cats.forEach(([key]) => {
      const value = selectedColours[key];
      if (!value || value.colours.length === 0) {
        isValid = false;
        document
          .querySelector(`[name=Colour_${key}]`)
          .setCustomValidity("At least one colour needs to be selected");
      } else {
        document.querySelector(`[name=Colour_${key}]`).setCustomValidity("");
      }
      if (!value || !value.costPrice || isNaN(parseFloat(value.costPrice))) {
        isValid = false;
        document
          .getElementById(`colour-cost-price-${key}`)
          .setCustomValidity("Cost price is required");
      } else {
        document
          .getElementById(`colour-cost-price-${key}`)
          .setCustomValidity("");
      }
      if (!value || !value.price || isNaN(parseFloat(value.price))) {
        isValid = false;
        document
          .getElementById(`colour-price-${key}`)
          .setCustomValidity("Selling price is required");
      } else {
        document.getElementById(`colour-price-${key}`).setCustomValidity("");
      }
    });

    if (!isValid) {
      formRef.current.reportValidity();
      setLoading(false);
      return;
    }
    const colours = Object.entries(selectedColours);
    const body = branches.map((branch) => {
      const minSize = branch.prices[0][0].size.split("-")[0].trim();
      const maxSize = branch.prices[0][branch.prices[0].length - 1].size
        .split("-")[1]
        .trim();

      const MIN_GIRTH = parseInt(minSize.slice(0, minSize.length - 2));
      const MAX_GIRTH = parseInt(maxSize.slice(0, maxSize.length - 2));

      return {
        Branch_ID: branch.id,
        Flashing_Variants: _.flatten(
          colours.map(([_, cat]) => {
            return cat.colours.map((colour) => ({
              Colour: colour,
              Min_Girth: MIN_GIRTH,
              Max_Girth: MAX_GIRTH,
              Cost_Price_Array: branch.prices.map((bendRecord) => {
                return bendRecord.map((record, index) => {
                  const size = record.size.split("-")[1].trim();
                  const cpRate = parseFloat(cat.costPrice);
                  return {
                    Max_Girth:
                      index !== bendRecord.length - 1
                        ? parseInt(size.slice(0, size.length - 2)) - 1
                        : parseInt(size.slice(0, size.length - 2)),
                    Cost_Price: parseFloat(
                      ((1 + cpRate / 100) * record.cp).toFixed(2),
                    ),
                    Tapered_Cost_Price: branch.tapered_cp
                      ? parseFloat(
                          (
                            (1 + cpRate / 100) *
                            (1 + branch.tapered_cp / 100) *
                            record.cp
                          ).toFixed(2),
                        )
                      : undefined,
                  };
                });
              }),
              Selling_Price_Array: branch.prices.map((bendRecord) => {
                return bendRecord.map((record, index) => {
                  const size = record.size.split("-")[1].trim();
                  const spRate = parseFloat(cat.price);
                  return {
                    Max_Girth:
                      index !== bendRecord.length - 1
                        ? parseInt(size.slice(0, size.length - 2)) - 1
                        : parseInt(size.slice(0, size.length - 2)),
                    Selling_Price: parseFloat(
                      ((1 + spRate / 100) * record.sp).toFixed(2),
                    ),
                    Tapered_Selling_Price: branch.tapered_sp
                      ? parseFloat(
                          (
                            (1 + spRate / 100) *
                            (1 + branch.tapered_sp / 100) *
                            record.sp
                          ).toFixed(2),
                        )
                      : undefined,
                    Tapered_Cost: branch.tapered_cost
                      ? branch.tapered_cost
                      : undefined,
                  };
                });
              }),
            }));
          }),
        ),
      };
    });

    const url = `${process.env.REACT_APP_BASE_URL}/api/flashingBranchItems/bulkAdd`;
    await createPostRequest(url, {
      Branch_Flashing_Items: body,
    });

    localStorage.removeItem(FLASHINGS_KEY);
    // re-route back
    setLoading(false);
    navigate("../flashings-pricing");
  }

  return modalOpen ? (
    <Modal open>
      <div className={styles.modalContainer}>
        <div className={styles.modalPrompt}>
          Would you edit the last saved draft?
        </div>
        <div className={styles.modalActionContainer}>
          <PrimaryButton
            variant="outlined"
            label="No"
            type="button"
            onClick={handleModalCancel}
          />
          <PrimaryButton
            label="Yes"
            type="button"
            onClick={handleModalAccept}
          />
        </div>
      </div>
    </Modal>
  ) : (
    <>
      <BreadCrums
        segments={[
          { label: "Flashings Pricing", link: "../flashings-pricing" },
          { label: heading, link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>{heading}</div>
        <CustomLink
          text="Create Item"
          icon={<IconAdd />}
          href="/user/flashings-pricing/add"
        />
      </div>
      <Form method="post" ref={formRef}>
        <section className={styles.topSection}>
          <div>
            <Dropdown
              name="Supplier"
              id="supplier"
              label="Supplier"
              required
              options={supplierOptions}
              placeholder="Select Supplier"
              value={supplier}
              onChange={onSupplierChange}
            />
          </div>
          <div>
            <div className={styles.taperedHeading}>Tapered</div>
            <Radio
              label="Fixed Cost"
              id="tapered-fixed-cost"
              name="Tapered_Cost_Type"
              required
              defaultChecked={taperedMode === "fixed"}
              containerStyles={{
                marginBottom: 10,
              }}
              value="fixed"
              onChange={(newMode) => {
                setTaperedMode(newMode);
                setStep(1);
              }}
            />
            <Radio
              label="Price"
              id="tapered-price"
              name="Tapered_Cost_Type"
              required
              defaultChecked={taperedMode === "price"}
              value="price"
              onChange={(newMode) => {
                setTaperedMode(newMode);
                setStep(1);
              }}
            />
          </div>
        </section>
        <div className={styles.tabsContainer}>
          <div
            className={`${styles.tab} ${step === 1 ? styles.activeTab : ""}`}
            onClick={() => setStep(1)}
          >
            Pricing
          </div>
          <div
            className={`${styles.tab} ${step === 2 ? styles.activeTab : ""} ${
              styles.noClick
            }`}
          >
            Colours
          </div>
        </div>
        <section className={step === 1 ? styles.visible : styles.hidden}>
          {branches.map((branch, index) => (
            <Branch
              key={branch.id}
              data={branch}
              onChange={(newBranch) => {
                setBranches((curr) => [
                  ...curr.slice(0, index),
                  newBranch,
                  ...curr.slice(index + 1),
                ]);
              }}
              taperedMode={taperedMode}
            />
          ))}
          {supplier && (
            <div className={styles.nextContainer}>
              <PrimaryButton label="Next" onClick={moveToColours} />
            </div>
          )}
        </section>
        <section className={step === 2 ? styles.visible : styles.hidden}>
          <Colours
            selectedColours={selectedColours}
            selectedTypes={selectedTypes}
            setSelectedColours={setSelectedColours}
            setSelectedTypes={setSelectedTypes}
            categories={categories.colourCategories}
            colours={colours}
          />
          <div className={styles.padder}>
            {buttonLabel && (
              <PrimaryButton
                label={buttonLabel}
                loading={loading}
                onClick={handleSubmit}
              />
            )}
          </div>
        </section>
      </Form>
    </>
  );
}
