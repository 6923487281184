export function formatFormData(formData) {
  const data = Object.fromEntries(formData);
  const formattedData = { item: {}, branches: {}, variants: {} };
  formattedData.item.Item_Industry = formData.getAll("Item_Industry");
  formattedData.item.Item_Category = formData.getAll("Item_Category");
  const handledKeys = [
    "Item_Image",
    "Item_Image_Large",
    "Item_Industry",
    "Item_Category",
  ];
  Object.entries(data).forEach(([key, value]) => {
    if (!handledKeys.includes(key) && !!value) {
      if (key.charAt(0) === "B") {
        const [_, bKey, bId] = key.split(/([a-zA-Z_]+)-(.+)/);
        if (!formattedData.branches[bId]) {
          formattedData.branches[bId] = {};
        }
        formattedData.branches[bId][bKey] = value;
      } else if (key.charAt(0) === "V") {
        const [_, vKey, vId] = key.split(/([a-zA-Z_0-9]+)-(.+)/);
        if (!formattedData.variants[vId]) {
          formattedData.variants[vId] = {};
        }
        if (vKey === "Variant_Colour_Type") {
          formattedData.variants[vId][vKey] = formData.getAll(key);
        } else if (vKey.startsWith("Variant_Colour")) {
          const segments = vKey.split("_");
          let category;
          if (segments[2] === "Price") {
            category = segments[3];
            formattedData.variants[vId]["Variant_Colour"] = {
              ...(formattedData.variants[vId]["Variant_Colour"] || {}),
              [category]: {
                ...(formattedData.variants[vId]["Variant_Colour"]
                  ? formattedData.variants[vId]["Variant_Colour"][category]
                  : {}),
                price: value,
              },
            };
          } else if (segments[2] === "Cost") {
            category = segments[4];
            formattedData.variants[vId]["Variant_Colour"] = {
              ...(formattedData.variants[vId]["Variant_Colour"] || {}),
              [category]: {
                ...(formattedData.variants[vId]["Variant_Colour"]
                  ? formattedData.variants[vId]["Variant_Colour"][category]
                  : {}),
                costPrice: value,
              },
            };
          } else {
            category = segments[2];
            formattedData.variants[vId]["Variant_Colour"] = {
              ...(formattedData.variants[vId]["Variant_Colour"] || {}),
              [category]: {
                ...(formattedData.variants[vId]["Variant_Colour"]
                  ? formattedData.variants[vId]["Variant_Colour"][category]
                  : {}),
                colours: formData.getAll(key),
              },
            };
          }
        } else {
          formattedData.variants[vId][vKey] = value;
        }
      } else {
        formattedData.item[key] = value;
      }
    }
  });
  const inactiveBranches = {};
  Object.entries(formattedData.branches).forEach(([key, branch]) => {
    if (branch["Branch_Available"] !== "Available") {
      inactiveBranches[key] = true;
    }
  });
  Object.keys(inactiveBranches).forEach(
    (id) => delete formattedData.branches[id],
  );
  return formattedData;
}
