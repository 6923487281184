import React from "react";
import styles from "./searchbar.module.css";

export default function Searchbar({ onSubmit }) {
  const [search, setSearch] = React.useState("");

  function handleKeyDown(e) {
    const key = e.key;
    if (key === "Enter") {
      onSubmit(search);
      setSearch("");
    }
  }

  return (
    <div className={styles.container}>
      <input
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Search for items"
        className={styles.search}
      />
      {search && (
        <div className={styles.helper}>Press &quot;Enter&quot; to search</div>
      )}
    </div>
  );
}
