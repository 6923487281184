import FormControlContainer from "../../../../components/form-control-container";
import styles from "./date-picker.module.css";

export default function DatePicker({
  label,
  id,
  value,
  onChange,
  required,
  min,
  max,
}) {
  return (
    <FormControlContainer id={id} label={label}>
      <input
        type="date"
        id={id}
        value={value}
        onChange={onChange}
        className={styles.input}
        required={required}
        min={min}
        max={max}
        placeholder="dd-mm-yyyy"
      />
    </FormControlContainer>
  );
}
