import { ReactComponent as IconDashboard } from "../assets/icons/Dashboard.svg";
import { ReactComponent as IconOrders } from "../assets/icons/Orders.svg";
import { ReactComponent as IconItems } from "../assets/icons/Items.svg";
import { ReactComponent as IconInvoices } from "../assets/icons/Invoices.svg";
import { ReactComponent as IconInventoryManager } from "../assets/icons/Inventory Manager.svg";
import { ReactComponent as IconCustomers } from "../assets/icons/Customers.svg";
import { ReactComponent as IconPaymentReceived } from "../assets/icons/Payment Received.svg";
// import { ReactComponent as IconPaymentMade } from "../assets/icons/Payment Made.svg";
import { ReactComponent as IconBills } from "../assets/icons/Bills.svg";
import { ReactComponent as IconPurchaseOrders } from "../assets/icons/Purchase Orders.svg";
import { ReactComponent as IconReceives } from "../assets/icons/Receives.svg";
import { ReactComponent as IconCategories } from "../assets/icons/Categories.svg";
import { ReactComponent as IconColours } from "../assets/icons/Colours.svg";
import { ReactComponent as IconSuppliers } from "../assets/icons/Suppliers.svg";

export const TOKEN = "token";
export const pages = [
  {
    title: "Dashboard",
    icon: <IconDashboard />,
    // link: "/user/dashboard",
  },
  {
    title: "Orders",
    icon: <IconOrders />,
    link: "/user/orders",
  },
  {
    title: "Invoices",
    icon: <IconInvoices />,
    link: "/user/invoices",
  },
  {
    title: "Purchase Orders",
    icon: <IconPurchaseOrders />,
    link: "/user/purchase-orders",
  },
  {
    title: "Items",
    icon: <IconItems />,
    link: "/user/items",
  },

  {
    title: "Industries",
    icon: <IconCategories />,
    link: "/user/industries",
  },
  {
    title: "Categories",
    icon: <IconCategories />,
    link: "/user/categories",
  },
  {
    title: "Colours",
    icon: <IconColours />,
    link: "/user/colours",
  },
  {
    title: "Colour Categories",
    icon: <IconColours />,
    link: "/user/colour-categories",
  },
  {
    title: "Suppliers",
    icon: <IconSuppliers />,
    link: "/user/suppliers",
  },
  {
    title: "Flashings Shape Library",
    icon: <IconCategories />,
    link: "/user/flashings-shape-library",
  },
  {
    title: "Flashings Pricing",
    icon: <IconPaymentReceived />,
    link: "/user/flashings-pricing",
  },
  {
    title: "Customers",
    icon: <IconCustomers />,
    // link: "/user/customers",
  },
  {
    title: "Payments",
    icon: <IconPaymentReceived />,
    // link: "/user/payments",
  },
  {
    title: "Bills",
    icon: <IconBills />,
    // link: "/user/bills",
  },
  // {
  //   title: "Payment Received",
  //   icon: <IconPaymentReceived />,
  //   link: "/user/payment-received",
  // },
  // {
  //   title: "Payment Made",
  //   icon: <IconPaymentMade />,
  //   link: "/user/payment-made",
  // },

  {
    title: "Receives",
    icon: <IconReceives />,
    // link: "/user/receives",
  },
  {
    title: "Inventory Manager",
    icon: <IconInventoryManager />,
    // link: "/user/inventory-manager",
  },
];
