import * as React from "react";
import styles from "./variant-color.module.css";
import MutliSelect from "../../../components/multi-select";
import Control from "../../../components/control";
import { ReactComponent as Dollar } from "../../../assets/icons/$.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import Palette from "../../../components/palette";

export default function VariantColor({ id, colours, prevData, categories }) {
  const [selectedTypes, setSelectedTypes] = React.useState(
    prevData?.Variant_Colour_Type?.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {}) || {},
  );
  const [selectedColours, setSelectedColours] = React.useState(
    prevData?.Variant_Colour || {},
  );

  const colourOptions = colours.reduce((acc, curr) => {
    const cat = curr.Colour_Category._id;
    if (!acc[cat]) {
      acc[cat] = [];
    }
    acc[cat].push({
      label: curr.Colour_Name,
      value: `${curr._id}#${id}`,
      code: curr.Colour_Code,
      secCode: curr.Secondary_Colour_Code,
    });
    return acc;
  }, {});
  const categoryIdToCat = categories.reduce((acc, curr) => {
    acc[curr._id] = curr;
    return acc;
  }, {});

  function handleChange(newValue) {
    setSelectedTypes(
      newValue.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {}),
    );
  }

  function deleteColour(cat, id) {
    setSelectedColours((curr) => ({
      ...curr,
      [cat]: {
        ...(curr[cat] || {}),
        colours: curr[cat].colours.filter((item) => item !== id),
      },
    }));
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.heading}>Choose Colour Range</div>
        <div className={styles.colourTypesRow}>
          <MutliSelect
            id={`variant-colour-type-${id}`}
            name={`Variant_Colour_Type-${id}`}
            label="Colour Types"
            placeholder="Choose"
            options={categories.map((cat) => ({
              label: cat.Colour_Category_Name,
              value: `${cat._id}#${id}`,
            }))}
            fullWidth
            value={Object.entries(selectedTypes)
              .filter(([_, value]) => value)
              .map((item) => item[0])}
            onChange={handleChange}
          />
          <div className={styles.coloursContainer}>
            {Object.entries(selectedTypes)
              .filter(([_, value]) => value)
              .map((stype) => {
                const id = stype[0];
                const k = id.split("#")[0];
                const name = categoryIdToCat[k]?.Colour_Category_Name || k;
                return (
                  <div key={id} className={styles.colourContainer}>
                    <div className={styles.colourName}>{name}</div>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedTypes((curr) => ({
                          ...curr,
                          [id]: false,
                        }));
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {Object.entries(selectedTypes).map(([k, value]) => {
        const key = k.split("#")[0];
        return !value ? null : (
          <div key={key} className={styles.row}>
            <MutliSelect
              id={`variant-colour-dropdown-${key}-${id}`}
              name={`Variant_Colour_${key}-${id}`}
              label={categoryIdToCat[key]?.Colour_Category_Name || key}
              placeholder="Choose"
              options={colourOptions[key] || []}
              fullWidth
              addAll
              value={selectedColours[key]?.colours || []}
              onChange={(newValue) =>
                setSelectedColours((curr) => ({
                  ...curr,
                  [key]: { ...(curr[key] || {}), colours: newValue },
                }))
              }
            />
            <Control
              id={`variant-colour-cost-price-${key}-${id}`}
              name={`Variant_Colour_Cost_Price_${key}-${id}`}
              required
              fullWidth
              label="Cost Price"
              endIcon={<Dollar />}
              type="number"
              step="any"
              initialValue={selectedColours[key]?.costPrice}
            />
            <Control
              id={`variant-colour-price-${key}-${id}`}
              name={`Variant_Colour_Price_${key}-${id}`}
              required
              fullWidth
              label="Price"
              endIcon={<Dollar />}
              type="number"
              step="any"
              initialValue={selectedColours[key]?.price}
            />

            <div className={styles.coloursContainer}>
              {selectedColours[key]?.colours?.map((colorId) => {
                const colourObj = colourOptions[key].find(
                  (item) => item.value === colorId,
                );
                return (
                  <div key={colorId} className={styles.colourContainer}>
                    <div className={styles.colour}>
                      <div className={styles.colourWrap}>
                        <Palette
                          code={colourObj.code}
                          borderRadius={15}
                          height={30}
                          width={30}
                          styles={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        />
                        {colourObj.secCode ? (
                          <Palette
                            code={colourObj.secCode}
                            borderRadius={15}
                            height={30}
                            width={30}
                            styles={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              clipPath: "path('M 15,0 A 15 15 0 1 1 15 30')",
                            }}
                          />
                        ) : null}
                      </div>
                      <div className={styles.colourName}>{colourObj.label}</div>
                    </div>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteColour(key, colorId)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
