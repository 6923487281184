import { NavLink } from "react-router-dom";
import styles from "./custom-link.module.css";

export default function CustomLink({ href, icon, text }) {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        `${styles.container} ${isActive ? styles.active : ""}`
      }
    >
      {icon}
      <span className={styles.text}>{text} </span>
    </NavLink>
  );
}
