import PageHeader from "../components/page-header";
import useChildRoute from "../hooks/useChildRoute";
import Table from "../components/table";
import { useLoaderData } from "react-router-dom";

export default function FlashingsPricing() {
  const { branchItems } = useLoaderData();
  const isChildRouter = useChildRoute("/user/flashings-pricing");

  const supplierRows = branchItems.map((record) => [
    { value: record.BranchID.Supplier.Supplier_Name },
    { value: `${record.Flashing_Variants.length} Variants` },
  ]);

  return (
    <>
      {!isChildRouter && <PageHeader text="Flashings Pricing" />}
      <Table
        headers={[
          { name: "Supplier", width: "25%" },
          { name: "Variants", width: "25%" },
        ]}
        rows={supplierRows}
        bodyStyles={{ height: "calc(100vh - 350px)" }}
      />
    </>
  );
}
