import { Form, redirect, useLoaderData, useNavigation } from "react-router-dom";
import { createGetRequest, createPostRequest } from "../../../lib/network";
import BreadCrums from "../../../components/breadcrums";
import styles from "./create-po.module.css";
import React from "react";
import Dropdown from "../../../components/dropdown";
import { useLoadScript } from "@react-google-maps/api";
import GoogleAutocomplete from "../../../components/google-autocomplete";
import Table from "../../../components/table";
import { FlashingEdit, OrderEdit } from "../../../components/edit";
import Loader from "../../../components/loader";
import PrimaryButton from "../../../components/primary-button";

export async function action({ request }) {
  const formData = await request.formData();
  const orderID = formData.get("orderID");
  if (orderID) {
    const supplierID = formData.get("supplierID");
    const POType = formData.get("POType");
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/createFromOrder`,
      { orderID, supplierID, POType },
    );
    return null;
  }
  return redirect("/user/purchase-orders");
}

const libs = ["places"];

export default function CreatePO() {
  const { suppliers, colourCategories } = useLoaderData();
  const [details, setDetails] = React.useState({
    supplier: "",
    branch: "",
    items: [],
    flashings: [],
  });
  const [flashingEditedRow, setFlashingEditedRow] = React.useState("");
  const [editedRow, setEditedRow] = React.useState("");
  const { isLoaded } = useLoadScript({
    libraries: libs,
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const autoCompleteService = React.useRef();
  const { state } = useNavigation();
  const submitButtonRef = React.useRef(null);
  const formRef = React.useRef(null);

  React.useEffect(() => {
    if (isLoaded) {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  const supplierOptions = suppliers.map((sup) => ({
    label: sup.Supplier_Name,
    value: sup._id.toString(),
  }));

  const branchOptions =
    suppliers
      .find((item) => item._id === details.supplier)
      ?.Branches.map((branch) => ({
        label: branch.Branch_Name,
        value: branch._id,
      })) || [];

  let subtotal = 0;

  const flashingRows = details.flashings.map((flashing, index) => {
    subtotal += flashing.total;
    return [
      {
        value: index.toString(),
      },
      {
        value: (
          <img src={flashing.shape} alt="Shape" width={150} height={196} />
        ),
        vAlign: "top",
      },
      {
        vAlign: "top",
        value: `${flashing.girth}mm`,
      },
      {
        vAlign: "top",
        value: `${flashing.bends}`,
      },
      {
        value: (
          <div className={styles.list}>
            {flashing.cutting.map((record, index) => (
              <div key={index.toString()}>{record.Size}MM</div>
            ))}
          </div>
        ),
      },
      {
        vAlign: "top",
        value: flashing.colour,
      },
      {
        value: (
          <div className={styles.list}>
            {flashing.cutting.map((record) => (
              <div>{record.Quantity}</div>
            ))}
          </div>
        ),
      },
      {
        vAlign: "top",
        value: `$${flashing.price}`,
      },
      {
        vAlign: "top",
        value: `$${flashing.total.toFixed(2)}`,
        align: "center",
      },
    ];
  });
  const orderRows = details.items.map((item, index) => {
    subtotal += parseFloat(item.totalPrice.slice(1));
    return [
      {
        value: index.toString(),
      },
      {
        value: item.supplier,
        vAlign: "top",
      },
      {
        value: item.branch,
        vAlign: "top",
      },
      {
        value: item.name,
        vAlign: "top",
      },
      {
        value:
          !item.cutting || item.cutting.length === 0 ? (
            ""
          ) : (
            <div className={styles.list}>
              {item.cutting.map((record, index) => (
                <div key={index.toString()}>
                  {record.Size} {item.unit}
                </div>
              ))}
            </div>
          ),
      },
      {
        value: item.colour,
        vAlign: "top",
      },
      {
        value:
          !item.cutting || item.cutting.length === 0 ? (
            item.quantity
          ) : (
            <div className={styles.list}>
              {item.cutting.map((record, index) => (
                <div key={index.toString()}>{record.Quantity}</div>
              ))}
            </div>
          ),
        align: "center",
      },
      {
        value: item.price,
        vAlign: "top",
        align: "center",
      },
      {
        value: item.totalPrice,
        vAlign: "top",
        align: "center",
      },
    ];
  });

  React.useEffect(() => {
    async function getFlashingData() {
      setLoading(true);
      const response = await createGetRequest(
        `${process.env.REACT_APP_BASE_URL}/api/flashingBranchItems/admin/getAll?branch=${details.branch}`,
      );
      if (!response.data) {
        setError(true);
      } else {
        setData(response.data);
      }
      setLoading(false);
    }
    if (details.supplier && details.branch) {
      getFlashingData();
    }
  }, [details.supplier, details.branch]);

  async function handleSubmit() {
    submitButtonRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const address = formData.get("Delivery_Address");
    const element = document.getElementById("delivery-address");
    if (!address) {
      element.setCustomValidity("Address is required");
      formRef.current.reportValidity();
      submitButtonRef.current.removeAttribute("disabled");
      return;
    } else {
      element.setCustomValidity("");
    }

    const sharedBody = {
      PurchaseOrder_Delivery_Address: address,
      PurchaseOrder_Delivery_Type: formData.get("DeliveryType"),
      PurchaseOrder_Supplier: details.supplier,
      PurchaseOrder_Branch: details.branch,
    };

    const promises = [];

    if (details.items.length > 0) {
      const body = {
        ...sharedBody,
        POType: "items",
        PurchaseOrder_Items: details.items.map((item) => ({
          Variant: item.variantID,
          Branch_Item: item.raw.Branch_Item_Object._id,
          Quantity:
            item.quantity && !isNaN(item.quantity) ? item.quantity : undefined,
          Cutting_List: item.cutting?.map((record) => ({
            Quantity: record.Quantity,
            Size: record.Size,
          })),
        })),
      };
      promises.push(
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/create`,
          body,
        ),
      );
    }

    if (details.flashings.length > 0) {
      const body = {
        ...sharedBody,
        POType: "flashings",
        PurchaseOrder_Flashing_Items: details.flashings.map((flashing) => {
          const parsed = JSON.parse(flashing.diagram);
          return {
            Flashing_Branch_Item: flashing.flashingBranchItem,
            Flashing_Branch_Item_Variant: flashing.variantID,
            Cutting_List: flashing.cutting.map((record) => ({
              Quantity: record.Quantity,
              Size: record.Size,
            })),
            Diagram: flashing.diagram,
            Image: flashing.shape,
            Tapered: parsed.tapered || false,
            Total_Girth: flashing.girth,
            Total_Bends: flashing.bends,
          };
        }),
      };
      promises.push(
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/create`,
          body,
        ),
      );
    }

    await Promise.all(promises);
    submitButtonRef.current.removeAttribute("disabled");
    formRef.current.requestSubmit();
  }

  return (
    <div>
      <BreadCrums
        segments={[
          { label: "Purchase Orders", link: "../purchase-orders" },
          { label: "Create Purchase Order", link: "." },
        ]}
      />
      <Form ref={formRef} method="POST">
        <section className={styles.topSection}>
          <Dropdown
            name="PO_Supplier"
            id="po-supplier"
            label="Supplier"
            required
            options={supplierOptions}
            placeholder="Select Supplier"
            value={details.supplier}
            fullWidth
            onChange={(newSup) =>
              setDetails((curr) => ({
                ...curr,
                supplier: newSup,
                branch: "",
                items: [],
                flashings: [],
              }))
            }
          />
          <Dropdown
            name="PO_Branch"
            id="po-branch"
            label="Branch"
            required
            options={branchOptions}
            placeholder="Select Branch"
            fullWidth
            key={details.supplier}
            onChange={(newBranch) =>
              setDetails((curr) => ({
                ...curr,
                branch: newBranch,
                items: [],
                flashings: [],
              }))
            }
            value={details.branch}
          />
          <Dropdown
            name="DeliveryType"
            id="delivery-type"
            label="Delivery Type"
            required
            options={[
              { value: "Delivery", label: "To Site" },
              { value: "Pickup", label: "Pick Up" },
            ]}
            placeholder="Select Delivery Type"
            fullWidth
            value="Pickup"
          />
          <GoogleAutocomplete
            label="Address"
            id="delivery-address"
            name="Delivery_Address"
            fullWidth
            smallFont
            autocompleteService={autoCompleteService}
            required
          />
        </section>
        {error ? (
          <div className={styles.loadingContainer}>Something went wrong</div>
        ) : loading ? (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        ) : (
          details.branch && (
            <>
              <section className={styles.tableContainer}>
                <Table
                  bodyStyles={{
                    height: "auto",
                  }}
                  headers={[
                    { name: "Shape", width: "19.3%" },
                    { name: "Girth", width: "12%" },
                    { name: "Bends", width: "12" },
                    { name: "Size", width: "12.4%" },
                    { name: "Colour", width: "14.7%" },
                    { name: "QTY", width: "8.1%" },
                    { name: "PPM", width: "6.5%" },
                    { name: "Item Total", width: "15%" },
                  ]}
                  rows={flashingRows}
                  onRowClick={(id) => setFlashingEditedRow(id)}
                  content={
                    <FlashingEdit
                      editedRow={flashingEditedRow}
                      key={flashingEditedRow}
                      flashings={details.flashings}
                      data={data}
                      onDiscard={() => {
                        setFlashingEditedRow("");
                      }}
                      onAdd={(data) => {
                        setDetails((curr) => ({
                          ...curr,
                          flashings: [...curr.flashings, data],
                        }));
                      }}
                      onEdit={(data, index) => {
                        setDetails((curr) => ({
                          ...curr,
                          flashings: [
                            ...curr.flashings.slice(0, index),
                            data,
                            ...curr.flashings.slice(index + 1),
                          ],
                        }));
                      }}
                      onDelete={(index) => {
                        setDetails((curr) => ({
                          ...curr,
                          flashings: [
                            ...curr.flashings.slice(0, index),
                            ...curr.flashings.slice(index + 1),
                          ],
                        }));
                      }}
                      costPrice
                    />
                  }
                />
                <Table
                  headers={[
                    { name: "Supplier", width: "12.3%" },
                    { name: "Branch", width: "12%" },
                    { name: "Item", width: "19%" },
                    { name: "Size", width: "12.4%" },
                    { name: "Colour", width: "14.7%" },
                    { name: "QTY", width: "8.1%" },
                    { name: "PPU", width: "6.5%" },
                    { name: "Item Total", width: "15%" },
                  ]}
                  rows={orderRows}
                  onRowClick={(id) => setEditedRow(id)}
                  bodyStyles={{
                    height: "auto",
                  }}
                  content={
                    <OrderEdit
                      items={details.items}
                      editedRow={editedRow}
                      selectedDetails={{
                        supplier: details.supplier,
                        branch: details.branch,
                      }}
                      suppliers={suppliers}
                      key={editedRow}
                      onDiscard={() => {
                        setEditedRow("");
                      }}
                      onAdd={(data) => {
                        setDetails((curr) => ({
                          ...curr,
                          items: [...curr.items, data],
                        }));
                      }}
                      onEdit={(data, index) => {
                        setDetails((curr) => ({
                          ...curr,
                          items: [
                            ...curr.items.slice(0, index),
                            data,
                            ...curr.items.slice(index + 1),
                          ],
                        }));
                      }}
                      onDelete={(index) => {
                        setDetails((curr) => ({
                          ...curr,
                          items: [
                            ...curr.items.slice(0, index),
                            ...curr.items.slice(index + 1),
                          ],
                        }));
                      }}
                      colourCategories={colourCategories.colourCategories}
                      cp
                    />
                  }
                />
              </section>
              <div className={styles.bottomSection}>
                <div className={styles.pricingContainer}>
                  <div>
                    <div>SUBTOTAL</div>
                    <div className={styles.price}>${subtotal.toFixed(2)}</div>
                  </div>
                </div>
              </div>
              <div className={styles.actionContainer}>
                <PrimaryButton
                  label="Create Purchase Order"
                  type="button"
                  onClick={handleSubmit}
                  loading={
                    state === "submitting" ||
                    state === "loading" ||
                    (details.items.length === 0 &&
                      details.flashings.length === 0)
                  }
                  ref={submitButtonRef}
                />
              </div>
            </>
          )
        )}
      </Form>
    </div>
  );
}
