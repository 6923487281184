import React from "react";
import { TOKEN } from "../lib/constants";

export default function useToken() {
  const [token, setToken] = React.useState(localStorage.getItem(TOKEN));

  React.useEffect(() => {
    function storageChanged() {
      const newValue = localStorage.getItem(TOKEN);
      setToken(newValue);
    }
    window.addEventListener("storage", storageChanged);

    return () => window.removeEventListener("storage", storageChanged);
  }, []);

  return token;
}
