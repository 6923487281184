import "reactflow/dist/style.css";
import {
  useReactFlow,
  getNodesBounds,
  getViewportForBounds,
  applyNodeChanges,
  ReactFlow,
  Background,
  BackgroundVariant,
} from "reactflow";
import Modal from "../modal";
import styles from "./flashing.module.css";
import React from "react";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { toPng } from "html-to-image";
import { getNewCoords, ColourContext, FoldDir, ColourDir } from "./utils";
import { nodeTypes, edgeTypes } from "./custom-types";
import ColourDirection from "./colour-direction";
import NodeActions from "./node-actions";
import Actions from "./actions";
import Girth from "./girth";

export default function Flashing({ open, onClose, onCompleted, shape }) {
  const [colourDir, setColourDir] = React.useState(ColourDir.OUTSIDE);
  const [nodes, setNodes] = React.useState([]);
  const [edges, setEdges] = React.useState([]);
  const [tapered, setTapered] = React.useState(false);
  const [foldableNode, setFoldableNode] = React.useState();
  const [foldedNodes, setFoldedNodes] = React.useState([]);
  const doneRef = React.useRef();

  const { screenToFlowPosition, getNode, getNodes, toObject, setViewport } =
    useReactFlow();

  const addPoint = React.useCallback(
    (e) => {
      function onEdgeUpdate(id, label, taperedLabel) {
        setEdges((curr) => {
          const newEdges = [];
          curr.forEach((edge) => {
            if (edge.id !== id) {
              newEdges.push(edge);
            } else {
              newEdges.push({
                ...edge,
                label: taperedLabel ? edge.label : label,
                data: { ...edge.data, taperedLabel: label },
              });
            }
          });
          return newEdges;
        });
      }

      function onAngleUpdate(id, prevAngle, angle) {
        const index = parseInt(id) - 1;
        setNodes((curr) => {
          const newNodes = [...curr.slice(0, index)];
          newNodes.push({ ...curr[index], data: { ...curr[index].data } });
          newNodes.push({
            ...curr[index + 1],
            position: getNewCoords(
              curr[index].position,
              curr[index - 1].position,
              curr[index + 1].position,
              prevAngle,
              angle,
            ),
            data: {
              ...curr[index + 1].data,
            },
          });
          if (index + 2 < curr.length) {
            newNodes.push({
              ...curr[index + 2],
              data: { ...curr[index + 2].data },
            });
            newNodes.push(...curr.slice(index + 3));
          }
          return newNodes;
        });
      }
      let isLastFolded = false;
      let lastFoldDir = FoldDir.UP;
      setNodes((curr) => {
        let edgeAdded;
        let segments = curr;
        if (curr.length > 0) {
          setEdges((edgeCurr) => {
            const lastNode = curr[curr.length - 1];
            if (lastNode.data.folded) {
              isLastFolded = true;
              lastFoldDir = lastNode.data.folDir;
              setFoldableNode(undefined);
              setFoldedNodes((foldNodesCurr) => {
                const index = foldNodesCurr.findIndex(
                  (item) => item === lastNode.id,
                );
                return [
                  ...foldNodesCurr.slice(0, index),
                  (curr.length + 1).toString(),
                  ...foldNodesCurr.slice(index + 1),
                ];
              });
            }
            const edge = {
              id: `${curr.length}-${curr.length + 1}`,
              source: curr.length.toString(),
              target: (curr.length + 1).toString(),
              type: "double",
              label: String.fromCodePoint("A".codePointAt(0) + edgeCurr.length),
              data: {
                onEdgeUpdate,
                tapered,
                taperedLabel: `${String.fromCodePoint(
                  "A".codePointAt(0) + edgeCurr.length,
                )}"`,
              },
            };
            edgeAdded = {
              n1: curr.length.toString(),
              n2: (curr.length + 1).toString(),
            };
            segments = [
              ...segments.slice(0, segments.length - 1),
              {
                ...segments[segments.length - 1],
                data: {
                  ...segments[segments.length - 1].data,
                  edges: [
                    ...segments[segments.length - 1].data.edges,
                    edgeAdded,
                  ],
                  folded: false,
                  folDir: undefined,
                },
              },
            ];
            return [...edgeCurr, edge];
          });
        }
        const position = screenToFlowPosition({
          x: e.clientX,
          y: e.clientY,
        });
        position.x = Math.round(position.x / 20) * 20;
        position.y = Math.round(position.y / 20) * 20;
        return [
          ...segments,
          {
            id: (curr.length + 1).toString(),
            type: "point",
            position,
            data: {
              edges: edgeAdded ? [edgeAdded] : [],
              onAngleUpdate: onAngleUpdate,
              tapered,
              folded: isLastFolded,
              folDir: lastFoldDir,
            },
          },
        ];
      });
    },
    [screenToFlowPosition, tapered],
  );

  const onNodesChange = React.useCallback(
    function (changes) {
      setNodes((curr) => {
        const updated = applyNodeChanges(changes, curr);
        const nodes = {};
        changes
          .filter((chg) => chg.type === "position")
          .forEach((chng) => {
            const node = getNode(chng.id);
            nodes[chng.id] = true;
            node.data.edges.forEach((edge) => {
              if (edge.n1 !== chng.id) {
                nodes[edge.n1] = true;
              } else {
                nodes[edge.n2] = true;
              }
            });
          });
        return updated.map((nd) => {
          if (nodes[nd.id]) {
            nd.data = {
              ...nd.data,
            };
          }
          return nd;
        });
      });
    },
    [getNode],
  );

  const selectionChange = React.useCallback(({ nodes }) => {
    if (document.activeElement && document.activeElement.tagName === "INPUT") {
      const input = document.activeElement;
      if (!!input.id) {
        input.blur();
      }
    }
    if (nodes.length > 0) {
      setFoldableNode(nodes[0]);
    } else {
      setFoldableNode(undefined);
    }
  }, []);

  async function exportToPng() {
    const nodesBound = getNodesBounds(getNodes());
    const transform = getViewportForBounds(nodesBound, 600, 850, 0.5, 1);
    const elements = document.querySelectorAll(`.${styles["shadow-pova-sm"]}`);
    elements.forEach((element) => {
      element.classList.replace(
        styles["shadow-pova-sm"],
        styles["drop-shadow-pova-flashing"],
      );
    });
    const dataUri = await toPng(
      document.querySelector(".react-flow__viewport"),
      {
        width: 600,
        height: 850,
        style: {
          width: "600",
          height: "850",
          transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
        },
      },
    );
    elements.forEach((element) => {
      element.classList.replace(
        styles["drop-shadow-pova-flashing"],
        styles["shadow-pova-sm"],
      );
    });
    return dataUri;
  }

  function downloadImage() {
    exportToPng().then((dataUrl) => {
      const a = document.createElement("a");
      a.setAttribute("download", "flashing.png");
      a.setAttribute("href", dataUrl);
      a.click();
    });
  }

  function taperedChange(newTapered) {
    setTapered(newTapered);
    setNodes((curr) =>
      curr.map((node) => ({
        ...node,
        data: { ...node.data, tapered: newTapered },
      })),
    );
    setEdges((curr) =>
      curr.map((edge) => ({
        ...edge,
        data: { ...edge.data, tapered: newTapered },
      })),
    );
  }

  React.useEffect(() => {
    function onEdgeUpdate(id, label, taperedLabel) {
      setEdges((curr) => {
        const newEdges = [];
        curr.forEach((edge) => {
          if (edge.id !== id) {
            newEdges.push(edge);
          } else {
            newEdges.push({
              ...edge,
              label: taperedLabel ? edge.label : label,
              data: { ...edge.data, taperedLabel: label },
            });
          }
        });
        return newEdges;
      });
    }

    function onAngleUpdate(id, prevAngle, angle) {
      const index = parseInt(id) - 1;
      setNodes((curr) => {
        const newNodes = [...curr.slice(0, index)];
        newNodes.push({ ...curr[index], data: { ...curr[index].data } });
        newNodes.push({
          ...curr[index + 1],
          position: getNewCoords(
            curr[index].position,
            curr[index - 1].position,
            curr[index + 1].position,
            prevAngle,
            angle,
          ),
          data: {
            ...curr[index + 1].data,
          },
        });
        if (index + 2 < curr.length) {
          newNodes.push({
            ...curr[index + 2],
            data: { ...curr[index + 2].data },
          });
          newNodes.push(...curr.slice(index + 3));
        }
        return newNodes;
      });
    }

    if (shape) {
      const flow = shape;
      setNodes(
        flow.data.nodes.map((node) => ({
          ...node,
          data: { ...node.data, onAngleUpdate },
        })),
      );
      setEdges(
        flow.data.edges.map((edge) => ({
          ...edge,
          data: { ...edge.data, onEdgeUpdate },
        })),
      );
      setViewport(flow.data.viewport);
      setColourDir(flow.direction);
      setTapered(!!flow.tapered);
      setFoldedNodes(
        flow.data.nodes.reduce((acc, curr) => {
          if (curr.data.folded) {
            acc.push(curr.id);
          }
          return acc;
        }, []),
      );
    } else {
      setNodes([]);
      setEdges([]);
      setColourDir(ColourDir.OUTSIDE);
      setTapered(false);
      setFoldedNodes([]);
    }
  }, [shape, setViewport]);

  async function onDone() {
    if (
      edges.length === 0 ||
      edges.some((edge) => isNaN(parseInt(edge.label)))
    ) {
      alert("Please specify length(mm) of all sides");
      return;
    }
    const totalGirth = edges.reduce((acc, curr) => {
      let value = parseInt(curr.label);
      let tValue = curr.data?.taperedLabel
        ? parseInt(curr.data.taperedLabel)
        : 0;
      if (isNaN(value)) {
        value = 0;
      }
      if (isNaN(tValue)) {
        tValue = 0;
      }
      return acc + Math.max(value, tValue);
    }, 0);
    // flat sheet
    if (edges.length === 1) {
      if (totalGirth < 100 || totalGirth > 800) {
        alert("Total girth of flat sheet should be between 100 and 800");
        return;
      }
    } else if (totalGirth < 100 || totalGirth > 1200) {
      alert("Total girth should be between 100 and 1200");
      return;
    }
    if (onCompleted) {
      if (doneRef.current) {
        doneRef.current.setAttribute("disabled", "true");
      }
      const image = await exportToPng();
      const diagram = {
        direction: colourDir,
        data: toObject(),
        tapered,
      };
      onCompleted(image, {
        diagram,
        bends:
          Math.max(edges.length - 1, 0) +
          nodes.reduce((acc, curr) => acc + (curr.data.folded ? 2 : 0), 0),
        girth: totalGirth,
        edgeCount: edges.length,
      });
      if (doneRef.current) {
        doneRef.current.removeAttribute("disabled");
      }
    }
    setNodes([]);
    setEdges([]);
    setColourDir(ColourDir.OUTSIDE);
    setTapered(false);
    setFoldedNodes([]);
    onClose();
  }

  return (
    <Modal open={open}>
      <div className={styles.modalClose}>
        <Close onClick={onClose} />
      </div>
      <div className={styles.canvas}>
        <ColourContext.Provider value={colourDir}>
          <ReactFlow
            panOnScroll
            selectionOnDrag
            onlyRenderVisibleElements
            proOptions={{
              hideAttribution: true,
            }}
            nodes={nodes}
            edges={edges}
            onPaneClick={addPoint}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            onSelectionChange={selectionChange}
            selectNodesOnDrag={false}
          >
            <Background variant={BackgroundVariant.Lines} color="#EEEEEE" />
            <ColourDirection
              setDirection={setColourDir}
              edgesCount={edges.length}
            />
            <Actions
              onRemove={() => {
                setNodes((curr) => {
                  setFoldableNode(undefined);
                  const lastNode = curr[curr.length - 1];
                  if (lastNode.data.folded) {
                    setFoldedNodes((foldNodesCurr) => {
                      const index = foldNodesCurr.findIndex(
                        (item) => item === lastNode.id,
                      );
                      return [
                        ...foldNodesCurr.slice(0, index),
                        (parseInt(lastNode.id) - 1).toString(),
                        ...foldNodesCurr.slice(index + 1),
                      ];
                    });
                  }
                  const edgeId = `${curr.length - 1}-${curr.length}`;
                  setEdges((curr) => curr.filter((edge) => edge.id !== edgeId));
                  return curr.length === 1
                    ? []
                    : [
                        ...curr.slice(0, curr.length - 2),
                        {
                          ...curr[curr.length - 2],
                          data: {
                            ...curr[curr.length - 2].data,
                            edges: curr[curr.length - 2].data.edges.filter(
                              (ed) => `${ed.n1}-${ed.n2}` !== edgeId,
                            ),
                            folded: lastNode.data.folded,
                            folDir: lastNode.data.folDir,
                          },
                        },
                      ];
                });
              }}
              onClear={() => {
                setNodes([]);
                setEdges([]);
                setFoldableNode(undefined);
                setFoldedNodes([]);
              }}
              onDownload={downloadImage}
              nodesCount={nodes.length}
              setTapered={taperedChange}
              tapered={tapered}
              foldedNodes={foldedNodes}
            />
            <Girth edges={edges} nodes={nodes} />
          </ReactFlow>
        </ColourContext.Provider>
        <NodeActions
          foldedNode={foldableNode}
          nodes={nodes}
          setNodes={setNodes}
          key={foldableNode?.id}
          setFoldedNodes={setFoldedNodes}
        />
      </div>
      <div className={styles.modalActionsContainer}>
        <button className={styles.modalSave} onClick={onDone} ref={doneRef}>
          Done
        </button>
      </div>
    </Modal>
  );
}
