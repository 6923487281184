import SupplierDetails from "../supplier-details";
import { Form, redirect, useNavigation } from "react-router-dom";
import * as React from "react";
import { createPostRequest, uploadImage } from "../../../lib/network";
import styles from "../supplier.module.css";
import BreadCrums from "../../../components/breadcrums";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import { v4 as uuidv4 } from "uuid";
import Branch from "../branch";
import PrimaryButton from "../../../components/primary-button";
import { useLoadScript } from "@react-google-maps/api";
import { formatSupplierData } from "../../helpers";

const libs = ["places", "drawing"];

export async function action({ request }) {
  const formData = await request.formData();
  const formattedData = formatSupplierData(formData);
  const image = formData.get("Supplier_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    formattedData.Supplier_Image = response.imageName;
  }
  const squareImage = formData.get("Supplier_Image");
  if (squareImage instanceof Blob && squareImage.size > 0) {
    const response = await uploadImage(squareImage);
    formattedData.Supplier_Image_Square = response.imageName;
  }
  formattedData.Branches = Object.values(formattedData.Branches);
  formattedData.Branches.forEach((branch) => {
    branch.Branch_Delivery_Charge = parseFloat(branch.Branch_Charge);
    branch.Branch_Delivery_Time = parseFloat(branch.Branch_Time);
    delete branch.Branch_Charge;
    delete branch.Branch_Time;
  });
  const URL = `${process.env.REACT_APP_BASE_URL}/api/supplier/create`;
  await createPostRequest(URL, formattedData);
  return redirect("/user/suppliers");
}

export default function CreateSupplier() {
  const [branchIds, setBranchIds] = React.useState([uuidv4()]);
  const formRef = React.useRef();
  const [polygons, setPolygons] = React.useState(new Map());
  const { isLoaded } = useLoadScript({
    libraries: libs,
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  const autoCompleteService = React.useRef();
  const submitButtonRef = React.useRef();
  const { state } = useNavigation();

  React.useEffect(() => {
    if (isLoaded) {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  const colours = [
    "#F44336",
    "#D81B60",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#00BCD4",
    "#004D40",
    "#4CAF50",
    "#CDDC39",
  ];
  const polygonObjects = Array.from(polygons.entries()).map(
    ([key, value], index) => ({
      id: key,
      path: value,
      colour: colours[index % colours.length],
    }),
  );

  function addBranch(e) {
    e.preventDefault();
    setBranchIds((curr) => [...curr, uuidv4()]);
  }

  function handleSubmit() {
    submitButtonRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const formattedData = formatSupplierData(formData);
    let isValid = true;
    Object.entries(formattedData.Branches).forEach(([branchId, branch]) => {
      const element = document.getElementById(`branch-location-${branchId}`);
      if (!branch["Branch_Service_Region"]) {
        element.setCustomValidity("Please provide the service region");
        isValid = false;
      } else {
        element.setCustomValidity("");
      }
    });
    submitButtonRef.current.setAttribute("disabled", "false");
    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }
    formRef.current.requestSubmit();
  }

  function deleteBranch(id) {
    setBranchIds((curr) => {
      if (curr.length === 1) {
        return curr;
      }
      return curr.filter((item) => item !== id);
    });
  }

  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Suppliers", link: "../suppliers" },
          { label: "Create Supplier", link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Create Supplier</div>
        <CustomLink text="Create Item" icon={<IconAdd />} href="." />
      </div>
      <Form method="post" encType="multipart/form-data" ref={formRef}>
        <SupplierDetails autoComplete={autoCompleteService} />
        <section>
          <div className={styles.branchesHeading}>Branches</div>
          {branchIds.map((id) => (
            <Branch
              key={id}
              id={id}
              autoComplete={autoCompleteService}
              polygons={polygonObjects}
              onDelete={branchIds.length > 1 && deleteBranch}
              setPolygon={(path) =>
                setPolygons((curr) => {
                  const newPolygons = new Map(curr);
                  if (path) {
                    newPolygons.set(id, path);
                  } else {
                    newPolygons.delete(id);
                  }
                  return newPolygons;
                })
              }
            />
          ))}
        </section>
        <section className={styles.actionContainer}>
          <PrimaryButton
            label="Add Branch"
            type="button"
            variant="outlined"
            onClick={addBranch}
          />
          <PrimaryButton
            label="Create Supplier"
            type="button"
            onClick={handleSubmit}
            loading={state === "loading"}
            ref={submitButtonRef}
          />
        </section>
      </Form>
    </div>
  );
}
