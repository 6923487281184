import { useLoaderData, useNavigate } from "react-router-dom";
import { generateGetLoader, getInvoiceStatus } from "../lib/helpers";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import dayjs from "dayjs";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/invoice/admin/getMany?offset=${page}&limit=25`,
    "invoices",
  )();
  return response;
}

export default function Invoices() {
  const { invoices } = useLoaderData();
  const navigate = useNavigate();
  const currentPage = invoices.currentPage;
  const totalPages = invoices.totalPages;

  const invoiceRows =
    invoices?.invoices.map((invoice) => {
      const { text, colour } = getInvoiceStatus(invoice);
      return [
        {
          value: invoice._id,
        },
        { value: invoice.Invoice_Number },
        {
          value:
            invoice.Invoice_Customer.Customer.Customer_Business_Name ||
            invoice.Invoice_Customer.Customer.Customer_Name,
        },
        { value: dayjs(invoice.Invoice_Date).format("DD/MM/YYYY") },
        { value: dayjs(invoice.Invoice_Due_Date).format("DD/MM/YYYY") },
        {
          value: <div style={{ color: colour }}>{text}</div>,
        },
        {
          value: `\$${
            invoice.Invoice_Amount_Outstanding ||
            invoice.Invoice_Total_Price_GST
          }`,
        },
      ];
    }) || [];

  function handleClick(link) {
    navigate(link);
  }

  return (
    <>
      <PageHeader text="Invoices" />
      <Table
        headers={[
          { name: "Number", width: "12.6%" },
          { name: "Customer", width: "23.8%" },
          { name: "Issue Date", width: "16%" },
          { name: "Due Date", width: "17.6%" },
          { name: "Status", width: "16%" },
          { name: "Amount", width: "14%" },
        ]}
        rows={invoiceRows}
        onRowClick={handleClick}
        nextLink={
          currentPage < totalPages ? `?page=${currentPage + 1}` : undefined
        }
        prevLink={currentPage > 1 ? `?page=${currentPage - 1}` : undefined}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </>
  );
}
