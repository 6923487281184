import * as React from "react";
import styles from "./appbar.module.css";
import { ReactComponent as Logo } from "../../assets/icons/Group 272.svg";
// import { ReactComponent as Profile } from "../../assets/icons/Group 275.svg";
// import Notification from "../../dashboard/components/notification";

export default function Appbar({
  name = "Tom York",
  designation = "Inventory Manager",
  count = 2,
}) {
  // const date = new Date();
  return (
    <div className={styles.container}>
      <Logo />
      <div className={styles.profileContainer}>
        {/* <Notification count={count} /> */}
        <div className={styles.leftMarginLarge}>{/* <Profile /> */}</div>
        <div className={styles.leftMargin}>
          {/* <div className={styles.profile}>{name}</div> */}
          <div className={`${styles.profile} ${styles.designation}`}>
            {/* {designation} */}
            <h5>Backoffice v0.9</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
