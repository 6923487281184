import { Form } from "react-router-dom";
import Control from "../../../components/control";
import styles from "./category-details.module.css";
import React from "react";
import PrimaryButton from "../../../components/primary-button";
import ImagePicker from "../../../components/image-picker";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import BreadCrums from "../../../components/breadcrums";

export default function CategoryDetails({
  heading = "Create Category",
  submitting,
  name,
  image,
  showSubmit,
  onFormChange,
}) {
  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Categories", link: ".." },
          { label: heading, link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>{heading}</div>
        <CustomLink
          text="Create Item"
          icon={<IconAdd />}
          href="/user/categories/add"
        />
      </div>
      <Form
        className={styles.form}
        method="post"
        encType="multipart/form-data"
        onChange={onFormChange}
      >
        <div className={styles.flex}>
          <ImagePicker
            name="Category_Image"
            id="category-image"
            image={image}
          />
          <div className={styles.inputContainer}>
            <Control
              label="Category Name"
              id="category-name"
              name="Category_Name"
              required
              initialValue={name}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {(showSubmit === undefined || showSubmit) && (
            <PrimaryButton
              label={showSubmit ? "Save Changes" : heading}
              type="submit"
              loading={submitting}
            />
          )}
        </div>
      </Form>
    </div>
  );
}
