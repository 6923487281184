import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Table from "../components/table";
import useChildRoute from "../hooks/useChildRoute";
import PageHeader from "../components/page-header";
import React from "react";
import { createPostRequest } from "../lib/network";

export default function Categories() {
  const { categories } = useLoaderData();
  const navigate = useNavigate();
  const [categoryRows, setCategoryRows] = React.useState(
    categories?.map((category) => [
      {
        value: `${category._id}?image=${encodeURIComponent(
          category.Category_Image,
        )}&name=${encodeURIComponent(category.Category_Name)}`,
      },
      { value: `#${category.Category_ID}` },
      { value: category.Category_Name },
      { value: category.Category_Metaname },
      {
        value: (
          <img
            src={`${process.env.REACT_APP_IMAGE_PREFIX}/${category.Category_Image}`}
            style={{ borderRadius: "50%", width: 100, height: 100 }}
            alt="preview"
          />
        ),
        align: "left",
      },
    ]) || [],
  );

  const isChildRoute = useChildRoute("/user/categories");

  function handleClick(link) {
    navigate(link);
  }

  function handleDrop(rowIndex, afterIndex) {
    if (rowIndex === afterIndex) {
      return;
    } else if (rowIndex < afterIndex) {
      setCategoryRows((curr) => {
        const prevId = curr[afterIndex][0].value.split("?")[0];
        const categoryId = curr[rowIndex][0].value.split("?")[0];
        const nextId =
          afterIndex + 1 < curr.length
            ? curr[afterIndex + 1][0].value.split("?")[0]
            : undefined;
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/category/rank`,
          { prevId, categoryId, nextId },
        );
        return [
          ...curr.slice(0, rowIndex),
          ...curr.slice(rowIndex + 1, afterIndex + 1),
          curr[rowIndex],
          ...curr.slice(afterIndex + 1),
        ];
      });
    } else {
      setCategoryRows((curr) => {
        const prevId =
          afterIndex >= 1
            ? curr[afterIndex - 1][0].value.split("?")[0]
            : undefined;
        const categoryId = curr[rowIndex][0].value.split("?")[0];
        const nextId = curr[afterIndex][0].value.split("?")[0];
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/category/rank`,
          { prevId, categoryId, nextId },
        );
        return [
          ...curr.slice(0, afterIndex),
          curr[rowIndex],
          ...curr.slice(afterIndex, rowIndex),
          ...curr.slice(rowIndex + 1),
        ];
      });
    }
  }

  return (
    <>
      {!isChildRoute && <PageHeader text="Categories" />}
      <Outlet />
      <Table
        headers={[
          { name: "ID", width: "25%" },
          { name: "Name", width: "25%" },
          { name: "Metaname", width: "25%" },
          { name: "IMG", width: "25%", align: "left" },
        ]}
        rows={categoryRows}
        bodyStyles={
          isChildRoute
            ? { height: "calc(100vh - 795px)" }
            : { height: "calc(100vh - 350px)" }
        }
        onRowClick={handleClick}
        draggableRows
        onDrop={handleDrop}
      />
    </>
  );
}
