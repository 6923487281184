import * as React from "react";
import styles from "./multi-select.module.css";
import { ReactComponent as Arrow } from "../../assets/icons/dropdown.svg";

export default function MutliSelect({
  fullWidth,
  id,
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
  defaultValue,
  addAll,
}) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(
    defaultValue
      ? options.filter((item) => defaultValue.includes(item.value))
      : [],
  );

  function toggle() {
    setOpen((curr) => !curr);
  }

  function handleChange(newOption, e) {
    let newValues;
    const existingIndex = selected.findIndex(
      (item) => item.value === newOption.value,
    );
    if (existingIndex === -1) {
      newValues = [...selected, newOption];
    } else {
      newValues = [
        ...selected.slice(0, existingIndex),
        ...selected.slice(existingIndex + 1),
      ];
    }
    setSelected(newValues);
    if (onChange) {
      if (value) {
        const existingIndex = value.findIndex(
          (item) => item === newOption.value,
        );
        if (existingIndex === -1) {
          newValues = [...value, newOption.value];
        } else {
          newValues = [
            ...value.slice(0, existingIndex),
            ...value.slice(existingIndex + 1),
          ];
        }
      }
      onChange(newValues);
    }
    const input = e.currentTarget;
    input.blur();
  }

  React.useEffect(() => {
    function closeDropdown(e) {
      setOpen(false);
    }
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  let selectedLabel;

  if (value && value.length === 1) {
    selectedLabel = options.find((item) => item.value === value[0])?.label;
  } else if (selected.length === 1) {
    selectedLabel = selected[0].label;
  }

  const computedValue = value || selected;

  return (
    <div className={`${styles.container} ${fullWidth ? styles.fullWidth : ""}`}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <div
        className={`${styles.selectContainer} ${open ? styles.active : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styles.selectButton} type="button" onClick={toggle}>
          <span className={styles.selectedValue}>
            {computedValue.length
              ? computedValue.length === 1
                ? selectedLabel
                : `${computedValue.length} items`
              : placeholder}
          </span>
          <Arrow className={styles.arrow} />
        </button>
        <ul className={styles.selectDropdown}>
          {addAll && (
            <li key="All">
              <label
                onClick={() => {
                  setSelected(options);
                  if (onChange) {
                    onChange(options.map((option) => option.value));
                  }
                }}
              >
                Select All
              </label>
            </li>
          )}
          {options.map((option) => (
            <li key={option.value}>
              <input
                type="checkbox"
                name={name}
                id={option.value}
                value={option.value}
                checked={
                  value
                    ? computedValue.includes(option.value)
                    : computedValue.findIndex(
                        (item) => item.value === option.value,
                      ) !== -1
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(option, e);
                }}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
