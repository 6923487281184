import { redirect } from "react-router-dom";
import { createPostRequest } from "../../../lib/network";
import ColourCategoryDetails from "../colour-category-details";

export async function action({ request }) {
  const formData = await request.formData();
  const name = formData.get("Colour_Category_Name");
  const url = `${process.env.REACT_APP_BASE_URL}/api/colourCategory/create`;
  await createPostRequest(url, { Colour_Category_Name: name });
  return redirect("..");
}

export default function CreateColourCategory() {
  return <ColourCategoryDetails />;
}
