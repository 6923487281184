import { createPostRequest } from "../../../lib/network";

export async function action({ request }) {
  const formData = await request.formData();
  const id = formData.get("id");
  await createPostRequest(
    `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/send/${id}`,
  );
  return null;
}
