import { Panel } from "reactflow";
import styles from "./flashing.module.css";

export default function Girth({ edges, nodes }) {
  return (
    <Panel
      position="bottom-center"
      className={`${styles["!m-0"]} ${styles["!mb-5"]}`}
    >
      <div
        className={`${styles["bg-white"]} ${styles["drop-shadow-pova-flashing"]} ${styles["text-xs"]} ${styles["p-2.5"]} ${styles["text-on-surface-variant"]} ${styles["font-medium"]} ${styles["rounded-xl"]} ${styles["w-200px"]}`}
      >
        <div
          className={`${styles.flex} ${styles["items-center"]} ${styles["justify-between"]}`}
        >
          <div>Total Bends:</div>
          <div className={styles["text-right"]}>
            {Math.max(edges.length - 1, 0) +
              nodes.reduce((acc, curr) => acc + (curr.data.folded ? 2 : 0), 0)}
          </div>
        </div>
        <div
          className={`${styles.flex} ${styles["items-center"]} ${styles["justify-between"]}`}
        >
          <div>Total Girth(mm):</div>
          <div className={styles["text-right"]}>
            {edges.reduce((acc, curr) => {
              let value = parseInt(curr.label);
              let tValue = curr.data?.taperedLabel
                ? parseInt(curr.data.taperedLabel)
                : 0;
              if (isNaN(value)) {
                value = 0;
              }
              if (isNaN(tValue)) {
                tValue = 0;
              }
              return acc + Math.max(value, tValue);
            }, 0)}
          </div>
        </div>
      </div>
    </Panel>
  );
}
