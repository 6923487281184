import * as React from "react";
import styles from "./items.module.css";
import { ReactComponent as Arrow } from "../assets/icons/dropdown.svg";

export default function SortDropdown({
  id,
  label,
  name,
  options,
  value,
  onChange,
}) {
  const [open, setOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);

  function toggle() {
    setOpen((curr) => !curr);
  }

  function handleChange(newOption, e) {
    onChange(newOption.value);
    setOpen(false);
    const input = e.currentTarget;
    input.blur();
  }

  React.useEffect(() => {
    function closeDropdown(e) {
      const evtTarget = e.target;
      if (
        evtTarget !== dropdownRef.current &&
        evtTarget.parentNode !== dropdownRef.current
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className={styles.dropdownContainer}>
      <label htmlFor={id} className={styles.dropdownLabel}>
        {label}
      </label>
      <div className={`${styles.selectContainer} ${open ? styles.active : ""}`}>
        <button
          className={styles.selectButton}
          type="button"
          onClick={toggle}
          ref={dropdownRef}
        >
          <span className={styles.selectedValue}>{value?.label}</span>
          <Arrow className={styles.arrow} />
        </button>
        <ul className={styles.selectDropdown}>
          {options.map((option) => (
            <li key={option.value}>
              <input
                type="radio"
                name={name}
                id={option.value}
                value={option.value}
                defaultChecked={option.value === value.value}
                onClick={(e) => handleChange(option, e)}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
