import { useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import React from "react";
import { createPostRequest } from "../lib/network";

export default function Suppliers() {
  const { suppliers } = useLoaderData();
  const navigate = useNavigate();
  const [supplierRows, setSupplierRows] = React.useState(
    suppliers.map((supplier) => [
      {
        value: `${supplier._id}`,
      },
      { value: supplier.Supplier_Name },
      { value: supplier.Supplier_Email },
      { value: supplier.Supplier_Phone },
      {
        value: (
          <div
            title={supplier.Supplier_Notes}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {supplier.Supplier_Notes}
          </div>
        ),
      },
    ]),
  );

  function handleRowClick(id) {
    navigate(id);
  }

  function handleDrop(rowIndex, afterIndex) {
    if (rowIndex === afterIndex) {
      return;
    } else if (rowIndex < afterIndex) {
      setSupplierRows((curr) => {
        const prevId = curr[afterIndex][0].value.split("?")[0];
        const supplierId = curr[rowIndex][0].value.split("?")[0];
        const nextId =
          afterIndex + 1 < curr.length
            ? curr[afterIndex + 1][0].value.split("?")[0]
            : undefined;
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/supplier/rank`,
          { prevId, supplierId, nextId },
        );
        return [
          ...curr.slice(0, rowIndex),
          ...curr.slice(rowIndex + 1, afterIndex + 1),
          curr[rowIndex],
          ...curr.slice(afterIndex + 1),
        ];
      });
    } else {
      setSupplierRows((curr) => {
        const prevId =
          afterIndex >= 1
            ? curr[afterIndex - 1][0].value.split("?")[0]
            : undefined;
        const supplierId = curr[rowIndex][0].value.split("?")[0];
        const nextId = curr[afterIndex][0].value.split("?")[0];
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/supplier/rank`,
          { prevId, supplierId, nextId },
        );
        return [
          ...curr.slice(0, afterIndex),
          curr[rowIndex],
          ...curr.slice(afterIndex, rowIndex),
          ...curr.slice(rowIndex + 1),
        ];
      });
    }
  }

  return (
    <>
      <PageHeader text="Suppliers" />
      <Table
        headers={[
          { name: "Name", width: "17%" },
          { name: "Email", width: "23%" },
          { name: "Phone", width: "23%" },
          { name: "Note", width: "37%" },
        ]}
        rows={supplierRows}
        onRowClick={handleRowClick}
        bodyStyles={{ height: "calc(100vh - 350px" }}
        draggableRows
        onDrop={handleDrop}
      />
    </>
  );
}
