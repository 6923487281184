import React from "react";
import {
  Form,
  redirect,
  useFetcher,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { generateGetLoader, getInvoiceStatus } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";
import Control from "../../../components/control";
import styles from "./order-details.module.css";
import Table from "../../../components/table";
import Dropdown from "../../../components/dropdown";
import dayjs from "dayjs";
import PrimaryButton from "../../../components/primary-button";
import GoogleAutocomplete from "../../../components/google-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { createGetRequest, createPostRequest } from "../../../lib/network";
import Modal from "../../../components/modal";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import DatePicker from "./date-picker";
import Timeline from "./timeline";
import Loader from "../../../components/loader";
import { OrderEdit, FlashingEdit } from "../../../components/edit";

const libs = ["places"];

export async function loader({ params }) {
  const response = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/order/admin/getOne/${params.id}`,
      `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany?limit=50&page=1`,
    ],
    ["order", "suppliers", "colourCategories"],
  )();
  return response;
}

export async function action() {
  return redirect("../orders");
}

export default function OrderDetails() {
  const { order, suppliers, colourCategories } = useLoaderData();
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const { isLoaded } = useLoadScript({
    libraries: libs,
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  const autoCompleteService = React.useRef();
  const { state } = useNavigation();
  const formRef = React.useRef();
  const submitRef = React.useRef();

  const [updated, setUpdated] = React.useState(false);
  const [editedRow, setEditedRow] = React.useState("");
  const [flashingEditedRow, setFlashingEditedRow] = React.useState("");
  const [orderDetails, setOrderDetails] = React.useState({
    items: order.Order_Items.map((item) => ({
      supplier: item.Branch_Item_Object.Branch_Item.Item_Supplier.Supplier_Name,
      branch: item.Branch_Item_Object.Branch_ID.Branch_Name,
      name: `${item.Branch_Item_Object.Branch_Item.Item_Name} - ${item.Variant_Object.Variant_Size}`,
      cutting: item.Cutting_List,
      price: `$${item.Variant_Object.Variant_Selling_Price.toFixed(2)}`,
      colour: item.Variant_Object.Variant_Colour?.Colour_Name || "",
      totalPrice: `$${item.Total_Item_Price.toFixed(2)}`,
      itemID: item.Branch_Item_Object.Branch_Item?.Item_ID,
      variantID: item.Variant_Object.Variant_ID,
      unit: item.Variant_Object.Variant_Size_Type_Units,
      quantity: item.Quantity,
      raw: item,
      variantSize: item.Variant_Object.Variant_Size,
    })),
    flashings: order.Order_Flashing_Items.map((flashing) => {
      const firstEntry = flashing.Cutting_List[0];
      const unitPrice =
        ((firstEntry.Total_Size_Price / firstEntry.Quantity) * 1000) /
        firstEntry.Size;
      return {
        shape: `${process.env.REACT_APP_IMAGE_PREFIX}/${flashing.Image}`,
        girth: flashing.Total_Girth,
        bends: flashing.Total_Bends,
        cutting: flashing.Cutting_List,
        colour: flashing.Flashing_Variant.Colour.Colour_Name,
        price: unitPrice.toFixed(2),
        total: flashing.Cutting_List.reduce(
          (acc, curr) => acc + curr.Total_Size_Price,
          0,
        ),
        diagram: flashing.Diagram,
        variantID: flashing.Flashing_Variant.Colour._id,
        colourID: flashing.Flashing_Variant.Colour._id,
        prices: [],
      };
    }),
    location: order.Order_Location_Coords,
    date: dayjs(order.Order_Delivery_Date).format("YYYY-MM-DD"),
  });
  const [showModal, setShowModal] = React.useState(false);
  const fetcher = useFetcher();

  const supplierProducts = order.Order_Items.reduce((acc, curr) => {
    if (!acc[curr.Branch_Item_Object.Branch_Item.Item_Supplier._id]) {
      acc[curr.Branch_Item_Object.Branch_Item.Item_Supplier._id] = {
        name: curr.Branch_Item_Object.Branch_Item.Item_Supplier.Supplier_Name,
        count: 0,
      };
    }
    acc[curr.Branch_Item_Object.Branch_Item.Item_Supplier._id].count++;
    return acc;
  }, {});

  let flashingPo;
  const poSuppliers = order.Purchase_Orders.reduce((acc, curr) => {
    if (
      curr.PurchaseOrder_Status !== "Void" &&
      curr.PurchaseOrder_Items.length > 0
    ) {
      acc[curr.PurchaseOrder_Supplier._id] = {
        no: `PO #${curr.PurchaseOrder_Number}`,
        id: curr._id,
      };
    } else if (curr.PurchaseOrder_Flashing_Items.length > 0) {
      flashingPo = {
        no: `PO #${curr.PurchaseOrder_Number}`,
        id: curr._id,
      };
    }
    return acc;
  }, {});
  let subtotal = 0;
  const orderRows = orderDetails.items.map((item, index) => {
    subtotal += parseFloat(item.totalPrice.slice(1));
    return [
      {
        value: index.toString(),
      },
      {
        value: item.supplier,
        vAlign: "top",
      },
      {
        value: item.branch,
        vAlign: "top",
      },
      {
        value: item.name,
        vAlign: "top",
      },
      {
        value:
          !item.cutting || item.cutting.length === 0 ? (
            ""
          ) : (
            <div className={styles.list}>
              {item.cutting.map((record, index) => (
                <div key={index.toString()}>
                  {record.Size} {item.unit}
                </div>
              ))}
            </div>
          ),
      },
      {
        value: item.colour,
        vAlign: "top",
      },
      {
        value:
          !item.cutting || item.cutting.length === 0 ? (
            item.quantity
          ) : (
            <div className={styles.list}>
              {item.cutting.map((record, index) => (
                <div key={index.toString()}>{record.Quantity}</div>
              ))}
            </div>
          ),
        align: "center",
      },
      {
        value: item.price,
        vAlign: "top",
        align: "center",
      },
      {
        value: item.totalPrice,
        vAlign: "top",
        align: "center",
      },
    ];
  });
  const flashingRows = orderDetails.flashings.map((flashing, index) => {
    subtotal += flashing.total;
    return [
      {
        value: index.toString(),
      },
      {
        value: (
          <img src={flashing.shape} alt="Shape" width={150} height={196} />
        ),
        vAlign: "top",
      },
      {
        vAlign: "top",
        value: `${flashing.girth}mm`,
      },
      {
        vAlign: "top",
        value: `${flashing.bends}`,
      },
      {
        value: (
          <div className={styles.list}>
            {flashing.cutting.map((record, index) => (
              <div key={index.toString()}>{record.Size}MM</div>
            ))}
          </div>
        ),
      },
      {
        vAlign: "top",
        value: flashing.colour,
      },
      {
        value: (
          <div className={styles.list}>
            {flashing.cutting.map((record) => (
              <div>{record.Quantity}</div>
            ))}
          </div>
        ),
      },
      {
        vAlign: "top",
        value: `$${flashing.price}`,
      },
      {
        vAlign: "top",
        value: `$${flashing.total.toFixed(2)}`,
        align: "center",
      },
    ];
  });

  const { text, colour } = getInvoiceStatus(order.Order_Invoice);
  let inputClassName;
  // eslint-disable-next-line default-case
  switch (colour) {
    case "var(--success)":
      inputClassName = styles.success;
      break;
    case "var(--error)":
      inputClassName = styles.error;
      break;
    case "var(--info)":
      inputClassName = styles.info;
  }

  function onRowClick(id) {
    setEditedRow(id);
  }

  async function addVersion() {
    submitRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const splits = orderDetails.date.split("-");
    let format = "YYYY-MM-DD";
    if (splits[0].length !== 4) {
      format = "DD-MM-YYYY";
    }
    const body = {
      orderID: order._id,
      orderUpdateObject: {
        location: orderDetails.location,
        address: formData.get("Delivery_Address"),
        checkoutType: formData.get("Order_Delivery_Type"),
        items: orderDetails.items.map((item) => ({
          item: item.itemID,
          variant: item.variantID,
          qty:
            item.quantity && !isNaN(item.quantity) ? item.quantity : undefined,
          cuttingList:
            item.cutting && item.cutting.length > 0
              ? item.cutting.map((record) => ({
                  size: record.Size,
                  qty: record.Quantity,
                }))
              : undefined,
        })),
        flashings: orderDetails.flashings.map((flashing) => {
          const parsed = JSON.parse(flashing.diagram);
          let image;
          if (flashing.shape.charAt(0).toLowerCase() === "h") {
            const segment = flashing.shape.split("/");
            image = segment[segment.length - 1];
          } else {
            image = flashing.shape;
          }
          return {
            flashingVariant: flashing.variantID,
            cuttingList: flashing.cutting.map((record) => ({
              size: record.Size,
              qty: record.Quantity,
            })),
            bends: flashing.bends,
            girth: flashing.girth,
            image,
            tapered: parsed.tapered || false,
            diagram: flashing.diagram,
          };
        }),
        deliveryOrPickupDate: dayjs(orderDetails.date, format).toISOString(),
      },
    };
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/order/admin/addVersion`,
      body,
    );
    submitRef.current.removeAttribute("disabled");
    setShowModal(false);
    formRef.current.requestSubmit();
  }

  React.useEffect(() => {
    if (isLoaded) {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  React.useEffect(() => {
    async function getFlashingData() {
      const response = await createGetRequest(
        `${process.env.REACT_APP_BASE_URL}/api/flashingBranchItems/user/getMany?location=${orderDetails.location}`,
      );
      if (!response.data) {
        setError(true);
      } else {
        setData(response.data);
        const data = response.data;
        const colourIdToVariant = data[0].Flashing_Variants.reduce(
          (acc, curr) => {
            acc[curr.Colour._id] = {
              id: curr._id,
              prices: curr.Selling_Price_Array.map((priceArray) =>
                priceArray.map((record) => ({
                  ...record,
                  Price: record.Selling_Price,
                  Tapered_Price: record.Tapered_Selling_Price,
                })),
              ),
            };
            return acc;
          },
          {},
        );
        setOrderDetails((curr) => ({
          ...curr,
          flashings: curr.flashings.map((flashing, index) => {
            return {
              ...flashing,
              variantID: colourIdToVariant[flashing.colourID].id,
              prices: colourIdToVariant[flashing.colourID].prices,
            };
          }),
        }));
      }
      setLoading(false);
    }
    getFlashingData();
  }, [orderDetails.location]);

  return (
    <div>
      <BreadCrums
        segments={[
          { label: "Orders", link: "../orders" },
          { label: `Order #${order.Order_Number}`, link: "." },
        ]}
      />
      {error ? (
        <div className={styles.loadingContainer}>Something went wrong</div>
      ) : loading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <Form ref={formRef} method="POST">
          {updated && (
            <section className={styles.buttonsContainer}>
              <div>
                Order total might be incorrect, once you are done with changes
                click "Save Changes" to see the final amount.
              </div>
              <PrimaryButton
                label="Save Changes"
                type="button"
                onClick={() => setShowModal(true)}
              />
            </section>
          )}
          <section className={styles.container}>
            <div className={styles.row}>
              <div className={styles.column}>
                <Control
                  label="Customer Name"
                  id="customer-name"
                  name="Customer_Name"
                  editable={false}
                  fullWidth
                  initialValue={
                    order.Order_Customer.Customer.Customer_Business_Name ||
                    order.Order_Customer.Customer.Customer_Name
                  }
                />
                <Control
                  label="Order Date"
                  id="order-date"
                  name="Order_Date"
                  editable={false}
                  fullWidth
                  initialValue={dayjs(order.Order_Date).format("DD/MM/YYYY")}
                />
              </div>
              <div className={styles.column}>
                <Dropdown
                  name="Order_Delivery_Type"
                  id="order-delivery-type"
                  label="Delivery"
                  required
                  fullWidth
                  options={[
                    { value: "Delivery", label: "To Site" },
                    { value: "Pickup", label: "Pick Up" },
                  ]}
                  value={order.Order_Delivery_Type}
                  onChange={() => setUpdated(true)}
                />
                <Control
                  label="Status"
                  id="status"
                  name="Status"
                  editable={false}
                  fullWidth
                  initialValue={text}
                  inputClassName={inputClassName}
                />
              </div>
              <div className={styles.column}>
                <GoogleAutocomplete
                  label="Address"
                  id="delivery-address"
                  name="Delivery_Address"
                  fullWidth
                  initialValue={order.Order_Delivery_Address}
                  smallFont
                  callback={(loc) => {
                    setOrderDetails((curr) => ({ ...curr, location: loc }));
                    setUpdated(true);
                  }}
                  autocompleteService={autoCompleteService}
                />
                <div className={styles.summary}>
                  <div
                    className={`${styles.summaryHeader} ${styles.suppliersHeader}`}
                  >
                    Order Suppliers
                  </div>
                  <div className={styles.summaryText}>
                    {Object.entries(supplierProducts).map(
                      ([id, { name, count }]) => (
                        <div className={styles.supplierOrderRow} key={id}>
                          <div>
                            {name}({count} items)
                          </div>
                          {poSuppliers[id] ? (
                            <a
                              className={`${styles.poButton} ${styles.poCreatedButton}`}
                              href={`../purchase-orders/${poSuppliers[id].id}`}
                            >
                              {poSuppliers[id].no}
                            </a>
                          ) : (
                            <button
                              className={styles.poButton}
                              disabled={fetcher.state === "submitting"}
                              onClick={() => {
                                fetcher.submit(
                                  {
                                    orderID: order._id,
                                    supplierID: id,
                                    POType: "items",
                                  },
                                  {
                                    method: "POST",
                                    action: "../purchase-orders/add",
                                  },
                                );
                              }}
                            >
                              Create PO
                            </button>
                          )}
                        </div>
                      ),
                    )}
                    {flashingRows.length > 0 && (
                      <div className={styles.supplierOrderRow}>
                        <div>Flashings ({flashingRows.length} items)</div>
                        {flashingPo ? (
                          <a
                            className={`${styles.poButton} ${styles.poCreatedButton}`}
                            href={`../purchase-orders/${flashingPo.id}`}
                          >
                            {flashingPo.no}
                          </a>
                        ) : (
                          <button
                            className={styles.poButton}
                            disabled={fetcher.state === "submitting"}
                            onClick={() => {
                              fetcher.submit(
                                {
                                  orderID: order._id,
                                  supplierID:
                                    order.Order_Flashing_Items[0]
                                      .Flashing_Variant.Branch_ID.Supplier,
                                  POType: "flashings",
                                },
                                {
                                  method: "POST",
                                  action: "../purchase-orders/add",
                                },
                              );
                            }}
                          >
                            Create PO
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={styles.tableContainer}>
            {flashingRows.length > 0 && (
              <Table
                bodyStyles={{
                  height: "auto",
                }}
                headers={[
                  { name: "Shape", width: "19.3%" },
                  { name: "Girth", width: "12%" },
                  { name: "Bends", width: "12" },
                  { name: "Size", width: "12.4%" },
                  { name: "Colour", width: "14.7%" },
                  { name: "QTY", width: "8.1%" },
                  { name: "PPM", width: "6.5%" },
                  { name: "Item Total", width: "15%" },
                ]}
                rows={flashingRows}
                onRowClick={(id) => setFlashingEditedRow(id)}
                content={
                  <FlashingEdit
                    editedRow={flashingEditedRow}
                    key={flashingEditedRow}
                    flashings={orderDetails.flashings}
                    data={data}
                    onDiscard={() => {
                      setFlashingEditedRow("");
                    }}
                    onAdd={(data) => {
                      setOrderDetails((curr) => ({
                        ...curr,
                        flashings: [...curr.flashings, data],
                      }));
                      setUpdated(true);
                    }}
                    onEdit={(data, index) => {
                      setOrderDetails((curr) => ({
                        ...curr,
                        flashings: [
                          ...curr.flashings.slice(0, index),
                          data,
                          ...curr.flashings.slice(index + 1),
                        ],
                      }));
                      setUpdated(true);
                    }}
                    onDelete={(index) => {
                      setOrderDetails((curr) => ({
                        ...curr,
                        flashings: [
                          ...curr.flashings.slice(0, index),
                          ...curr.flashings.slice(index + 1),
                        ],
                      }));
                      setUpdated(true);
                    }}
                  />
                }
              />
            )}
            {orderRows.length > 0 && (
              <Table
                headers={[
                  { name: "Supplier", width: "12.3%" },
                  { name: "Branch", width: "12%" },
                  { name: "Item", width: "19%" },
                  { name: "Size", width: "12.4%" },
                  { name: "Colour", width: "14.7%" },
                  { name: "QTY", width: "8.1%" },
                  { name: "PPU", width: "6.5%" },
                  { name: "Item Total", width: "15%" },
                ]}
                rows={orderRows}
                onRowClick={onRowClick}
                bodyStyles={{
                  height: "auto",
                }}
                content={
                  <OrderEdit
                    items={orderDetails.items}
                    editedRow={editedRow}
                    suppliers={suppliers}
                    key={editedRow}
                    location={orderDetails.location}
                    onDiscard={() => {
                      setEditedRow("");
                    }}
                    onAdd={(data) => {
                      setOrderDetails((curr) => ({
                        ...curr,
                        items: [...curr.items, data],
                      }));
                      setUpdated(true);
                    }}
                    onEdit={(data, index) => {
                      setOrderDetails((curr) => ({
                        ...curr,
                        items: [
                          ...curr.items.slice(0, index),
                          data,
                          ...curr.items.slice(index + 1),
                        ],
                      }));
                      setUpdated(true);
                    }}
                    onDelete={(index) => {
                      setOrderDetails((curr) => ({
                        ...curr,
                        items: [
                          ...curr.items.slice(0, index),
                          ...curr.items.slice(index + 1),
                        ],
                      }));
                      setUpdated(true);
                    }}
                    colourCategories={colourCategories.colourCategories}
                  />
                }
              />
            )}
            <div className={styles.bottomSection}>
              <Timeline events={order.Order_Events} />
              <div className={styles.pricingContainer}>
                <div>
                  <div>SUBTOTAL</div>
                  <div className={styles.price}>${subtotal.toFixed(2)}</div>
                </div>
                <div>
                  <div>SHIPPING</div>
                  <div className={styles.price}>
                    ${order.Order_Delivery_Price || 0}
                  </div>
                </div>
                <div>
                  <div>TOTAL</div>
                  <div className={styles.price}>
                    ${order.Order_Total_Price_GST}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal open={showModal}>
            <div className={styles.modalContainer}>
              <div className={styles.modalTopRow}>
                <div className={styles.modalHeading}>Update Order</div>
                <div>
                  <Close
                    onClick={() => setShowModal(false)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div className={styles.dateContainer}>
                <DatePicker
                  id="update-order-date"
                  label="Delivery Date"
                  value={orderDetails.date}
                  onChange={(e) =>
                    setOrderDetails((curr) => ({
                      ...curr,
                      date: e.target.value,
                    }))
                  }
                  min={orderDetails.date}
                  max={dayjs().add(1, "month").format("YYYY-MM-DD")}
                />
              </div>
              <div className={styles.modalActionContainer}>
                <PrimaryButton
                  label="Update Order"
                  type="button"
                  loading={state === "loading" || !orderDetails.date}
                  ref={submitRef}
                  onClick={addVersion}
                />
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </div>
  );
}
