import styles from "./loader.module.css";

export default function Loader({ light }) {
  return (
    <div className={`${styles.spinner} ${light ? styles.light : ""}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
