import { redirect, useNavigation } from "react-router-dom";
import CategoryDetails from "../category-details";
import { createPostRequest, uploadImage } from "../../../lib/network";

export async function action({ request }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  const image = formData.get("Category_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    data.Category_Image = response.imageName;
  } else {
    delete data.Category_Image;
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/category/create`;
  await createPostRequest(url, data);
  return redirect("..");
}

export default function CreateCategory() {
  const { state } = useNavigation();
  return <CategoryDetails submitting={state === "submitting"} />;
}
