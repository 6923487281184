import styles from "./form-control-container.module.css";

export default function FormControlContainer({
  fullWidth,
  id,
  label,
  children,
}) {
  return (
    <div className={`${styles.container} ${fullWidth ? styles.fullWidth : ""}`}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <div className={styles.inputWrapper}>{children}</div>
    </div>
  );
}
