import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Table from "../components/table";
import Palette from "../components/palette";
import useChildRoute from "../hooks/useChildRoute";
import PageHeader from "../components/page-header";

export default function Colors() {
  const { colors } = useLoaderData();
  const navigate = useNavigate();
  const colorRows =
    colors?.map((color) => [
      {
        value: color.Secondary_Colour_Code
          ? `${color._id}?code=${encodeURIComponent(
              color.Colour_Code,
            )}&name=${encodeURIComponent(
              color.Colour_Name,
            )}&category=${encodeURIComponent(
              color.Colour_Category?._id,
            )}&sCode=${encodeURIComponent(color.Secondary_Colour_Code)}`
          : `${color._id}?code=${encodeURIComponent(
              color.Colour_Code,
            )}&name=${encodeURIComponent(
              color.Colour_Name,
            )}&category=${encodeURIComponent(color.Colour_Category?._id)}`,
      },
      { value: `#${color.Colour_ID}` },
      { value: color.Colour_Name },
      { value: color.Colour_Category?.Colour_Category_Name },
      {
        value: (
          <div style={{ display: "flex", columnGap: 8 }}>
            <Palette
              code={color.Colour_Code}
              width={color.Secondary_Colour_Code ? 44 : 87}
              height={37}
              borderRadius={color.Secondary_Colour_Code ? 10 : 16}
            />
            {color.Secondary_Colour_Code ? (
              <Palette
                code={color.Secondary_Colour_Code}
                width={43}
                height={37}
                borderRadius={10}
              />
            ) : null}
          </div>
        ),
      },
    ]) || [];
  const isChildRoute = useChildRoute("/user/colours");

  function handleRowClick(id) {
    navigate(id);
  }

  return (
    <>
      {!isChildRoute && <PageHeader text="Colours" />}
      <Outlet />
      <Table
        headers={[
          { name: "ID", width: "23%" },
          { name: "Name", width: "23%" },
          { name: "Category", width: "23%" },
          { name: "Image", width: "31%" },
        ]}
        rows={colorRows}
        bodyStyles={
          isChildRoute
            ? { height: "calc(100vh - 760px)" }
            : { height: "calc(100vh - 350px)" }
        }
        onRowClick={handleRowClick}
      />
    </>
  );
}
