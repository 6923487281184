import { useReactFlow, useStoreApi } from "reactflow";
import React from "react";
import { ReactComponent as Close } from "../../assets/icons/flashings-close.svg";
import { ReactComponent as Left } from "../../assets/icons/left-turn.svg";
import { ReactComponent as Right } from "../../assets/icons/right-turn.svg";
import styles from "./flashing.module.css";
import { FoldDir } from "./utils";

export default function NodeActions({
  foldedNode,
  nodes,
  setNodes,
  setFoldedNodes,
}) {
  const [foldDetails, setFoldDetails] = React.useState({
    folded: foldedNode?.data?.folded || false,
    dir: foldedNode?.data?.folDir || FoldDir.UP,
  });
  const store = useStoreApi();
  const { flowToScreenPosition } = useReactFlow();
  const position =
    foldedNode &&
    (foldedNode.id === "1" || foldedNode.id === `${nodes.length}`) &&
    nodes.length > 1
      ? foldedNode.position
      : null;

  const screenPosition = position ? flowToScreenPosition(position) : null;

  function foldNode(dir) {
    setNodes((curr) =>
      curr.map((node) =>
        node.id === foldedNode.id
          ? {
              ...node,
              data: { ...node.data, folded: true, folDir: dir },
            }
          : node,
      ),
    );
    setFoldDetails({ folded: true, dir });
    setFoldedNodes((curr) => [
      ...curr.filter((node) => node !== foldedNode.id),
      foldedNode.id,
    ]);
  }

  function unfold() {
    setNodes((curr) =>
      curr.map((node) =>
        node.id === foldedNode.id
          ? {
              ...node,
              data: { ...node.data, folded: false, folDir: undefined },
            }
          : node,
      ),
    );
    setFoldDetails({ folded: false, dir: FoldDir.UP });
    setFoldedNodes((curr) => curr.filter((node) => node !== foldedNode.id));
    store.getState().resetSelectedElements();
  }

  return (
    <div
      className={`${styles["bg-surface"]} ${
        position ? styles["inline-block"] : styles["hidden"]
      } ${styles.actionContainer}`}
      style={
        screenPosition
          ? { left: screenPosition.x - 20, top: screenPosition.y - 48 }
          : undefined
      }
    >
      {foldDetails.folded ? (
        <div className={styles.actionsWrapper}>
          {foldDetails.dir === FoldDir.UP ? (
            <Left onClick={() => foldNode(FoldDir.DOWN)} />
          ) : (
            <Right onClick={() => foldNode(FoldDir.UP)} />
          )}
          <Close onClick={unfold} />
        </div>
      ) : (
        <Right onClick={() => foldNode(FoldDir.UP)} />
      )}
    </div>
  );
}
