import * as React from "react";
import styles from "./dropdown.module.css";
import { ReactComponent as Arrow } from "../../assets/icons/dropdown.svg";
import _ from "lodash";

export default function DisplayDropdown({
  fullWidth,
  id,
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
  required,
}) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(
    value
      ? _.flatten(Object.values(options)).find((item) => item.value === value)
      : undefined,
  );
  const dropdownRef = React.useRef(null);

  function toggle() {
    setOpen((curr) => !curr);
  }

  function handleChange(newOption, e) {
    if (onChange) {
      onChange(newOption.value);
    }
    setSelected(newOption);
    setOpen(false);
    const input = e.currentTarget;
    input.blur();
  }

  React.useEffect(() => {
    function closeDropdown(e) {
      const evtTarget = e.target;
      if (
        evtTarget !== dropdownRef.current &&
        evtTarget.parentNode !== dropdownRef.current
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className={`${styles.container} ${fullWidth ? styles.fullWidth : ""}`}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <div className={`${styles.selectContainer} ${open ? styles.active : ""}`}>
        <button
          className={styles.selectButton}
          type="button"
          onClick={toggle}
          ref={dropdownRef}
        >
          <span className={styles.selectedValue}>
            {selected?.label || placeholder}
          </span>
          <Arrow className={styles.arrow} />
        </button>
        <ul className={styles.selectDropdown}>
          {Object.entries(options).map(([key, options]) => (
            <React.Fragment key={key}>
              <div>{key}</div>
              {options.map((option) => (
                <li key={option.value}>
                  <input
                    type="radio"
                    name={name}
                    id={option.value}
                    required={required}
                    value={option.value}
                    defaultChecked={option.value === value}
                    onClick={(e) => handleChange(option, e)}
                  />
                  <label htmlFor={option.value}>{option.label}</label>
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}
