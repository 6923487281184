import React from "react";
import styles from "./slider.module.css";

export default function Slider({ name, defaultChecked, value }) {
  const [isChecked, setIsChecked] = React.useState(defaultChecked || false);

  function handleChange(e) {
    const value = e.target.checked;
    setIsChecked(value);
  }

  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={handleChange}
        value={value || "Active"}
      />
      <span className={styles.slider}>{isChecked ? "Active" : "Inactive"}</span>
    </label>
  );
}
