import styles from "./page-header.module.css";
import CustomLink from "../custom-link";
import { ReactComponent as IconAdd } from "../../assets/icons/Add.svg";

export default function PageHeader({ text, noAdd, extraSection }) {
  return (
    <div className={styles.headerContainer}>
      <div className="page-heading">{text}</div>
      {extraSection && (
        <div className={styles.extraSection}>{extraSection}</div>
      )}
      {!noAdd && (
        <CustomLink text="Create Item" icon={<IconAdd />} href="add" />
      )}
    </div>
  );
}
