import { Panel, useStoreApi } from "reactflow";
import styles from "./flashing.module.css";
import React from "react";

export default function Actions({
  onClear,
  onRemove,
  onDownload,
  nodesCount,
  setTapered,
  tapered,
  foldedNodes,
}) {
  return (
    <Panel
      position="top-center"
      className={`${styles["!m-0"]} ${styles["!mt-5"]}`}
    >
      <div
        className={`${styles["bg-surface"]} ${styles.flex} ${
          styles["items-center"]
        } ${styles["justify-between"]} ${styles["cursor-pointer"]} ${
          styles["rounded-3xl"]
        } ${styles["text-2xl"]} ${styles["py-2"]} ${styles["px-4"]} ${
          nodesCount === 0 ? styles["text-disabled"] : styles["text-primary"]
        } ${styles["gap-x-4"]} ${styles["relative"]}`}
      >
        <CrushFold foldedNodes={foldedNodes} nodesCount={nodesCount} />

        <div title="Remove last point" onClick={onRemove} role="button">
          <Undo />
        </div>
        <div title="Download" onClick={onDownload} role="button">
          <Download />
        </div>
        <div title="Clear" onClick={onClear} role="button">
          <Delete />
        </div>
      </div>
      <div
        className={`${styles["bg-surface"]} ${styles["mt-4"]} ${styles["rounded-3xl"]} ${styles["text-primary"]} ${styles["flex"]} ${styles["items-center"]} ${styles["p-1"]} ${styles["text-xs"]}`}
      >
        <div
          className={`${styles["cursor-pointer"]} ${styles["p-2"]} ${
            styles["grow"]
          } ${
            tapered
              ? ""
              : `${styles["bg-white"]} ${styles["rounded-3xl"]} ${styles["border"]} ${styles["border-primary"]}`
          }`}
          onClick={() => setTapered(false)}
        >
          Regular
        </div>
        <div
          className={`${styles["cursor-pointer"]} ${styles["p-2"]} ${
            styles["grow"]
          } ${
            !tapered
              ? ""
              : `${styles["bg-white"]} ${styles["rounded-3xl"]} ${styles["border"]} ${styles["border-primary"]}`
          }`}
          onClick={() => setTapered(true)}
        >
          Tapered
        </div>
      </div>
    </Panel>
  );
}

function Delete() {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.12 5H1.62V19.5H15.12V5Z" fill="currentColor" />
      <path d="M16.75 1H0V4H16.75V1Z" fill="currentColor" />
      <path d="M9.88 0H6.88V3H9.88V0Z" fill="currentColor" />
    </svg>
  );
}

function Download() {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95215 15.95L15.9521 7.94995L18.0523 10.0501L10.0523 18.0501L7.95215 15.95Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95215 18.05L1.95215 10.05L4.05226 7.94994L12.0523 15.9499L9.95215 18.05Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00428581 19.0699L21.0043 19.0999L21 22.0999L0 22.0699L0.00428581 19.0699Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5022 15L8.5022 0L11.5022 -1.31134e-07L11.5022 15L8.5022 15Z"
        fill="currentColor"
      />
    </svg>
  );
}

function Undo() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84797 4.74765C4.65493 2.87522 7.18833 1.70215 9.98256 1.70215C15.5249 1.70215 19.9852 6.2546 19.9852 11.8392C19.9852 17.4218 15.537 21.9762 9.99262 21.9762C4.44725 21.9762 0 17.4105 0 11.8392H2.67C2.67 15.9798 5.96533 19.3062 9.99262 19.3062C14.0209 19.3062 17.3152 15.989 17.3152 11.8392C17.3152 7.69145 14.0129 4.37215 9.98256 4.37215C7.95025 4.37215 6.09954 5.22324 4.76924 6.60174L2.84797 4.74765Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.4632 8.62993L1.2832 0.0630193L4.28254 0L4.46254 8.56691L1.4632 8.62993Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.56323 5.77459L9.78847 5.59058L9.8549 8.55983L1.62966 8.74385L1.56323 5.77459Z"
        fill="currentColor"
      />
    </svg>
  );
}

function CrushFold({ foldedNodes, nodesCount }) {
  const [foldState, setFoldState] = React.useState("focusFirst");
  const state = useStoreApi().getState();

  let text = "";
  switch (foldState) {
    case "focusFirst":
      text = "Add Crush Fold";
      break;
    case "focusLast":
      text = "Fold Other End";
      break;
    default:
      text = "No Fold";
  }

  function mainClick() {
    if (foldedNodes.length > 1) {
      state.resetSelectedElements();
      state.addSelectedNodes([foldedNodes[1]]);
    } else if (foldedNodes.length > 0) {
      switch (foldState) {
        case "focusFirst":
          state.resetSelectedElements();
          state.addSelectedNodes([
            foldedNodes[0] === "1" ? `${nodesCount}` : "1",
          ]);
          setFoldState("noFold");
          break;
        default:
          state.resetSelectedElements();
          setFoldState("focusFirst");
      }
    } else {
      switch (foldState) {
        case "focusFirst":
          state.resetSelectedElements();
          state.addSelectedNodes(["1"]);
          setFoldState("focusLast");
          break;
        case "focusLast":
          state.resetSelectedElements();
          state.addSelectedNodes([`${nodesCount}`]);
          setFoldState("noFold");
          break;
        default:
          state.resetSelectedElements();
          setFoldState("focusFirst");
      }
    }
  }

  React.useEffect(() => {
    if (foldedNodes.length <= 1) {
      setFoldState("focusFirst");
    }
  }, [foldedNodes, setFoldState]);

  return (
    <>
      {foldedNodes.length > 0 && (
        <button
          className={styles.crushFoldButton}
          onClick={() => {
            state.resetSelectedElements();
            state.addSelectedNodes([foldedNodes[0]]);
          }}
        >
          Crush Fold #1
        </button>
      )}
      <button
        className={`${styles.button2Base} ${
          foldedNodes.length > 1
            ? styles.button2Assigned
            : foldedNodes.length > 0
            ? styles.button2NotAssigned
            : styles.button2Only
        } `}
        disabled={nodesCount <= 1}
        onClick={mainClick}
      >
        {foldedNodes.length > 1 ? "Crush Fold #2" : text}
      </button>
    </>
  );
}
