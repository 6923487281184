import React from "react";
import useChildRoute from "../hooks/useChildRoute";
import PageHeader from "../components/page-header";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Table from "../components/table";
import { createPostRequest } from "../lib/network";

export default function Industries() {
  const { industries } = useLoaderData();
  const navigate = useNavigate();
  const isChildRoute = useChildRoute("/user/industries");
  const [industryRows, setIndustryRows] = React.useState(
    industries?.map((industry) => [
      {
        value: `${industry._id}?n=${encodeURIComponent(
          industry.Industry_Name,
        )}&i=${encodeURIComponent(
          industry.Industry_Image,
        )}&si=${encodeURIComponent(
          industry.Industry_Image_Square,
        )}&c=${encodeURIComponent(
          industry.Categories.map((cat) => cat._id).join(","),
        )}`,
      },
      { value: industry.Industry_Name },
      { value: `${industry.Industry_Metaname}` },
      {
        value: (
          <div
            title={industry.Categories.map((cat) => cat.Category_Name).join(
              ",",
            )}
            style={{ paddingLeft: 16 }}
          >
            {industry.Categories.length}
          </div>
        ),
      },
      {
        value: (
          <img
            src={`${process.env.REACT_APP_IMAGE_PREFIX}/${
              industry.Industry_Image_Square || industry.Industry_Image
            }`}
            style={{ borderRadius: "50%", width: 100, height: 100 }}
            alt="preview"
          />
        ),
        align: "left",
      },
    ]) || [],
  );

  function handleClick(link) {
    navigate(link);
  }

  function handleDrop(rowIndex, afterIndex) {
    if (rowIndex === afterIndex) {
      return;
    } else if (rowIndex < afterIndex) {
      setIndustryRows((curr) => {
        const prevId = curr[afterIndex][0].value.split("?")[0];
        const industryId = curr[rowIndex][0].value.split("?")[0];
        const nextId =
          afterIndex + 1 < curr.length
            ? curr[afterIndex + 1][0].value.split("?")[0]
            : undefined;
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/industry/rank`,
          { prevId, industryId, nextId },
        );
        return [
          ...curr.slice(0, rowIndex),
          ...curr.slice(rowIndex + 1, afterIndex + 1),
          curr[rowIndex],
          ...curr.slice(afterIndex + 1),
        ];
      });
    } else {
      setIndustryRows((curr) => {
        const prevId =
          afterIndex >= 1
            ? curr[afterIndex - 1][0].value.split("?")[0]
            : undefined;
        const industryId = curr[rowIndex][0].value.split("?")[0];
        const nextId = curr[afterIndex][0].value.split("?")[0];
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/industry/rank`,
          { prevId, industryId, nextId },
        );
        return [
          ...curr.slice(0, afterIndex),
          curr[rowIndex],
          ...curr.slice(afterIndex, rowIndex),
          ...curr.slice(rowIndex + 1),
        ];
      });
    }
  }

  return (
    <>
      {!isChildRoute && <PageHeader text="Industries" />}
      <Outlet />
      <Table
        headers={[
          { name: "Name", width: "30%" },
          { name: "Metaname", width: "20%" },
          { name: "Categories", width: "25%" },
          { name: "IMG", width: "25%", align: "left" },
        ]}
        rows={industryRows}
        bodyStyles={
          isChildRoute
            ? { height: "calc(100vh - 795px)" }
            : { height: "calc(100vh - 350px)" }
        }
        onRowClick={handleClick}
        draggableRows
        onDrop={handleDrop}
      />
    </>
  );
}
