import { createPostRequest } from "../../../lib/network";

export async function action({ request }) {
  const formData = await request.formData();
  const id = formData.get("id");
  const status = formData.get("status");
  await createPostRequest(
    `${process.env.REACT_APP_BASE_URL}/api/purchaseOrder/status`,
    {
      PurchaseOrderID: id,
      newStatus: status,
    },
  );
  return null;
}
