import styles from "../supplier.module.css";
import Control from "../../../components/control";
import GoogleAutocomplete from "../../../components/google-autocomplete";
import React from "react";
import PrimaryButton from "../../../components/primary-button";
import { DrawingManager, GoogleMap, Polygon } from "@react-google-maps/api";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete.svg";
import { ReactComponent as Dollar } from "../../../assets/icons/$.svg";

const DeleteControl = React.forwardRef(({ onClick }, ref) => {
  return (
    <div ref={ref} onClick={onClick} className={styles.mapDeleteContainer}>
      <div className={styles.mapDelete}>
        <IconDelete />
      </div>
    </div>
  );
});

export default function Branch({
  id,
  autoComplete,
  polygons,
  setPolygon,
  branch,
  onDelete,
}) {
  const [showRegion, setShowRegion] = React.useState(!!branch?.Branch_Address);
  const [showMap, setShowMap] = React.useState(false);
  const [coords, setCoords] = React.useState(
    branch?.Branch_Map_Center
      ? {
          lng: branch?.Branch_Map_Center.coordinates[0],
          lat: branch?.Branch_Map_Center.coordinates[1],
        }
      : undefined,
  );
  const mapObjectRef = React.useRef();
  const polygonRef = React.useRef();
  const deleteRef = React.useRef();

  function handleButtonClick() {
    setShowMap((curr) => !curr);
  }

  function polygonDrawn(polygon, destroy = true) {
    const coords = polygon.getPath().getArray();

    setPolygon(
      coords.map((cord) => ({
        lat: cord.lat(),
        lng: cord.lng(),
      })),
    );
    const container = document.getElementById(`branch-container-${id}`);
    container.setAttribute(
      "data-coords",
      coords.map((cord) => `${cord.lng()},${cord.lat()}`).join("#"),
    );
    container.setAttribute("data-zoom", mapObjectRef.current.getZoom());
    if (destroy) {
      polygon.setMap(null);
    }
  }

  function vertexMoved(e) {
    if (e.vertex !== undefined || e.edge !== undefined) {
      polygonDrawn(polygonRef.current, false);
    }
  }

  const polygonExists = polygons.find((item) => item.id === id);
  const otherPolygons = polygons.filter((item) => item.id !== id);

  function mapLoaded(currMap) {
    mapObjectRef.current = currMap;
    mapObjectRef.current.controls[
      window.google.maps.ControlPosition.RIGHT_TOP
    ].push(deleteRef.current);
  }

  function polygonLoaded(currPolygon) {
    polygonRef.current = currPolygon;
  }

  return (
    <>
      <div className={styles.branchRow} id={`branch-container-${id}`}>
        <Control
          label="Branch Name"
          id={`branch-name-${id}`}
          name={`Branch_Name_${id}`}
          required
          initialValue={branch?.Branch_Name}
        />
        <GoogleAutocomplete
          label="Branch Location"
          id={`branch-location-${id}`}
          name={`Branch_Location_${id}`}
          autocompleteService={autoComplete}
          required
          callback={(coords) => {
            setShowRegion(true);
            const [lng, lat] = coords.split(",");
            setCoords({ lat: parseFloat(lat), lng: parseFloat(lng) });
          }}
          initialValue={branch?.Branch_Address}
        />
        {showRegion && (
          <div className={styles.branchButtonContainer}>
            <PrimaryButton
              label={showMap ? "Done" : "Choose Service Region"}
              variant="outlined"
              onClick={handleButtonClick}
            />
          </div>
        )}
        {onDelete && (
          <div className={styles.branchDelete} onClick={() => onDelete(id)}>
            <IconDelete />
          </div>
        )}
      </div>
      <div className={styles.branchChargesContainer}>
        <Control
          id={`branch-delivery-charge-${id}`}
          name={`Branch_Charge_${id}`}
          label="Delivery Charge"
          type="number"
          initialValue={branch?.Branch_Delivery_Charge}
          endIcon={<Dollar />}
          required
        />
        <Control
          id={`branch-delivery-time-${id}`}
          name={`Branch_Time_${id}`}
          label="Delivery Time"
          type="number"
          initialValue={branch?.Branch_Delivery_Time}
          endIcon={<span className={styles.daysLabel}>days</span>}
          required
        />
      </div>
      {showMap && (
        <GoogleMap
          center={coords}
          zoom={branch?.Branch_Map_Zoom ? parseInt(branch.Branch_Map_Zoom) : 14}
          mapContainerClassName={styles.mapContainer}
          options={{
            mapTypeControl: false,
            drawingControl: false,
            streetViewControl: false,
          }}
          onLoad={mapLoaded}
        >
          {polygonExists ? (
            <>
              <Polygon
                path={polygonExists.path}
                options={{
                  fillColor: polygonExists.colour,
                  clickable: true,
                  editable: true,
                  strokeColor: polygonExists.colour,
                }}
                onMouseUp={vertexMoved}
                onLoad={polygonLoaded}
                key={polygonExists.path
                  .map(({ lat, lng }) => `${lng},${lat}`)
                  .join("/")}
              />
            </>
          ) : (
            <DrawingManager
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [
                    window.google.maps.drawing.OverlayType.POLYGON,
                  ],
                },
                polygonOptions: {
                  fillColor: "#00695C",
                  strokeColor: "#00695C",
                  editable: true,
                  clickable: true,
                },
              }}
              onPolygonComplete={polygonDrawn}
            />
          )}
          {otherPolygons.map((polygon) => (
            <Polygon
              key={polygon.id}
              path={polygon.path}
              options={{
                fillColor: polygon.colour,
                strokeColor: polygon.colour,
              }}
            />
          ))}
          <DeleteControl
            ref={deleteRef}
            onClick={() => setPolygon(undefined)}
          />
        </GoogleMap>
      )}
    </>
  );
}
