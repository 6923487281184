import { Link } from "react-router-dom";
import styles from "./table.module.css";
import { ReactComponent as IconNext } from "../../assets/icons/next.svg";
import { ReactComponent as IconPrev } from "../../assets/icons/prev.svg";
import React from "react";

/**
 * IMP: First column in rows (i.e. for each row) needs to be identifier
 * with which to call function. In case no click feature is required the
 * it can be the first data value
 */
export default function Table({
  headers = [],
  rows = [],
  bodyStyles = {},
  onRowClick,
  onRightClick,
  nextLink,
  prevLink,
  content,
  currentPage,
  totalPages,
  draggableRows,
  onDrop,
}) {
  const width = `${100 / headers.length}%`;
  const [rigthClickDetails, setRightClickDetails] = React.useState(null);

  function handleClick(e, id) {
    if (e.type === "click") {
      if (onRowClick) {
        onRowClick(id);
      }
    } else {
      if (onRightClick) {
        e.preventDefault();
        const link = onRightClick(id);
        setRightClickDetails({
          link,
          element: {
            x: e.clientX,
            y: e.clientY,
          },
        });
      }
    }
  }

  function handleDragStart(e, index) {
    if (draggableRows) {
      e.dataTransfer.setData("text/plain", index.toString());
    }
  }

  function handleDragOver(e) {
    if (draggableRows) {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
    }
  }

  function handleDrag(e) {
    if (draggableRows) {
      e.preventDefault();
    }
  }

  function handleDrop(e, dropIndex) {
    if (draggableRows && onDrop) {
      e.preventDefault();
      const target = e.currentTarget;
      target.classList.toggle(styles.dropRow);
      const dragIndex = e.dataTransfer.getData("text/plain");
      onDrop(parseInt(dragIndex), dropIndex);
    }
  }

  function handleDragEnterAndLeave(e) {
    const target = e.currentTarget;
    target.classList.toggle(styles.dropRow);
  }

  return (
    <div className={styles.listContainer}>
      <table className={styles.table}>
        <colgroup>
          {headers.map((header) => (
            <col style={{ width: header.width || width }} key={header.name} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {headers.map((header) => (
              <th align={header.align || "left"} key={header.name}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className={styles.tableBody} style={bodyStyles}>
        <table>
          <colgroup>
            {headers.map((header) => (
              <col style={{ width: header.width || width }} key={header.name} />
            ))}
          </colgroup>

          <tbody>
            {rows.length > 0 &&
              rows.map((row, index) => (
                <tr
                  key={row[0].value}
                  onClick={(e) => handleClick(e, row[0].value)}
                  onContextMenu={(e) => handleClick(e, row[0].value)}
                  draggable={draggableRows}
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDrag={handleDrag}
                  onDrop={(e) => handleDrop(e, index)}
                  onDragEnter={handleDragEnterAndLeave}
                  onDragLeave={handleDragEnterAndLeave}
                >
                  {row
                    .slice(rows[0].length > headers.length ? 1 : 0)
                    .map((entry, index) => (
                      <td
                        align={entry.align || "left"}
                        key={index.toString()}
                        valign={entry.vAlign || "middle"}
                      >
                        {entry.value}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {content ? (
        content
      ) : (
        <div className={styles.paginationContainer}>
          {prevLink && (
            <Link to={prevLink} className={styles.pageOption}>
              <IconPrev />
            </Link>
          )}
          {currentPage && (
            <span className={styles.pageNumber}>
              Page {Math.min(currentPage, totalPages)} of {totalPages}
            </span>
          )}
          {nextLink && (
            <Link to={nextLink} className={styles.pageOption}>
              <IconNext />
            </Link>
          )}
        </div>
      )}
      {rigthClickDetails && (
        <div
          className={styles.backdrop}
          onClick={(e) => {
            e.stopPropagation();
            setRightClickDetails(null);
          }}
        >
          <div
            className={styles.contextMenu}
            style={{
              top: rigthClickDetails.element.y,
              left: rigthClickDetails.element.x,
            }}
          >
            <a href={rigthClickDetails.link} target="_blank" rel="noreferrer">
              Open in new tab
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
