import * as React from "react";
import styles from "./sidebar.module.css";
import { NavLink } from "react-router-dom";

export default function Sidebar({ pages = [] }) {
  return (
    <nav className={styles.sidebar}>
      {pages.map((page) =>
        page.link ? (
          <NavLink to={page.link} className={styles.link} key={page.title}>
            {page.icon}
            <div className={styles.linkText}>{page.title}</div>
          </NavLink>
        ) : (
          <div className={`${styles.link} ${styles.inactive}`} key={page.title}>
            {page.icon}
            <div className={`${styles.linkText} `}>{page.title}</div>
          </div>
        ),
      )}
    </nav>
  );
}
