import { Link } from "react-router-dom";
import styles from "./breadcrumbs.module.css";

export default function BreadCrums({ segments }) {
  return (
    <div>
      {segments.map(({ label, link }) => (
        <Link to={link} className={styles.link} key={label}>
          {label}
        </Link>
      ))}
    </div>
  );
}
