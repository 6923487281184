import React from "react";
import styles from "./colours.module.css";
import MutliSelect from "../../../components/multi-select";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import Control from "../../../components/control";
import { ReactComponent as Percent } from "../../../assets/icons/Percent.svg";
import Palette from "../../../components/palette";

export default function Colours({
  selectedTypes,
  setSelectedTypes,
  selectedColours,
  setSelectedColours,
  colours,
  categories,
}) {
  let standardId;
  const colourOptions = colours.reduce((acc, curr) => {
    const cat = curr.Colour_Category._id;
    if (curr.Colour_Category.Colour_Category_Name === "Standard") {
      standardId = cat;
    }
    if (!acc[cat]) {
      acc[cat] = [];
    }
    acc[cat].push({
      label: curr.Colour_Name,
      value: curr._id,
      code: curr.Colour_Code,
    });
    return acc;
  }, {});
  const categoryIdToCat = categories.reduce((acc, curr) => {
    acc[curr._id] = curr;
    return acc;
  }, {});

  function handleChange(newValue) {
    const newSelectedTypes = newValue.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
    if (!newSelectedTypes[standardId]) {
      newSelectedTypes[standardId] = true;
    }
    setSelectedTypes(newSelectedTypes);
  }

  function deleteColour(cat, id) {
    setSelectedColours((curr) => ({
      ...curr,
      [cat]: {
        ...(curr[cat] || {}),
        colours: curr[cat].colours.filter((item) => item !== id),
      },
    }));
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.heading}>Choose Colour Range</div>
        <div className={styles.colourTypesRow}>
          <MutliSelect
            id="colour-type"
            name="Colour_Type"
            label="Colour Types"
            placeholder="Choose"
            options={categories.map((cat) => ({
              label: cat.Colour_Category_Name,
              value: cat._id,
            }))}
            fullWidth
            value={Object.entries(selectedTypes)
              .filter(([_, value]) => value)
              .map((item) => item[0])}
            onChange={handleChange}
          />
          <div className={styles.coloursContainer}>
            {Object.entries(selectedTypes)
              .filter(([_, value]) => value)
              .map((stype) => {
                const id = stype[0];
                const name = categoryIdToCat[id]?.Colour_Category_Name || id;
                return (
                  <div key={id} className={styles.colourContainer}>
                    <div className={styles.colourName}>{name}</div>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (id === standardId) {
                          return;
                        }
                        setSelectedTypes((curr) => ({
                          ...curr,
                          [id]: false,
                        }));
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {Object.entries(selectedTypes).map(([key, value]) => {
        return !value ? null : (
          <div key={key} className={styles.row}>
            <MutliSelect
              id={`colour-dropdown-${key}`}
              name={`Colour_${key}`}
              label={categoryIdToCat[key]?.Colour_Category_Name || key}
              placeholder="Choose"
              options={colourOptions[key] || []}
              fullWidth
              addAll
              value={selectedColours[key]?.colours || []}
              onChange={(newValue) =>
                setSelectedColours((curr) => ({
                  ...curr,
                  [key]: { ...(curr[key] || {}), colours: newValue },
                }))
              }
            />
            <Control
              id={`colour-cost-price-${key}`}
              name={`Colour_Cost_Price_${key}`}
              required
              fullWidth
              label="Cost Price"
              endIcon={<Percent />}
              type="number"
              step="any"
              value={selectedColours[key]?.costPrice}
              onChange={(newValue, e) => {
                setSelectedColours((curr) => ({
                  ...curr,
                  [key]: { ...curr[key], costPrice: newValue },
                }));
                e.target.setCustomValidity("");
              }}
              editable={key !== standardId}
            />
            <Control
              id={`colour-price-${key}`}
              name={`Colour_Price_${key}`}
              required
              fullWidth
              label="Price"
              endIcon={<Percent />}
              type="number"
              step="any"
              value={selectedColours[key]?.price}
              onChange={(newValue, e) => {
                setSelectedColours((curr) => ({
                  ...curr,
                  [key]: { ...curr[key], price: newValue },
                }));
                e.target.setCustomValidity("");
              }}
              editable={key !== standardId}
            />

            <div className={styles.coloursContainer}>
              {selectedColours[key]?.colours?.map((colorId) => {
                const colourObj = colourOptions[key].find(
                  (item) => item.value === colorId,
                );
                return (
                  <div key={colorId} className={styles.colourContainer}>
                    <div className={styles.colour}>
                      <Palette
                        code={colourObj.code}
                        borderRadius={15}
                        height={30}
                        width={30}
                      />
                      <div className={styles.colourName}>{colourObj.label}</div>
                    </div>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteColour(key, colorId)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
