import { Outlet, useNavigation, useLocation, Navigate } from "react-router-dom";
import styles from "./dashboard.module.css";
import Sidebar from "../components/sidebar";
import Appbar from "../components/appbar";
import Loader from "../components/loader";
import useToken from "../hooks/useToken";
import { pages } from "../lib/constants";

export default function Dashboard() {
  const { state } = useNavigation();
  const location = useLocation();

  const token = useToken();

  if (!token) {
    return <Navigate to={`/?next=${encodeURI(location.pathname)}`} replace />;
  }

  return (
    <main className={styles.container}>
      <Appbar />
      <div className={styles.contentContainer}>
        <Sidebar pages={pages} />
        <div className={styles.childrenContainer}>
          {state === "loading" && (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          )}
          <Outlet />
        </div>
      </div>
    </main>
  );
}
