import * as React from "react";
import SupplierDetails from "../supplier-details";
import { useNavigation, redirect, useLoaderData, Form } from "react-router-dom";
import { createPostRequest, uploadImage } from "../../../lib/network";
import { generateGetLoader } from "../../../lib/helpers";
import styles from "../supplier.module.css";
import BreadCrums from "../../../components/breadcrums";
import { useLoadScript } from "@react-google-maps/api";
import PrimaryButton from "../../../components/primary-button";
import { v4 as uuidv4 } from "uuid";
import { formatSupplierData } from "../../helpers";
import Branch from "../branch";

export async function action({ request, params }) {
  const formData = await request.formData();
  const formattedData = formatSupplierData(formData);
  const branches = Object.entries(formattedData.Branches).map(
    ([key, value]) => {
      if (key.length === 24) {
        return {
          UpdateBranchID: key,
          ...value,
        };
      } else {
        return {
          uuid: key,
          ...value,
        };
      }
    },
  );
  formattedData.Branches = branches;
  formattedData.Branches.forEach((branch) => {
    branch.Branch_Delivery_Charge = parseFloat(branch.Branch_Charge);
    branch.Branch_Delivery_Time = parseFloat(branch.Branch_Time);
    delete branch.Branch_Charge;
    delete branch.Branch_Time;
  });
  const image = formData.get("Supplier_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    formattedData.Supplier_Image = response.imageName;
  }
  const squareImage = formData.get("Supplier_Image");
  if (squareImage instanceof Blob && squareImage.size > 0) {
    const response = await uploadImage(squareImage);
    formattedData.Supplier_Image_Square = response.imageName;
  }
  formattedData.UpdateSupplierID = params.id;
  const URL = `${process.env.REACT_APP_BASE_URL}/api/supplier/update`;
  await createPostRequest(URL, formattedData);
  return redirect("/user/suppliers");
}

export async function loader({ params }) {
  const supplierId = params.id;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/supplier/get/${supplierId}`,
    "supplier",
  )();
  return response;
}

const libs = ["places", "drawing"];

export default function UpdateSupplier() {
  const { state } = useNavigation();
  const { supplier } = useLoaderData();
  const [branchIds, setBranchIds] = React.useState(
    supplier.Branches.map((branch) => branch._id),
  );
  const [formChanged, setFormChanged] = React.useState(false);
  const { isLoaded } = useLoadScript({
    libraries: libs,
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  const formRef = React.useRef();
  const submitButtonRef = React.useRef();
  const autoCompleteService = React.useRef();
  const [polygons, setPolygons] = React.useState(
    supplier.Branches.reduce((acc, curr) => {
      acc.set(
        curr._id,
        curr.Branch_Service_Region.coordinates[0].map(([lng, lat]) => ({
          lat,
          lng,
        })),
      );
      return acc;
    }, new Map()),
  );

  const colours = [
    "#F44336",
    "#D81B60",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#00BCD4",
    "#004D40",
    "#4CAF50",
    "#CDDC39",
  ];
  const polygonObjects = Array.from(polygons.entries()).map(
    ([key, value], index) => ({
      id: key,
      path: value,
      colour: colours[index % colours.length],
    }),
  );

  React.useEffect(() => {
    if (isLoaded) {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  function handleFormChange() {
    setFormChanged(true);
  }

  function addBranch(e) {
    e.preventDefault();
    setBranchIds((curr) => [...curr, uuidv4()]);
  }

  function handleSubmit() {
    submitButtonRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const formattedData = formatSupplierData(formData, supplier);
    let isValid = true;
    Object.entries(formattedData.Branches).forEach(([branchId, branch]) => {
      const element = document.getElementById(`branch-location-${branchId}`);
      if (!branch["Branch_Service_Region"]) {
        element.setCustomValidity("Please provide the service region");
        isValid = false;
      } else {
        element.setCustomValidity("");
      }
    });
    submitButtonRef.current.setAttribute("disabled", "false");
    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }
    formRef.current.requestSubmit();
  }

  function deleteBranch(id) {
    setBranchIds((curr) => {
      if (curr.length === 1) {
        return curr;
      }
      return curr.filter((item) => item !== id);
    });
  }

  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Suppliers", link: "../suppliers" },
          { label: supplier.Supplier_Name, link: "." },
        ]}
      />
      <Form
        method="post"
        encType="multipart/form-data"
        ref={formRef}
        onChange={handleFormChange}
        style={{ marginTop: 24 }}
      >
        <SupplierDetails
          autoComplete={autoCompleteService}
          supplier={supplier}
        />
        <section>
          <div className={styles.branchesHeading}>Branches</div>
          {branchIds.map((id) => (
            <Branch
              key={id}
              id={id}
              autoComplete={autoCompleteService}
              polygons={polygonObjects}
              onDelete={branchIds.length > 1 && deleteBranch}
              setPolygon={(path) => {
                setPolygons((curr) => {
                  const newPolygons = new Map(curr);
                  if (path) {
                    newPolygons.set(id, path);
                  } else {
                    newPolygons.delete(id);
                  }
                  return newPolygons;
                });
                handleFormChange();
              }}
              branch={supplier.Branches.find((item) => item._id === id)}
            />
          ))}
        </section>
        <section className={styles.actionContainer}>
          <PrimaryButton
            label="Add Branch"
            type="button"
            variant="outlined"
            onClick={addBranch}
          />
          {formChanged && (
            <PrimaryButton
              label="Update Supplier"
              type="button"
              onClick={handleSubmit}
              loading={state === "loading"}
              ref={submitButtonRef}
            />
          )}
        </section>
      </Form>
    </div>
  );
}
