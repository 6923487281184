import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import useChildRoute from "../hooks/useChildRoute";

export default function Flashings() {
  const { shapes } = useLoaderData();
  const isChildRoute = useChildRoute("/user/flashings-shape-library");
  const navigate = useNavigate();

  const shapeRows = shapes.map((shape) => [
    { value: shape._id },
    { value: shape.Flashing_Shape_Name },
    {
      value: (
        <img
          src={`${process.env.REACT_APP_IMAGE_PREFIX}/${shape.Flashing_Shape_Image}`}
          style={{ height: 100, width: "auto" }}
          alt="preview"
        />
      ),
    },
  ]);

  function handleRowClick(id) {
    navigate(id);
  }

  return (
    <>
      {!isChildRoute && <PageHeader text="Flashings Shape Library" />}
      <Outlet />
      <Table
        rows={shapeRows}
        bodyStyles={{
          height: !isChildRoute ? "calc(100vh - 350px)" : "calc(100vh - 738px)",
        }}
        headers={[
          { name: "Shape Name", width: "50%" },
          { name: "Image", width: "50%" },
        ]}
        onRowClick={handleRowClick}
      />
    </>
  );
}
