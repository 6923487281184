import styles from "./pricing-details.module.css";
import Control from "../../../components/control";
import { ReactComponent as Dollar } from "../../../assets/icons/$.svg";
import { ReactComponent as Percent } from "../../../assets/icons/Percent.svg";
import React from "react";

export default function Branch({ data, onChange, taperedMode }) {
  function onCPChange(e, index, subIndex) {
    const text = e.target.value;
    const value = parseFloat(text);
    if (!isNaN(value)) {
      onChange({
        ...data,
        prices: [
          ...data.prices.slice(0, index),
          [
            ...data.prices[index].slice(0, subIndex),
            {
              ...data.prices[index][subIndex],
              cp: text,
              sp: data.branch_sp
                ? parseFloat(((1 + data.branch_sp / 100) * value).toFixed(2))
                : data.prices[index][subIndex].sp,
            },
            ...data.prices[index].slice(subIndex + 1),
          ],
          ...data.prices.slice(index + 1),
        ],
      });
    } else if (text === "") {
      onChange({
        ...data,
        prices: [
          ...data.prices.slice(0, index),
          [
            ...data.prices[index].slice(0, subIndex),
            {
              ...data.prices[index][subIndex],
              cp: "",
              sp: "",
            },
            ...data.prices[index].slice(subIndex + 1),
          ],
          ...data.prices.slice(index + 1),
        ],
      });
    }
    e.target.setCustomValidity("");
  }

  function onSpChange(e, index, subIndex) {
    const text = e.target.value;
    onChange({
      ...data,
      prices: [
        ...data.prices.slice(0, index),
        [
          ...data.prices[index].slice(0, subIndex),
          {
            ...data.prices[index][subIndex],
            sp: text,
          },
          ...data.prices[index].slice(subIndex + 1),
        ],
        ...data.prices.slice(index + 1),
      ],
    });
    e.target.setCustomValidity("");
  }

  function addRow() {
    if (data.prices.length === 8) {
      alert("Maximum 8 bends are allowed");
      return;
    }
    onChange({
      ...data,
      prices: [
        ...data.prices,
        data.prices[0].map((record) => ({ size: record.size, cp: "", sp: "" })),
      ],
    });
  }

  function addColumn() {
    onChange({
      ...data,
      prices: data.prices.map((record) => [
        ...record,
        { size: "", cp: "", sp: "" },
      ]),
    });
  }

  function onSizeChange(e, index) {
    onChange({
      ...data,
      prices: data.prices.map((bendRecord) => [
        ...bendRecord.slice(0, index),
        {
          ...bendRecord[index],
          size: e.target.value,
        },
        ...bendRecord.slice(index + 1),
      ]),
    });
    e.target.setCustomValidity("");
  }

  return (
    <div>
      <div className={styles.branchName}>{data.name}</div>
      <div className={styles.branchInputsContainer}>
        <Control
          id={`branch-${data.id}-selling-price`}
          name={`Branch_Selling_Price-${data.id}`}
          fullWidth
          label="Selling Price"
          input
          type="number"
          value={data.branch_sp}
          onChange={(value, e) => {
            onChange({
              ...data,
              branch_sp: parseFloat(value),
              prices: data.prices.map((bendRecord) => {
                return bendRecord.map((record) => {
                  const cp = parseFloat(record.cp);
                  if (!isNaN(cp)) {
                    return {
                      size: record.size,
                      cp: record.cp,
                      sp: ((1 + value / 100) * cp).toFixed(2),
                    };
                  } else {
                    return record;
                  }
                });
              }),
            });
            e.target.setCustomValidity("");
          }}
          endIcon={<Percent />}
          required
        />
        {taperedMode === "fixed" ? (
          <Control
            id={`branch-${data.id}-tapered-cost`}
            name={`Branch_Tapered_Cost-${data.id}`}
            fullWidth
            label="Tapered Fixed Cost"
            input
            type="number"
            value={data.tapered_cost}
            onChange={(value, e) => {
              onChange({ ...data, tapered_cost: parseFloat(value) });
              e.target.setCustomValidity("");
            }}
            endIcon={<Dollar />}
            required
          />
        ) : (
          <>
            <Control
              id={`branch-${data.id}-tapered-cost-price`}
              name={`Branch_Tapered_Cost_Price-${data.id}`}
              fullWidth
              label="Tapered Cost Price"
              input
              type="number"
              value={data.tapered_cp}
              onChange={(value, e) => {
                onChange({ ...data, tapered_cp: parseFloat(value) });
                e.target.setCustomValidity("");
              }}
              endIcon={<Percent />}
              required
            />
            <Control
              id={`branch-${data.id}-tapered-selling-price`}
              name={`Branch_Tapered_Selling_Price-${data.id}`}
              fullWidth
              label="Selling Price"
              input
              type="number"
              value={data.tapered_sp}
              onChange={(value, e) => {
                onChange({ ...data, tapered_sp: parseFloat(value) });
                e.target.setCustomValidity("");
              }}
              endIcon={<Percent />}
              required
            />
          </>
        )}
        <Control
          id={`branch-${data.id}-delivery-cost`}
          name={`Branch_Delivery_Cost-${data.id}`}
          fullWidth
          label="Delivery Cost"
          type="number"
          endIcon={<Dollar />}
          value={data.branch_delivery_cost}
          onChange={(value, e) => {
            onChange({ ...data, branch_delivery_cost: parseFloat(value) });
            e.target.setCustomValidity("");
          }}
          required
        />
        <Control
          id={`branch-${data.id}-delivery-large`}
          name={`Branch_Large_Delivery_Cost-${data.id}`}
          fullWidth
          label="8.5m+ Delivery Cost"
          type="number"
          value={data.branch_large_delivery_cost}
          onChange={(value, e) => {
            onChange({
              ...data,
              branch_large_delivery_cost: parseFloat(value),
            });
            e.target.setCustomValidity("");
          }}
          endIcon={<Dollar />}
          required
        />
        <Control
          id={`branch-${data.id}-edt`}
          name={`Branch_EDT-${data.id}`}
          fullWidth
          label="EDT"
          type="number"
          endIcon={<span className={styles.daysLabel}>days</span>}
          value={data.branch_edt}
          onChange={(value, e) => {
            onChange({ ...data, branch_edt: parseFloat(value) });
            e.target.setCustomValidity("");
          }}
          required
        />
      </div>
      <div className={styles.pricesContainer}>
        <table className={styles.table} border={1}>
          <thead>
            <tr>
              <th>Bends</th>
              {data.prices[0].map((record, index) => (
                <th colSpan={2} key={index.toString()}>
                  <input
                    value={record.size}
                    onChange={(e) => onSizeChange(e, index)}
                    placeholder="100mm - 150mm"
                    required
                    id={`branch-${data.id}-girth-${index}`}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={styles.headerRow}>
              <td></td>
              {data.prices[0].map((_, index) => (
                <React.Fragment key={`-1,${index}`}>
                  <td align="center">CP</td>
                  <td align="center">SP</td>
                </React.Fragment>
              ))}
            </tr>
            {data.prices.map((row, index) => (
              <tr key={index.toString()}>
                <td align="center">{index + 1}</td>
                {row.map((record, i) => (
                  <React.Fragment key={`${index},${i}`}>
                    <td>
                      <input
                        value={record.cp}
                        onChange={(e) => onCPChange(e, index, i)}
                        required
                        id={`branch-${data.id}-cp-${index}-${i}`}
                      />
                    </td>
                    <td>
                      <input
                        value={record.sp}
                        onChange={(e) => onSpChange(e, index, i)}
                        required
                        id={`branch-${data.id}-sp-${index}-${i}`}
                      />
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <div onClick={addColumn} className={styles.addColumn}>
            Add Girth
          </div>
          <div onClick={addRow} className={styles.addRow}>
            Add Bend
          </div>
        </table>
      </div>
    </div>
  );
}
