import styles from "./errors.module.css";
import Appbar from "../components/appbar";
import Sidebar from "../components/sidebar";
import { pages } from "../lib/constants";

export default function Layout({ children }) {
  return (
    <main className={styles.container}>
      <Appbar />
      <div className={styles.contentContainer}>
        <Sidebar pages={pages} />
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </main>
  );
}
