import { useLoaderData, useNavigation, redirect } from "react-router-dom";
import CategoryDetails from "../category-details";
import { convertToBase64 } from "../../../lib/helpers";
import { createPostRequest } from "../../../lib/network";
import * as React from "react";

export async function action({ request, params }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  data.UpdateCategoryID = params.id;
  const image = formData.get("Category_Image");
  if (image instanceof Blob && image.size > 0) {
    const base64Image = await convertToBase64(image);
    const url = `${process.env.REACT_APP_BASE_URL}/api/image/create`;
    const postResponse = await createPostRequest(url, {
      imageContent: base64Image,
      imageName: image.filename,
    });
    data.Category_Image = postResponse.data.imageName;
  } else {
    delete data.Category_Image;
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/category/update`;
  await createPostRequest(url, data);
  return redirect("..");
}

export function loader({ request }) {
  const url = new URL(request.url);
  const image = url.searchParams.get("image");
  const name = url.searchParams.get("name");
  return { image, name };
}

export default function UpdateCategory() {
  const { state } = useNavigation();
  const { image, name = "" } = useLoaderData();
  const [formChanged, setFormChanged] = React.useState(false);

  function handleFormChange() {
    setFormChanged(true);
  }

  return (
    <CategoryDetails
      name={name}
      image={`${process.env.REACT_APP_IMAGE_PREFIX}/${image}`}
      heading={name}
      submitting={state === "submitting"}
      key={name}
      showSubmit={formChanged}
      onFormChange={handleFormChange}
    />
  );
}
