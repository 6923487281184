import BreadCrums from "../../../components/breadcrums";
import CustomLink from "../../../components/custom-link";
import styles from "./colour-category-details.module.css";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import { Form } from "react-router-dom";
import Control from "../../../components/control";
import PrimaryButton from "../../../components/primary-button";

export default function ColourCategoryDetails({
  heading = "Create Colour Category",
  onFormChange,
  name,
  showSubmit,
  submitting,
}) {
  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Colours", link: ".." },
          { label: heading, link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>{heading}</div>
        <CustomLink
          text="Create Item"
          icon={<IconAdd />}
          href="/user/colours/add"
        />
      </div>
      <Form className={styles.form} method="post" onChange={onFormChange}>
        <Control
          label="Category Name"
          id="colour-category-name"
          name="Colour_Category_Name"
          required
          initialValue={name}
        />
        {(showSubmit === undefined || showSubmit) && (
          <PrimaryButton
            label={showSubmit ? "Save Changes" : heading}
            type="submit"
            loading={submitting}
          />
        )}
      </Form>
    </div>
  );
}
