import styles from "./quantity.module.css";
import { ReactComponent as Minus } from "../../assets/icons/minusIcon.svg";
import { ReactComponent as Plus } from "../../assets/icons/plusIcon.svg";

export default function Quantity({ value, onChange }) {
  function increment() {
    onChange(value + 1);
  }

  function decrement() {
    onChange(value - 1);
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.iconContainer} ${styles.leftIcon}`}
        onClick={decrement}
      >
        <Minus />
      </div>
      <div className={styles.value}>{value}</div>
      <div className={styles.iconContainer} onClick={increment}>
        <Plus />
      </div>
    </div>
  );
}
