import { Outlet, useLoaderData } from "react-router-dom";
import PageHeader from "../components/page-header";
import useChildRoute from "../hooks/useChildRoute";
import { generateGetLoader } from "../lib/helpers";
import Table from "../components/table";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany?page=${page}&limit=50`,
    "colourCategories",
  )();
  return response;
}

export default function ColourCategories() {
  const { colourCategories } = useLoaderData();
  const currentPage = colourCategories.currentPage;
  const totalPages = colourCategories.totalPages;
  const isChildRoute = useChildRoute("/user/colour-categories");

  const rows = colourCategories?.colourCategories.map((cat) => {
    return [
      { value: cat.Colour_Category_ID },
      { value: cat.Colour_Category_Name },
    ];
  });

  return (
    <>
      {!isChildRoute && <PageHeader text="Colour Categories" />}
      <Outlet />
      <Table
        headers={[
          { name: "ID", width: "25%" },
          { name: "Name", width: "75%" },
        ]}
        rows={rows}
        nextLink={
          currentPage < totalPages ? `?page=${currentPage + 1}` : undefined
        }
        prevLink={currentPage > 1 ? `?page=${currentPage - 1}` : undefined}
        currentPage={currentPage}
        totalPages={totalPages}
        bodyStyles={
          isChildRoute
            ? { height: "calc(100vh - 675px)" }
            : { height: "calc(100vh - 420px)" }
        }
      />
    </>
  );
}
