export function formatSupplierData(formData, supplier) {
  const data = Object.fromEntries(formData);
  const formattedData = { Branches: {} };
  const branchRegex = /^Branch_[a-zA-Z]+_(.+)/i;
  Object.entries(data).forEach(([key, value]) => {
    const matches = key.match(branchRegex);
    if (
      key !== "Supplier_Image" &&
      key !== "Supplier_Image_Square" &&
      !!value
    ) {
      if (matches) {
        const branchId = matches[1];
        if (!formattedData.Branches[branchId]) {
          formattedData.Branches[branchId] = {};
        }
        const formattedKey = key.split("_").slice(0, 2).join("_");
        if (formattedKey === "Branch_Location") {
          formattedData.Branches[branchId][formattedKey] = value.split("@")[1];
          const branchAddress = document.getElementById(
            `branch-location-${branchId}`,
          );
          let addressCoords = branchAddress.getAttribute("data-coords");
          const currentBranch = supplier?.Branches.find(
            (item) => item._id === branchId,
          );
          if (!addressCoords) {
            addressCoords =
              currentBranch.Branch_Location_Coords.coordinates.join(",");
            branchAddress.setAttribute("data-coords", addressCoords);
          }
          const [lng, lat] = addressCoords.split(",");
          formattedData.Branches[branchId]["Location_Coords"] = {
            type: "Point",
            coordinates: [parseFloat(lng), parseFloat(lat)],
          };
          formattedData.Branches[branchId]["Branch_Map_Center"] = {
            type: "Point",
            coordinates: [parseFloat(lng), parseFloat(lat)],
          };
          const containerElement = document.getElementById(
            `branch-container-${branchId}`,
          );
          let serviceRegionCoords =
            containerElement.getAttribute("data-coords");
          let mapZoom = containerElement.getAttribute("data-zoom");
          if (!mapZoom) {
            mapZoom = currentBranch.Branch_Map_Zoom;
            containerElement.setAttribute("data-zoom", mapZoom);
          }
          formattedData.Branches[branchId]["Branch_Map_Zoom"] = mapZoom;
          if (!serviceRegionCoords) {
            serviceRegionCoords =
              currentBranch.Branch_Service_Region.coordinates[0]
                .map(([lng, lat]) => `${lng},${lat}`)
                .join("#");
            containerElement.setAttribute("data-coords", serviceRegionCoords);
          }
          const polygon = serviceRegionCoords
            .split("#")
            .map((vertex) => vertex.split(",").map((cord) => parseFloat(cord)));
          formattedData.Branches[branchId]["Branch_Service_Region"] = {
            coordinates: [polygon],
            type: "Polygon",
          };
        } else {
          formattedData.Branches[branchId][formattedKey] = value;
        }
      } else if (key !== "Supplier_Address") {
        formattedData[key] = value;
      } else {
        formattedData[key] = value.split("@")[1];
        const supplierAddress = document.getElementById(`supplier-address`);
        let addressCoords = supplierAddress.getAttribute("data-coords");
        if (!addressCoords) {
          addressCoords =
            supplier.Supplier_Location_Coords.coordinates.join(",");
          supplierAddress.setAttribute("data-coords", addressCoords);
        }
        const [lng, lat] = addressCoords.split(",");
        formattedData["Supplier_Location_Coords"] = {
          type: "Point",
          coordinates: [parseFloat(lng), parseFloat(lat)],
        };
      }
    }
  });
  return formattedData;
}
