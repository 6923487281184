import React from "react";
import styles from "./login.module.css";
import { ReactComponent as Name } from "../../assets/icons/Name.svg";
import PrimaryButton from "../../components/primary-button";
import {
  Form,
  Navigate,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";
import { createPostRequest } from "../../lib/network";
import { TOKEN } from "../../lib/constants";
import useToken from "../../hooks/useToken";

export default function Login() {
  const [loading, setLoading] = React.useState(false);
  const { state } = useNavigation();
  const [step, setStep] = React.useState(1);
  const formRef = React.useRef();
  const [userId, setUserId] = React.useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = useToken();

  async function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    if (step === 1) {
      setLoading(true);
      const email = formData.get("Email");
      try {
        const response = await createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/auth/login/phone`,
          {
            User_Email: email.trim(),
          },
        );
        if (response) {
          setStep(2);
          setUserId(response.data.userId);
        }
      } catch (err) {
        if (err.status === 404) {
          alert("Email not registered as admin");
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const otp = formData.get("OTP");
      try {
        const response = await createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/auth/verify`,
          {
            userId,
            Client_OTP: otp.trim(),
          },
        );
        if (response) {
          const token = response.data.token;
          localStorage.setItem(TOKEN, token);
          const newPath = searchParams.get("next") || "/user/orders";
          navigate(newPath, { replace: true });
        }
      } catch (err) {
        if (err.status === 400) {
          alert("Please enter correct OTP");
        }
      } finally {
        setLoading(false);
      }
    }
  }

  if (token) {
    const newPath = searchParams.get("next") || "/user/orders";
    return <Navigate to={newPath} replace />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.leftCardSection}>
          <div className={styles.logoContainer}>
            {/* <Fence /> */}
            <div className={styles.name}>
              <Name />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightCardSection}>
          <div className={styles.heading}>Log in</div>
          <Form
            className={styles.form}
            method="post"
            onSubmit={onSubmit}
            ref={formRef}
          >
            <label className={styles.label} htmlFor="email">
              Email Address
            </label>
            <Input id="email" name="Email" required type="email" />
            <div className={step === 1 ? styles.hidden : ""}>
              <label className={styles.label}>One Time Password</label>
              <Input id="otp" name="OTP" required={step === 2} />
            </div>
            <div className={styles.actionContainer}>
              <PrimaryButton
                label="Login"
                type="submit"
                loading={state === "loading" || loading}
                fullWidth
                rounded
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

function Input({ id, name, required, type }) {
  return (
    <input
      id={id}
      name={name}
      className={styles.input}
      required={required}
      type={type}
    />
  );
}
