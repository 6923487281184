import styles from "./branches.module.css";
import Control from "../../../components/control";
import Radio from "../../../components/radio";
import { ReactComponent as Dollar } from "../../../assets/icons/$.svg";
import { ReactComponent as Percent } from "../../../assets/icons/Percent.svg";
import React from "react";

export default function Branch({
  branch,
  setDefault,
  defaultBranch,
  prevData,
  update,
}) {
  const [available, setAvailable] = React.useState(update ? !!prevData : true);

  function invertEditable(value) {
    setDefault(value);
  }

  function handleChange(e) {
    const value = e.target.checked;
    if (!value && defaultBranch === branch._id) {
      alert(
        "Item needs to be available in default branch. Please change default branch and then product availability",
      );
      return;
    }
    setAvailable(value);
  }

  return (
    <div className={styles.branchContainer}>
      <div className={styles.branchTopSection}>
        <div className={styles.branchName}>{branch.Branch_Name}</div>
        <div className={styles.ctaContainer}>
          <label className={styles.switch}>
            <input
              type="checkbox"
              name={`Branch_Available-${branch._id}`}
              checked={available}
              onChange={handleChange}
              value={available ? "Available" : "Not Available"}
            />
            <span className={styles.slider}>
              {available ? "Available" : "Not Available"}
            </span>
          </label>
          {available && (
            <Radio
              name="Default_Branch"
              id={`default-branch-${branch._id}`}
              value={branch._id}
              required
              checked={defaultBranch === branch._id}
              onChange={invertEditable}
              label={
                <label
                  className={styles.defaultBranch}
                  htmlFor={`default-branch-${branch._id}`}
                >
                  Default Branch
                </label>
              }
            />
          )}
        </div>
      </div>
      <div className={styles.branchInputsContainer}>
        <Control
          id={`branch-${branch._id}-price`}
          name={`Branch_Cost_Price-${branch._id}`}
          fullWidth
          label="Price"
          type="number"
          initialValue={prevData ? prevData[`Branch_Cost_Price`] : undefined}
          endIcon={<Percent />}
          editable={defaultBranch !== branch._id && available}
        />
        <Control
          id={`branch-${branch._id}-selling-price`}
          name={`Branch_Selling_Price-${branch._id}`}
          fullWidth
          label="Selling Price"
          type="number"
          initialValue={prevData ? prevData[`Branch_Selling_Price`] : undefined}
          endIcon={<Percent />}
          editable={defaultBranch !== branch._id && available}
        />
        <Control
          id={`branch-${branch._id}-delivery`}
          name={`Branch_Delivery_Cost-${branch._id}`}
          fullWidth
          label="Delivery Cost"
          type="number"
          initialValue={prevData ? prevData[`Branch_Delivery_Cost`] : undefined}
          endIcon={<Dollar />}
          editable={available}
        />
        <Control
          id={`branch-${branch._id}-edt`}
          name={`Branch_EDT-${branch._id}`}
          fullWidth
          label="EDT"
          type="number"
          initialValue={prevData ? prevData[`Branch_EDT`] : undefined}
          endIcon={<span className={styles.daysLabel}>days</span>}
          editable={available}
        />
      </div>
    </div>
  );
}
