import { createGetRequest } from "./network";
import dayjs from "dayjs";

export function generateGetLoader(url, entity) {
  return async function () {
    let data;
    if (Array.isArray(url)) {
      data = await Promise.all(url.map((path) => createGetRequest(path)));
      return data.reduce(
        (acc, curr, index) => ({ ...acc, [entity[index]]: curr.data }),
        {},
      );
    } else {
      data = await createGetRequest(url);
      return { [entity]: data.data };
    }
  };
}

export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(this.result);
    };
    reader.onerror = function (err) {
      reject(err);
    };
  });
}

export function getInvoiceStatus(invoice) {
  if (invoice.Invoice_Action_Required) {
    if (invoice.Invoice_Amount_Outstanding > 0) {
      return { text: "To Be Sent", colour: "var(--info)" };
    } else {
      return { text: "Refund Approval Pending", colour: "var(--info)" };
    }
  }
  if (
    invoice.Invoice_Status === "Settled" ||
    invoice.Invoice_Status === "Refund Processed"
  ) {
    return { text: invoice.Invoice_Status, colour: "var(--success)" };
  }
  if (invoice.Invoice_Status === "Partially Paid") {
    return dayjs().isAfter(dayjs(invoice.Invoice_Due_Date))
      ? { text: "Overdue", colour: "var(--error)" }
      : { text: "Pending payment", colour: "var(--error)" };
  }
  return { text: invoice.Invoice_Status, colour: "black" };
}

export function getPOStatus(po) {
  const status = po.PurchaseOrder_Status;
  const sentStatus = po.PurchaseOrder_Sent_Status;

  if (!sentStatus) {
    return { text: "To Be Sent", colour: "var(--error)" };
  }

  if (status === "Paid") {
    return { text: status, colour: "var(--success)" };
  }

  if (status === "Void") {
    return { text: status, colour: "var(--accent)" };
  }

  if (status === "Delivered") {
    return { text: status, colour: "var(--success)" };
  }

  if (status === "Pending") {
    return { text: "Sent", colour: "var(--info)" };
  }

  return { text: status, colour: "black" };
}
