import { generateGetLoader } from "../../../lib/helpers";
import PricingDetails from "../pricing-details";
import { FLASHINGS_KEY } from "../pricing-details/constants";

export async function loader() {
  const data = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colour/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany`,
    ],
    ["suppliers", "colours", "categories"],
  )();
  const prevData = localStorage.getItem(FLASHINGS_KEY);
  return { ...data, prevData: prevData ? JSON.parse(prevData) : prevData };
}

export default function CreatePricing() {
  return <PricingDetails buttonLabel="Create Pricing" />;
}
