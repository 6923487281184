import * as React from "react";
import PrimaryButton from "../../../components/primary-button";
import Branch from "./branch";
import styles from "./branches.module.css";

export default function Branches({
  branches,
  onNext,
  prevData,
  prevDefaultBranch,
  update,
}) {
  const [defaultBranch, setDefaultBranch] = React.useState(
    prevDefaultBranch
      ? prevDefaultBranch
      : () => {
          if (branches.length > 0) {
            return branches[0]._id;
          }
          return "";
        },
  );

  React.useEffect(() => {
    if (!defaultBranch && branches.length > 0) {
      setDefaultBranch(branches[0]._id);
    } else if (branches.length === 1) {
      setDefaultBranch(branches[0]._id);
    }
  }, [branches, defaultBranch]);

  return (
    <>
      {branches.map((branch) => (
        <Branch
          key={branch._id}
          branch={branch}
          defaultBranch={defaultBranch}
          setDefault={setDefaultBranch}
          prevData={prevData ? prevData[branch._id] : undefined}
          update={update}
        />
      ))}
      {branches.length > 0 && (
        <div className={styles.nextContainer}>
          <PrimaryButton label="Next" type="button" onClick={onNext} />
        </div>
      )}
    </>
  );
}
