import * as React from "react";
import styles from "./modal.module.css";
import { createPortal } from "react-dom";

export default function Modal({ open, onClose, children }) {
  return (
    open &&
    createPortal(
      <div className={styles.container} onClick={onClose}>
        <div className={styles.childrenContainer}>{children}</div>
      </div>,
      document.body,
    )
  );
}
