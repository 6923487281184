import * as React from "react";
import { useLoaderData, useNavigation, redirect } from "react-router-dom";
import { createPostRequest, uploadImage } from "../../../lib/network";
import IndustryDetails from "../industry-details";
import { generateGetLoader } from "../../../lib/helpers";

export async function action({ request, params }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  data.UpdateIndustryID = params.id;
  data.Categories = formData.getAll("Categories");
  const image = formData.get("Industry_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    data.Industry_Image = response.imageName;
  } else {
    delete data.Industry_Image;
  }
  const squareImage = formData.get("Industry_Image_Square");
  if (squareImage instanceof Blob && squareImage.size > 0) {
    const response = await uploadImage(squareImage);
    data.Industry_Image_Square = response.imageName;
  } else {
    delete data.Industry_Image_Square;
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/industry/update`;
  await createPostRequest(url, data);
  return redirect("..");
}

export async function loader({ request }) {
  const url = new URL(request.url);
  const name = url.searchParams.get("n");
  const image = url.searchParams.get("i");
  const squareImage = url.searchParams.get("si");
  const categories = url.searchParams.get("c");
  const { categories: categoryOptions } = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/category/getMany`,
    "categories",
  )();
  return {
    industry: {
      Industry_Name: name,
      Industry_Image: `${process.env.REACT_APP_IMAGE_PREFIX}/${image}`,
      Industry_Image_Square: `${process.env.REACT_APP_IMAGE_PREFIX}/${squareImage}`,
      Categories: categories.split(",").map((cat) => ({ _id: cat })),
    },
    categories: categoryOptions,
  };
}

export default function UpdateIndustry() {
  const { state } = useNavigation();
  const { industry, categories } = useLoaderData();
  const [formChanged, setFormChanged] = React.useState(false);

  function handleFormChange() {
    setFormChanged(true);
  }

  return (
    <IndustryDetails
      heading={industry.Industry_Name}
      categories={categories}
      industry={industry}
      onFormChange={handleFormChange}
      showSubmit={formChanged}
      submitting={state === "submitting"}
      key={industry.Industry_Name}
    />
  );
}
