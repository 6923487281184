import * as React from "react";
import { redirect, useLoaderData, useNavigation } from "react-router-dom";
import ColorDetails from "../color-details";
import { createPostRequest } from "../../../lib/network";
import { generateGetLoader } from "../../../lib/helpers";

export async function action({ request, params }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  data["Colour_Code"] = data["Colour_Code"].toUpperCase();
  if (data["Secondary_Colour_Code"]) {
    data["Secondary_Colour_Code"] = data["Secondary_Colour_Code"].toUpperCase();
  } else {
    delete data["Secondary_Colour_Code"];
  }
  data["UpdateColourID"] = params.id;
  const url = `${process.env.REACT_APP_BASE_URL}/api/colour/update`;
  await createPostRequest(url, data);
  return redirect("..");
}

export async function loader({ request }) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");
  const name = url.searchParams.get("name");
  const category = url.searchParams.get("category");
  const secondaryCode = url.searchParams.get("sCode");
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany?page=1&limit=50`,
    "colourCategories",
  )();
  return {
    code,
    name,
    category,
    colourCategories: response.colourCategories,
    secondaryCode,
  };
}

export default function UpdateColor() {
  const { state } = useNavigation();
  const { code, name, category, colourCategories, secondaryCode } =
    useLoaderData();
  const [formChanged, setFormChanged] = React.useState(false);

  function handleFormChange() {
    setFormChanged(true);
  }

  return (
    <ColorDetails
      submitting={state === "submitting"}
      heading={name}
      code={code}
      name={name}
      category={category}
      key={`${code}-${category}`}
      onFormChange={handleFormChange}
      showSubmit={formChanged}
      categoryOptions={
        colourCategories?.colourCategories.map((cat) => ({
          label: cat.Colour_Category_Name,
          value: cat._id,
        })) || []
      }
      secondaryCode={secondaryCode}
    />
  );
}
