import React from "react";
import styles from "./industry-details.module.css";
import BreadCrums from "../../../components/breadcrums";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import { Form } from "react-router-dom";
import PrimaryButton from "../../../components/primary-button";
import ImagePicker from "../../../components/image-picker";
import Control from "../../../components/control";
import MultiSelect from "../../../components/multi-select";

export default function IndustryDetails({
  heading = "Create Industry",
  submitting,
  industry,
  showSubmit,
  onFormChange,
  categories,
}) {
  const formRef = React.useRef();

  function validate() {
    const formData = new FormData(formRef.current);
    const categories = formData.getAll("Categories");
    if (!categories || categories.length === 0) {
      document
        .querySelector('[name="Categories"]')
        .setCustomValidity("Please select at least one category");
      formRef.current.reportValidity();
      return;
    } else {
      document.querySelector('[name="Categories"]').setCustomValidity("");
    }
    formRef.current.requestSubmit();
  }

  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Industries", link: ".." },
          { label: heading, link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>{heading}</div>
        <CustomLink
          text="Create Item"
          icon={<IconAdd />}
          href="/user/industries/add"
        />
      </div>
      <Form
        className={styles.form}
        method="post"
        encType="multipart/form-data"
        onChange={onFormChange}
        ref={formRef}
      >
        <div className={styles.flex}>
          <ImagePicker
            name="Industry_Image"
            id="industry-image"
            image={industry?.Industry_Image}
            label={"Update Image"}
          />
          <div className={styles.inputContainer}>
            <ImagePicker
              name="Industry_Image_Square"
              id="industry-image-square"
              image={industry?.Industry_Image_Square}
              label={"Update Square Image"}
            />
          </div>
          <div className={styles.inputContainer}>
            <Control
              label="Industry Name"
              id="industry-name"
              name="Industry_Name"
              required
              initialValue={industry?.Industry_Name}
            />
            <div className={styles.spacing} />
            <MultiSelect
              name="Categories"
              id="categories"
              label="Categories"
              options={categories.map((cat) => ({
                label: cat.Category_Name,
                value: cat._id,
              }))}
              placeholder="Select Categories"
              defaultValue={industry?.Categories.map((cat) => cat._id)}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {(showSubmit === undefined || showSubmit) && (
            <PrimaryButton
              label={showSubmit ? "Save Changes" : heading}
              type="button"
              onClick={validate}
              loading={submitting}
            />
          )}
        </div>
      </Form>
    </div>
  );
}
