import { BaseEdge, EdgeLabelRenderer, getStraightPath } from "reactflow";
import React from "react";
import { ColourContext, ColourDir } from "./utils";
import styles from "./flashing.module.css";

export default function DoubleEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  data,
}) {
  const direction = React.useContext(ColourContext);
  const valueRef = React.useRef();
  const taperedValueRef = React.useRef();
  const [e1, e2] = getEdgeEnds({
    x1: sourceX,
    y1: sourceY,
    x2: targetX,
    y2: targetY,
  });
  const [e1t, e2t] = getEdgeEnds({
    x1: sourceX + 150,
    y1: sourceY - 150,
    x2: targetX + 150,
    y2: targetY - 150,
  });

  const [p1] = getStraightPath(e1.outer ? e1 : e2);
  const [p2] = getStraightPath(e1.outer ? e2 : e1);
  const [_, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const [p1t] = getStraightPath(e1t.outer ? e1t : e2t);
  const [p2t] = getStraightPath(e1t.outer ? e2t : e1t);
  const [__, labelXT, labelYT] = getStraightPath({
    sourceX: sourceX + 150,
    sourceY: sourceY - 150,
    targetX: targetX + 150,
    targetY: targetY - 150,
  });

  function onInputChange(e, tapered) {
    const value = parseInt(e.target.value);
    if (isNaN(value) && e.target.value !== "") {
      return;
    } else if (!isNaN(value)) {
      data.onEdgeUpdate(id, value.toString(), tapered);
    } else {
      data.onEdgeUpdate(id, "", tapered);
    }
  }

  function onInputFocus(tapered) {
    if (tapered) {
      taperedValueRef.current = data?.taperedLabel;
    } else {
      valueRef.current = label;
    }
    data?.onEdgeUpdate(id, "", tapered);
  }

  function onInputBlur(e, tapered) {
    const value = e.target.value;
    if (value === "") {
      data?.onEdgeUpdate(
        id,
        tapered ? taperedValueRef.current : valueRef.current,
        tapered,
      );
    }
  }

  return (
    <>
      <BaseEdge
        id={id}
        path={p1}
        style={{
          stroke: direction === ColourDir.OUTSIDE ? "#276cF1" : "black",
          strokeDasharray: direction === ColourDir.OUTSIDE ? undefined : "5,5",
          strokeWidth: direction === ColourDir.OUTSIDE ? 3 : 2,
        }}
      />
      <BaseEdge
        id={id}
        path={p2}
        style={{
          stroke: direction === ColourDir.INSIDE ? "#276cF1" : "black",
          strokeDasharray: direction === ColourDir.INSIDE ? undefined : "5,5",
          strokeWidth: direction === ColourDir.INSIDE ? 3 : 2,
        }}
      />
      {data?.tapered && (
        <>
          <BaseEdge
            id={id}
            path={p1t}
            style={{
              stroke: direction === ColourDir.OUTSIDE ? "#276cF1" : "black",
              strokeDasharray:
                direction === ColourDir.OUTSIDE ? undefined : "5,5",
              strokeWidth: direction === ColourDir.OUTSIDE ? 3 : 2,
            }}
          />
          <BaseEdge
            id={id}
            path={p2t}
            style={{
              stroke: direction === ColourDir.INSIDE ? "#276cF1" : "black",
              strokeDasharray:
                direction === ColourDir.INSIDE ? undefined : "5,5",
              strokeWidth: direction === ColourDir.INSIDE ? 3 : 2,
            }}
          />
        </>
      )}
      <EdgeLabelRenderer>
        <div
          className={`${styles.absolute} ${styles["bg-white"]} ${styles["z-10"]} no-pan ${styles["pointer-all"]} ${styles["shadow-pova-sm"]} ${styles["rounded-[5px]"]}`}
          style={{
            transform: `translate(-50%,-50%) translate(${labelX}px, ${labelY}px)`,
          }}
        >
          <input
            inputMode="numeric"
            value={label}
            onChange={(e) => onInputChange(e, false)}
            placeholder={valueRef.current || "100"}
            className={`${styles["w-6"]} ${styles["outline-none"]} ${styles["focus:outline-none"]} ${styles["text-xs"]} ${styles["text-center"]} ${styles["font-semibold"]} ${styles["border-none"]}`}
            onFocus={() => onInputFocus(false)}
            onBlur={(e) => onInputBlur(e, false)}
          />
        </div>
      </EdgeLabelRenderer>
      {data?.tapered && (
        <EdgeLabelRenderer>
          <div
            className={`${styles.absolute} ${styles["bg-white"]} ${styles["z-10"]} no-pan ${styles["pointer-all"]} ${styles["shadow-pova-sm"]} ${styles["rounded-[5px]"]}`}
            style={{
              transform: `translate(-50%,-50%) translate(${labelXT}px, ${labelYT}px)`,
            }}
          >
            <input
              inputMode="numeric"
              value={data.taperedLabel}
              onChange={(e) => onInputChange(e, true)}
              placeholder={taperedValueRef.current || "100"}
              className={`${styles["w-6"]} ${styles["outline-none"]} ${styles["focus:outline-none"]} ${styles["text-xs"]} ${styles["text-center"]} ${styles["font-semibold"]} ${styles["border-none"]}`}
              onFocus={() => onInputFocus(true)}
              onBlur={(e) => onInputBlur(e, true)}
            />
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

function getEdgeEnds({ x1, y1, x2, y2 }) {
  const slope = -(y2 - y1) / (x2 - x1);
  const pSlope = -1 / slope;
  const [p1, p2] = pointAtDist(pSlope, 3, { x: x1, y: -y1 });
  const [p3, p4] = pointAtDist(pSlope, 3, { x: x2, y: -y2 });
  const plus = { sourceX: p1.x, sourceY: -p1.y, targetX: p3.x, targetY: -p3.y };
  const minus = {
    sourceX: p2.x,
    sourceY: -p2.y,
    targetX: p4.x,
    targetY: -p4.y,
  };
  let outer = "plus";
  if (slope === 0 && x2 > x1) {
    outer = "minus";
  } else if (!isFinite(slope) && y2 > y1) {
    outer = "minus";
  } else if ((x2 < x1 && y2 > y1) || (x2 > x1 && y2 > y1)) {
    outer = "minus";
  }
  return [
    { ...plus, outer: outer === "plus" },
    { ...minus, outer: outer === "minus" },
  ];
}

function pointAtDist(slope, dist, { x, y }) {
  if (slope === 0) {
    return [
      { x: x + dist, y },
      { x: x - dist, y },
    ];
  } else if (!isFinite(slope)) {
    return [
      { x, y: y + dist },
      { x, y: y - dist },
    ];
  } else {
    const dx = dist / Math.sqrt(1 + slope * slope);
    const dy = slope * dx;
    return [
      { x: x + dx, y: y + dy },
      { x: x - dx, y: y - dy },
    ];
  }
}
