import { redirect, useLoaderData, useNavigation } from "react-router-dom";
import { createPostRequest, uploadImage } from "../../../lib/network";
import IndustryDetails from "../industry-details";

export async function action({ request }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  const image = formData.get("Industry_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    data.Industry_Image = response.imageName;
  } else {
    delete data.Industry_Image;
  }
  const squareImage = formData.get("Industry_Image_Square");
  if (squareImage instanceof Blob && squareImage.size > 0) {
    const response = await uploadImage(squareImage);
    data.Industry_Image_Square = response.imageName;
  } else {
    delete data.Industry_Image_Square;
  }
  data.Categories = formData.getAll("Categories");
  const url = `${process.env.REACT_APP_BASE_URL}/api/industry/create`;
  await createPostRequest(url, data);
  return redirect("..");
}

export default function CreateIndustry() {
  const { state } = useNavigation();
  const { categories } = useLoaderData();
  return (
    <IndustryDetails
      submitting={state === "submitting"}
      categories={categories}
    />
  );
}
