import styles from "./radio.module.css";

export default function Radio({
  label,
  name,
  id,
  required,
  value,
  fullWidth,
  onChange,
  inputType,
  defaultChecked,
  multiple,
  containerStyles,
  labelClassname,
  checked,
}) {
  const inputLabel =
    typeof label === "string" ? (
      <label
        htmlFor={id}
        className={`${styles.label} ${labelClassname ? labelClassname : ""}`}
      >
        {label}
      </label>
    ) : (
      label
    );

  function handleChange(e) {
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <div
      className={`${styles.container} ${fullWidth ? styles.fullWidth : ""}`}
      style={containerStyles}
    >
      <input
        type={inputType || "radio"}
        name={name}
        id={id}
        required={required}
        value={value}
        className={styles.hidden}
        onChange={handleChange}
        defaultChecked={defaultChecked}
        multiple={multiple}
        checked={checked}
      />
      {inputLabel}
    </div>
  );
}
