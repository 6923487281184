import DoubleEdge from "./double-edge";
import Point from "./point";

export const nodeTypes = {
  point: Point,
};

export const edgeTypes = {
  double: DoubleEdge,
};
